import {
  Avatar,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { usePlans } from "../../hooks/usePlans";
// import { SubNavbar } from "../Layout/components/SubNavbar";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import { NoPlans } from "../AlternatePages/noPlans";
import { IPlan } from "../Plans/UserPlan";
// import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import CustomPayModel from "../Payments/CustomPayPopUp";
import { usePayment } from "../../hooks/usePayment";
import { Helmet } from "react-helmet";
import { IPaymentList } from "../../models/payment.model";
import React from "react";
import { useNavigate } from "react-router-dom";
import PaymentSuccess from "../Payments/PaymentSuccessPopUp";
import PaymentFailed from "../Payments/PaymentFailedPopUp";
import SubscriptionPlans from "./SubscriptionPlans";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export const Subscription = () => {
  const { isLoading, plans } = usePlans();

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  // console.log(selectedCommunity?.selectedCommunity?.name, "selectedCommunity")

  const _id = selectedCommunity?.selectedCommunity?._id;

  /////paymentsList api call////////
  const { getPaymentStatusById, initialSubscriptionPaymentIds, CustomPay } =
    usePayment();

  const [transaction, setTransaction] = useState<IPaymentList>();

  const [customloading, setCustomLoading] = React.useState(false);
  const [filteredPaymentsList] = useState<IPaymentList[]>([]);
  const [reson, setReson] = React.useState("");
  const [amount, setAmount] = React.useState<any>("");

  // eslint-disable-next-line
  const [retryLoadingStates, setRetryLoadingStates] = useState<boolean[]>(
    Array(filteredPaymentsList?.length).fill(false)
  );
  /////

  const navigate = useNavigate();
  const [, setCommunity] = useState("");
  const [, setplan] = useState("");
  const [, setinvitiesId] = useState("");

  enum PaymentStatus {
    SUCCESS = "SUCCESS",
    PENDING = "PENDING",
    FAILED = "FAILED",
    USERCANCELLED = "USERCANCELLED",
  }
  // payment response function
  // const paymentResponse = (response: any) => {
  //   const tnxId = response?.transactionId;
  //   const transaction = response?.transaction as IPaymentList;
  //   if (transaction) setTransaction(transaction);
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const width = Math.min(1000, screenWidth);
  //   const height = Math.min(1000, screenHeight);
  //   const left = (screenWidth - width) / 2;
  //   const top = (screenHeight - height) / 2;

  //   // console.log("User Agent:", navigator.userAgent);

  //   const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  //   const isMacOSDevice =
  //     /Macintosh|MacIntel|MacPPC|Mac68K/i.test(navigator.userAgent) &&
  //     !(navigator.maxTouchPoints && navigator.maxTouchPoints > 1);

  //   // console.log("isIOSDevice:", isIOSDevice, "isMacOSDevice:", isMacOSDevice);

  //   // console.log("window.webkit:", (window as any).webkit);

  //   if (
  //     (isIOSDevice || isMacOSDevice) &&
  //     (window as any).webkit?.messageHandlers?.webToNativeInterface
  //   ) {
  //     // console.log("webkit.messageHandlers.webToNativeInterface exists");

  //     const message = {
  //       action: "openNewWindow",
  //       data: JSON.stringify({
  //         openUrl: response.url,
  //         closeWindow: [
  //           { regex: "(^https://testpay\\\\.easebuzz\\\\.in/error)" },
  //           {
  //             regex:
  //               "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/failure)",
  //           },
  //           {
  //             regex:
  //               "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/success)",
  //           },
  //         ],
  //       }),
  //     };

  //     (window as any).webkit.messageHandlers.webToNativeInterface.postMessage(
  //       message
  //     );
  //     // console.log("Posted message:", message);
  //   } else {
  //     // This will run on non-iOS/macOS or in browsers like Safari
  //     // console.log("Opening window in non-iOS/macOS device (or in Safari)");
  //     const windowRef = window.open(
  //       response.url,
  //       `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
  //     );

  //     const intervalRef = setInterval(async () => {
  //       const paymentStatus = await getPaymentStatusById(tnxId);
  //       if (paymentStatus && paymentStatus.length > 0) {
  //         clearInterval(intervalRef);
  //         windowRef?.close();
  //         if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
  //           setSuccessOpen(true);
  //         } else {
  //           setFailureOpen(true);
  //         }
  //       }
  //     }, 1000);
  //   }
  // };


  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.min(1000, screenWidth);
    const height = Math.min(1000, screenHeight);
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;
    const windowRef = window.open(
      response.url,
      // "_self",
      `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
    );
    const intervalRef = setInterval(async () => {
      const paymentStatus = await getPaymentStatusById(tnxId);
      if (paymentStatus && paymentStatus.length > 0) {
        clearInterval(intervalRef);
        windowRef?.close();
        if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
          setSuccessOpen(true);
        } else {
          setFailureOpen(true);
        }
      }
    }, 1000);
  };

  const handleRetryClick = async (
    communityId: string,
    planId: string,
    invitiesId: string,
    i: any
  ) => {
    setCommunity(communityId);
    setplan(planId);
    setinvitiesId(invitiesId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[i] = true; // Set loading state for this item to true
      return newStates;
    });
    // console.log(communityId, planId, invitiesId);
    const response = await initialSubscriptionPaymentIds(
      communityId,
      planId,
      invitiesId
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[i] = false; // Set loading state for this item back to false
      return newStates;
    });
    paymentResponse(response);
  };

  // UseEffect for  Success and Failed Model Open
  const [timer, setTimer] = useState(5);

  //////PaymentPopup state and Functions/////
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [customPay, setCustomPay] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  //const [fullWidth, setFullWidth] = React.useState(true);

  const [readonly, setReadOnly] = useState(false);
  const handleSuccessClose = () => {
    setTimer(5);
    navigate("/subscriptions");
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate("/subscriptions");
    setFailureOpen(false);
  };
  // const handleCustomPayOpen = () => {
  //   setCustomPay(true);
  // };

  const handleCustomPayClose = () => {
    setCustomPay(false);
    setReson("");
    setAmount("");
    setReadOnly(false);
  };

  const handleAmountChange = (newValue: any) => {
    setAmount(newValue);
  };
  const handleResonChange = (newValue: string) => {
    setReson(newValue);
  };
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      // const formattedAmount = amount.toString() + ".00";
      // formData.append("amount",amount.toString() + ".00");

      const regex = /\.(\d{2})$/;
      console.log(amount);

      if (regex.test(amount)) {
        formData.append("amount", amount);
      } else {
        formData.append("amount", amount.toString() + ".00");
      }
      formData.append("description", reson);
      setCustomLoading(true);

      if (_id) {
        const updateResponse = await CustomPay(_id, formData);
        paymentResponse(updateResponse);
        if (updateResponse.status === 200) {
          // setCustomLoading(false);
          // setCustomPay(false);
        } else {
          console.error("Update failed:", updateResponse);
        }
      } else {
        // Handle the case where _id is null or undefined
        console.error("Selected community ID is null or undefined");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCustomLoading(false);
      setAmount("");
      setReson("");
      setCustomPay(false);
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  //

  //
  return (
    <>
      <Helmet>
        <title>Subscriptions - Onecommunn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>

      <Stack direction={"column"} spacing={1}>
        <Stack>
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={1}
          >
            <BootstrapTooltip title="Back" placement="left">
              <KeyboardBackspaceIcon
                sx={{
                  color: "#50A1CA",
                  cursor: "pointer",
                  ml: 2,
                  fontSize: { xs: "20px", md: "40px" },
                }}
                onClick={() => navigate(`/explore-communities`)}
              />
            </BootstrapTooltip>
            <Avatar
              src={selectedCommunity?.selectedCommunity?.logo}
              sx={{
                backgroundColor: "white",
              }}
            ></Avatar>
            <Typography
              sx={{
                fontSize: { xs: "15px", md: "20px" },
                fontWeight: 600,
                background:
                  "-webkit-linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%);",

                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontFamily: "Montserrat",
              }}
            >
              {selectedCommunity?.selectedCommunity?.name}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#575757",
              fontFamily: "Montserrat",
              mt: 3,
            }}
          >
            Choose the right plan for you.
          </Typography>
        </Stack>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLoading ? (
              <Loader />
            ) : plans.length === 0 ? (
              <NoPlans />
            ) : (
              <Stack direction="column" spacing={0.5}>
                {plans
                  ?.slice()
                  .sort((a, b) => {
                    const aHasActiveDue = a.subscriptionDue.some(
                      (due) => due?.status === "ACTIVE"
                    );
                    const bHasActiveDue = b.subscriptionDue.some(
                      (due) => due?.status === "ACTIVE"
                    );
                    if (aHasActiveDue && !bHasActiveDue) return -1;
                    if (!aHasActiveDue && bHasActiveDue) return 1;
                    return 0;
                  })
                  .map((plan: IPlan) => (
                    <SubscriptionPlans plan={plan} key={plan?._id} plans={plans} />
                  ))}
              </Stack>
            )}



            <CustomPayModel
              fullWidth={true}
              maxWidth={"xs"}
              customPay={customPay}
              handleCustomPayClose={handleCustomPayClose}
              buttonText={
                customloading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )
              }
              handleCustomPayIcon={handleCustomPayClose}
              handleSubmitClick={() => {
                //if (amount && amount > 99.0) {
                handleSubmit();
                //}
              }}
              paymentvalue={amount || ""}
              paymentOnChange={handleAmountChange}
              paymenReason={reson}
              paymentReasonOnChange={handleResonChange}
              readonly={readonly}
            />
            {/* Payment Success Pop up */}
            <PaymentSuccess
              txnid={transaction?.txnid || ""}
              open={successOpen}
              amount={transaction?.amount || ""}
              timer={timer}
              dialogText={""}
              onClose={handleSuccessClose}
              onClickNavigateButton={() => {
                navigate("/payments");
                setSuccessOpen(false);
              }}
            />

            {/* Payment Failed Pop up */}
            <PaymentFailed
              open={failureOpen}
              onClose={handleFailureClose}
              amount={transaction?.amount || ""}
              txnid={transaction?.txnid || ""}
              onClickNavigateButton={() => {
                navigate("/payments");
                setFailureOpen(false);
              }}
              dialogText={""}
              timer={timer}
              OnClickRetryButtonFunction={() => {
                if ((transaction?.udf2 as any) === "663f0fbb7377a32029638f15") {
                  setFailureOpen(false);
                  setCustomPay(true);
                  setAmount(transaction?.amount);
                  setReson(transaction?.udf4 || "");
                } else {
                  handleRetryClick(
                    transaction?.udf3?._id || "",
                    transaction?.udf2?._id || "",
                    "",
                    ""
                  );
                }
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
