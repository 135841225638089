import { Paper, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Delete = () => {


    const selectedCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );


    // console.log(selectedCommunity, "selectedCommunity")

    const adminEmail = selectedCommunity?.email;
    const adminPhone = selectedCommunity?.mobileNumber;

    return (
        <>
            <Paper sx={{ height: '90vh', borderRadius: '12px !important', border: '1px solid #E7EBF1', }} elevation={0} >
                <Stack
                    direction='column'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        padding: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: '15px', md: '20px' },
                            fontWeight: 500,
                            color: '#50A1CA',
                            fontFamily: 'Montserrat',
                        }}
                    >
                        Delete Account
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '13px', md: '16px' },
                            lineHeight: 1.5,
                            fontFamily: 'Montserrat',
                        }}
                    >
                        This is the user account deletion page. In the Onecommunn app, we do not provide a direct option for users to delete their accounts themselves. If you wish to remove or delete your account from the community, please send a request to the community admin. The admin will review your request and process the deletion if needed. You can contact the admin through either phone or email.
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '13px', md: '16px' },
                            color: '#50A1CA',
                            fontWeight: 500,
                            marginTop: 2,
                            fontFamily: 'Montserrat',
                        }}
                    >
                        Admin Contact Details:
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '13px', md: '16px' },
                            lineHeight: 1.5,
                            fontFamily: 'Montserrat',
                        }}
                    >
                        <span style={{ fontWeight: 'bold' }}>Email: </span>  {adminEmail}
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '13px', md: '16px' },
                            lineHeight: 1.5,
                            fontFamily: 'Montserrat',
                        }}
                    >
                        <span style={{ fontWeight: 'bold' }}>
                            Mobile: </span>   {adminPhone}
                    </Typography>
                </Stack>
            </Paper>
        </>
    );
}

export default Delete;
