
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IService } from "../community/CreateCommunityPage2";
import { Avatar, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, Grid, Typography } from "@mui/material";
// import ServiceImage from "../../assets/images/Communn-favicon.png"
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const AboutCarousel = ({ productData }: { productData: IService[] }) => {


  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [selectedItem, setSelectedItem] = useState<IService | null>(null);

  const handleCloses = () => {
    setOpen(false);
  };

  const handleClickOpen = (item: IService) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const product = productData.map((item: any, index) => (
    <Card elevation={0} key={item._id} style={{ height: "90%", marginRight: "15PX", paddingBottom: "20px", borderRadius: '12px !important', border: '1px solid #E7EBF1', paddingTop: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Avatar sx={{
          color: '#ffffff',
          fontSize: { xs: "14px", md: '25px' },
          fontFamily: 'Montserrat',
          fontWeight: "700",
          textAlign: 'center',
          p: 5, background: "linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%)", borderRadius: '100px'
        }}>0{index + 1}</Avatar>
      </Box>
      <CardContent>
        <Typography sx={{
          color: '#000000',
          fontSize: { xs: "14px", md: '16px' },
          fontFamily: 'Montserrat',
          fontWeight: "600",
        }}>
          {item?.title?.length > 20 ? `${item?.title?.slice(0, 20)}...` : item?.title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            paddingBottom: '15px',
            color: '#565656',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              flexGrow: 1,
              fontFamily: "Montserrat",
            }}
          >
            {item?.description && item.description.length > 100
              ? item.description.substring(0, 100) + '...'
              : item.description}
          </Typography>
          {item?.description && item?.description.length > 100 && (
            <Button
              onClick={() => handleClickOpen(item)}
              sx={{ minWidth: 'auto', padding: 0, marginLeft: '8px' }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "small", color: '#50a1ca ' }} />
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  ));

  return (
    <div >
      <Carousel responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {product}
      </Carousel>
      {selectedItem && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloses}
        >
          <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: "#F0F9FF" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "15px" },
                  fontWeight: 500,
                  textDecoration: "none",
                  color: "#50A1CA",
                  textTransform: "uppercase",
                  marginLeft: 2,
                  fontFamily: "Montserrat",
                }}
              >
                {selectedItem.title}
              </Typography>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: "#50A1CA",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Grid>
          </DialogActions>
          <DialogContent sx={{ p: 2 }}>
            <DialogContentText>
              <Typography sx={{ fontSize: "15px", fontFamily: "Montserrat", }}> {selectedItem.description}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default AboutCarousel;
