import { Box, Grid, Stack, Typography } from "@mui/material";
import AppointmentShareFooter from "./Layout/AppointmentShareFooter";
import ShareAvailability from "./Components/ShareAvailability";
import AppointmentShareHeader from "./Layout/AppointmentShareHeader";
import Logo from '../../../assets/images/Communn-favicon.png';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useParams } from "react-router-dom";
import { useAppointments } from "../../../hooks/useAppointments";
import { useEffect, useState } from "react";
import { IAppointment } from "../../../models/appointments.models";
import { EmailOutlined, ScheduleOutlined } from "@mui/icons-material";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';



const AppointmentShare = () => {



    const { id } = useParams();

    const { getNoAuthAppointmentById } = useAppointments();

    const [appointmentDetails, setAppointmentDetails] = useState<IAppointment[]>([]);
    const [, setIsLoading] = useState(false);

    // console.log(appointmentDetails, "appointmentDetails");

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const cachedData = localStorage.getItem(`appointmentDetails_${id}`);
            if (cachedData) {
                setAppointmentDetails(JSON.parse(cachedData));
            } else {
                const response = await getNoAuthAppointmentById(id || '');
                if (Array.isArray(response)) {
                    setAppointmentDetails(response);
                    localStorage.setItem(`appointmentDetails_${id}`, JSON.stringify(response));
                } else {
                    setAppointmentDetails([]);
                }
            }
        } catch (err) {
            console.error('Error fetching appointments:', err);
            setAppointmentDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchClassDetails();
        }
    }, [id]);


    // const defaultImages =
    //     "https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png";

    return (
        <>


            <Stack spacing={4} >
                {/* Header */}
                <Stack sx={{ zIndex: 1 }}>
                    <AppointmentShareHeader />
                </Stack>

                {/* Main Content */}
                <Stack sx={{ px: { xs: 1, sm: 10, md: 10, xl: 20 } }}>
                    <Grid
                        // spacing={2}
                        container
                        justifyContent="center"
                        sx={{
                            p: { xs: 2, md: 5 },
                            backgroundColor: "#ffffff",
                            height: { xs: "fit-content", md: "59vh" },
                            borderRadius: "10px",
                            border: "1px solid #E7EBF1",
                            mt: { xs: 10, md: 13 },
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={4.7}
                            sx={{
                                height: "100%",
                                borderRight: { xs: 'none', md: '1px solid #E7EBF1' },


                            }}
                        >
                            {appointmentDetails?.map((appointment) => (
                                <Box sx={{
                                    pr: 1,
                                }}>
                                    <Box
                                        component={'img'}
                                        src={Logo}
                                        sx={{
                                            borderRadius: '10px', objectFit: 'contain', height: { xs: '50px', md: "50px" },
                                        }}
                                    />
                                    <Stack spacing={1.5} mt={2}>
                                        {/* Appointment */}
                                        <Stack direction="column" gap={1}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: '400', fontFamily: 'Montserrat' }}>
                                                {appointment?.title}
                                            </Typography>
                                            <Typography sx={{ fontSize: '22px', fontWeight: '600', fontFamily: 'Montserrat' }}>
                                                ₹ {appointment?.pricing || "Free"}
                                            </Typography>
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                                {appointment?.description?.length > 200 ? appointment?.description?.slice(0, 200) + "..." : appointment?.description}
                                            </Typography>
                                        </Stack>

                                        {/* Event Date & Time */}
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <ScheduleOutlined sx={{ fontSize: '20px', color: 'gray' }} />
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                                {appointment?.duration} Minutes
                                            </Typography>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <PhoneOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                                +91 {appointment?.createdBy?.phoneNumber}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <EmailOutlined sx={{ fontSize: '20px', color: 'gray' }} />
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                                {appointment?.createdBy?.emailId}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <Person2OutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                                By {appointment?.createdBy?.firstName}
                                            </Typography>
                                        </Stack>



                                        <Stack direction='row' alignItems='center' gap={1}>
                                            <PinDropOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Montserrat', }}>
                                                {appointment?.location}
                                            </Typography>
                                        </Stack>

                                    </Stack>
                                </Box>
                            ))}

                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                height: "100%",
                                ml: { xs: 0, md: 2 },
                                mt: { xs: 2, mt: 0 },
                                borderTop: { xs: '1px solid #E7EBF1', md: 'none' },
                            }}
                        >
                            <ShareAvailability />
                        </Grid>
                    </Grid>
                </Stack>

                {/* Footer */}
                <Stack sx={{ mt: { xs: 2, md: 3 } }}>
                    <AppointmentShareFooter />
                </Stack>
            </Stack >
        </>
    );
};

export default AppointmentShare;
