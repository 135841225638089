import { ReactComponent as Group520 } from "./svg-icons/Group 520.svg";
import { ReactComponent as Group529 } from "./svg-icons/Group 529.svg";
import { ReactComponent as A } from "./svg-icons/A.svg";
import { ReactComponent as Group437 } from "./svg-icons/Group 437.svg";
import { ReactComponent as Group438 } from "./svg-icons/Group 438.svg";
import { ReactComponent as Group436 } from "./svg-icons/Group 436.svg";
import { ReactComponent as Group432 } from "./svg-icons/Group 432.svg";
import { ReactComponent as Polygon15 } from "./svg-icons/Polygon 15.svg";
import { ReactComponent as People } from "./svg-icons/people.svg";
import { ReactComponent as Edit } from "./svg-icons/edit.svg";
import { ReactComponent as Delete } from "./svg-icons/delete.svg";
import { ReactComponent as Group } from "./svg-icons/Group.svg";
import { ReactComponent as Group2 } from "./svg-icons/Group 2.svg";
import { ReactComponent as Group1 } from "./svg-icons/Group 1.svg";
import { ReactComponent as Group448 } from "./svg-icons/Group 448.svg";
import { ReactComponent as Google } from "./svg-icons/image 1.svg";
import { ReactComponent as Facebook } from "./svg-icons/image 2.svg";
import { ReactComponent as Group401 } from "./svg-icons/Group 401.svg";
import { ReactComponent as Setting2 } from "./svg-icons/dashboardIcon.svg";
import { ReactComponent as CardTick } from "./svg-icons/Payment 123.svg";
import { ReactComponent as IcDashboard } from "./svg-icons/PlansIcon.svg";
import { ReactComponent as BoxTick } from "./svg-icons/messageIcon11.svg";
import { ReactComponent as SmsTracking } from "./svg-icons/Posts.svg";
import { ReactComponent as Home } from "./svg-icons/HMIcon.svg";
import { ReactComponent as SidebarToggler } from "./svg-icons/SidebarToggler.svg";
import { ReactComponent as Search } from "./svg-icons/Vector.svg";
import { ReactComponent as Attach } from "./svg-icons/Attach.svg";
import { ReactComponent as TotalRevenue } from "./svg-icons/TotalRevenue.svg";
import { ReactComponent as SettledBalance } from "./svg-icons/SettledBalance.svg";
import { ReactComponent as TopArrow } from "./svg-icons/TopArrow.svg";
import { ReactComponent as Message } from "./svg-icons/Message.svg";
import { ReactComponent as Notification } from "./svg-icons/Notification.svg";
import { ReactComponent as Visibility } from "./svg-icons/visibility.svg";
import { ReactComponent as Community } from "./svg-icons/Communities.svg";
import { ReactComponent as Preminum } from "./svg-icons/Preminum.svg";
import { ReactComponent as Lock } from "./svg-icons/lock.svg";
import { ReactComponent as BuildCommunity } from "./svg-icons/Buildcommunity.svg"
import { ReactComponent as Plans } from "./svg-icons/payment-1-_2_.svg"
import { ReactComponent as InvitiesUsers } from "./svg-icons/Inviteusers.svg"
import { ReactComponent as Add } from "./svg-icons/+.svg"
import { ReactComponent as dashboard1 } from "./svg-icons/dashboard (1).svg"
import { ReactComponent as Activity } from "./svg-icons/ActivityFinal.svg"
import { ReactComponent as Explore1 } from "./svg-icons/Explore.svg"
import { ReactComponent as MessageInfo } from "./svg-icons/MessageInfo.svg"
import { ReactComponent as King } from "./svg-icons/King.svg"
import { ReactComponent as Switch } from "./svg-icons/Group (5).svg"
import { ReactComponent as SearchCard } from "./svg-icons/Frame 456.svg"
import { ReactComponent as Report } from "./svg-icons/report.svg"
import { ReactComponent as Settings } from "./svg-icons/settings.svg"
import { ReactComponent as About } from "./svg-icons/info.svg"
import { ReactComponent as Logout } from "./svg-icons/logout.svg"
import { ReactComponent as Help } from "./svg-icons/help outline.svg"
import { ReactComponent as CreatePost } from "./svg-icons/add_circle.svg"
import { ReactComponent as Profile } from "./svg-icons/profile.svg"
import { ReactComponent as Like } from "./svg-icons/Favorite.svg"
import { ReactComponent as Love } from "./svg-icons/Love.svg"
import { ReactComponent as SheildPerson } from "./svg-icons/shield_person.svg"
import { ReactComponent as Menu } from "./svg-icons/menu.svg"
import { ReactComponent as LinkShare } from './new-icons/Share.svg';
import { ReactComponent as Calender } from './new-icons/calender.svg';
import { ReactComponent as Website } from './new-icons/website.svg';
import { ReactComponent as Course } from './new-icons/course.svg';
import { ReactComponent as Payments } from './new-icons/payments.svg';
import { ReactComponent as Products } from './new-icons/product.svg';
import { ReactComponent as Forms } from './new-icons/forms.svg';
import { ReactComponent as GreenFill } from './new-icons/green.svg'
import { ReactComponent as Border } from './new-icons/Border.svg'
import { ReactComponent as CalenderWhite } from './new-icons/calender-white.svg'





import SvgIcon from "@mui/material/SvgIcon";

const iconConfigs = {
  width: "7",
  height: "7",
  viewBox: "0 0 7 7",
};

const Icon = (props: any) => {
  return <SvgIcon {...props}></SvgIcon>;
};
export const dashboardIcon = (props: any) => (
  <SvgIcon component={dashboard1} {...props}></SvgIcon>
);
export const Group520Icon = (props: any) => (
  <SvgIcon component={Group520} {...props}></SvgIcon>
);
export const Group529Icon = (props: any) => (
  <SvgIcon component={Group529} {...props}></SvgIcon>
);
export const Group436Icon = (props: any) => (
  <SvgIcon component={Group436} {...props}></SvgIcon>
);
export const Group432Icon = (props: any) =>
  Icon({ ...props, component: Group432, ...iconConfigs });

export const AIcon = (props: any) => (
  <SvgIcon component={A} {...props}></SvgIcon>
);
export const Group437Icon = (props: any) => (
  <SvgIcon component={Group437} {...props}></SvgIcon>
);
export const ProfileIcon = (props: any) => (
  <SvgIcon component={Profile} {...props}></SvgIcon>
);
export const Group438Icon = (props: any) => (
  <SvgIcon component={Group438} {...props}></SvgIcon>
);
export const Group401Icon = (props: any) => (
  <SvgIcon component={Group401} {...props}></SvgIcon>
);
export const Polygon15Icon = (props: any) => (
  <SvgIcon component={Polygon15} {...props}></SvgIcon>
);
export const PeopleIcon = (props: any) => (
  <SvgIcon component={People} {...props}></SvgIcon>
);
export const EditIcon = (props: any) =>
  Icon({ ...props, component: Edit, viewBox: "0 0 14 14" });
export const DeleteIcon = (props: any) =>
  Icon({
    ...props,
    component: Delete,
    viewBox: "0 0 15 15",
  });
export const AttachementIcon = (props: any) =>
  Icon({
    ...props,
    component: Attach,
    viewBox: "0 0 9 9",
  });
export const GroupIcon = (props: any) => (
  <SvgIcon component={Group} {...props}></SvgIcon>
);
export const Group2Icon = (props: any) =>
  Icon({
    ...props,
    component: Group2,
    viewBox: "0 0 15 15",

  });


export const MenuIcon = (props: any) =>
  Icon({
    ...props,
    component: Menu,
    viewBox: "0 0 13 13",

  });

export const Group1Icon = (props: any) => (
  <SvgIcon component={Group1} {...props}></SvgIcon>
);

// export const MenuIcon = (props: any) => (
//   <SvgIcon component={Menu} {...props}></SvgIcon>
// );
export const ExploreIcon = (props: any) => (
  <SvgIcon component={Explore1} {...props}></SvgIcon>
);

export const ActivityIcon = (props: any) => (
  <SvgIcon component={Activity} {...props}></SvgIcon>
);
export const Group448Icon = (props: any) => (
  <SvgIcon component={Group448} {...props}></SvgIcon>
);
export const GoogleIcon = (props: any) => (
  <SvgIcon component={Google} {...props}></SvgIcon>
);
export const FacebookIcon = (props: any) => (
  <SvgIcon component={Facebook} {...props}></SvgIcon>
);
export const Setting2Icon = (props: any) => (
  <SvgIcon component={Setting2} {...props}></SvgIcon>
);
export const CardTickIcon = (props: any) => (
  <SvgIcon component={CardTick} {...props}></SvgIcon>
);
export const IcDashboardIcon = (props: any) => (
  <SvgIcon component={IcDashboard} {...props}></SvgIcon>
);
export const BoxTickIcon = (props: any) => (
  <SvgIcon component={BoxTick} {...props}></SvgIcon>
);
export const SmsTrackingIcon = (props: any) => (
  <SvgIcon component={SmsTracking} {...props}></SvgIcon>
);
export const ReportIcon = (props: any) => (
  <SvgIcon component={Report} {...props}></SvgIcon>
);
export const SettingsIcon = (props: any) => (
  <SvgIcon component={Settings} {...props}></SvgIcon>
);
export const AboutIcon = (props: any) => (
  <SvgIcon component={About} {...props}></SvgIcon>
);
export const HelpIcon = (props: any) => (
  <SvgIcon component={Help} {...props}></SvgIcon>
);
export const LogoutIcon = (props: any) => (
  <SvgIcon component={Logout} {...props}></SvgIcon>
);
// export const LogoutIcon = (props: any) => (
//   <SvgIcon component={} {...props}></SvgIcon>
// );

export const HomeIcon = (props: any) => (
  <SvgIcon component={Home} {...props}></SvgIcon>
);

export const SidebarTogglerIcon = (props: any) => (
  <SvgIcon component={SidebarToggler} {...props}></SvgIcon>
);
export const SearchIcon = (props: any) => (
  <SvgIcon component={Search} {...props}></SvgIcon>
);
export const TotalRevenueIcon = (props: any) => (
  <SvgIcon component={TotalRevenue} {...props}></SvgIcon>
);
export const SettledBalanceIcon = (props: any) => (
  <SvgIcon component={SettledBalance} {...props}></SvgIcon>
);
export const TopArrowIcon = (props: any) => (
  <SvgIcon component={TopArrow} {...props}></SvgIcon>
);

export const MessageIcon = (props: any) => (
  <SvgIcon component={Message} {...props}></SvgIcon>
);

export const NotificationIcon = (props: any) => (
  <SvgIcon component={Notification} {...props}></SvgIcon>
);
export const VisibilityIcon = (props: any) => (
  <SvgIcon component={Visibility} {...props}></SvgIcon>
);

export const CommunityIcon = (props: any) => (
  <SvgIcon component={Community} {...props}></SvgIcon>
);
export const PreminumIcon = (props: any) => (
  <SvgIcon component={Preminum} {...props}></SvgIcon>
);
export const LockIcon = (props: any) => (
  <SvgIcon component={Lock} {...props}></SvgIcon>
);
export const BuildCommunityIcon = (props: any) => (
  <SvgIcon component={BuildCommunity} {...props}></SvgIcon>
);
export const PlansIcon = (props: any) => (
  <SvgIcon component={Plans} {...props}></SvgIcon>
);
export const InviteUsersIcon = (props: any) => (
  <SvgIcon component={InvitiesUsers} {...props}></SvgIcon>
);
export const AddIcon = (props: any) =>
  Icon({
    ...props,
    component: AddIcon,
    viewBox: "0 0 17 17",

  });
export const MessageInfo1 = (props: any) => (
  <SvgIcon component={MessageInfo} {...props}></SvgIcon>
);
export const CreatePostIcon = (props: any) => (
  <SvgIcon component={CreatePost} {...props}></SvgIcon>
);
// export const KingIcon = (props: any) => (
//   <SvgIcon component={King} {...props}></SvgIcon>
// );
export const KingIcon = (props: any) =>
  Icon({
    ...props,
    component: King,
    viewBox: "0 0 17 17",

  });
export const SwitchIcon = (props: any) =>
  Icon({
    ...props,
    component: Switch,
    viewBox: "0 0 17 17",

  });
export const SearchIcon1 = (props: any) =>
  Icon({
    ...props,
    component: SearchCard,
    viewBox: "0 0 30 30",

  });
export const LikeIcon = (props: any) =>
  Icon({
    ...props,
    component: Like,
    viewBox: "0 0 25 25",

  });
export const LoveIcon = (props: any) =>
  Icon({
    ...props,
    component: Love,
    viewBox: "0 0 25 25",

  });

export const AdminPerson = (props: any) =>
  Icon({
    ...props,
    component: SheildPerson,
    viewBox: "0 0 19 19",

  });
export const CalenderIcon = (props: any) =>
  Icon({
    ...props,
    component: Calender,
    viewBox: '0 0 16 16',
  });

export const CalenderWhiteIcon = (props: any) =>
  Icon({
    ...props,
    component: CalenderWhite,
    viewBox: '0 0 16 16',
  });

export const WebsiteIcon = (props: any) =>
  Icon({
    ...props,
    component: Website,
    viewBox: '0 0 16 16',
  });

export const CourseIcon = (props: any) =>
  Icon({
    ...props,
    component: Course,
    viewBox: '0 0 16 16',
  });
export const PaymentsIcon = (props: any) =>
  Icon({
    ...props,
    component: Payments,
    viewBox: '0 0 16 16',
  });
export const FormsIcon = (props: any) =>
  Icon({
    ...props,
    component: Forms,
    viewBox: '0 0 16 16',
  });
export const ProductIcon = (props: any) =>
  Icon({
    ...props,
    component: Products,
    viewBox: '0 0 16 16',
  });
export const LinkShareIcon = (props: any) => Icon({
  ...props,
  component: LinkShare,
  viewBox: '0 0 19 19',
});

export const GreenFillIcon = (props: any) => Icon({
  ...props,
  component: GreenFill,
  viewBox: '0 0 22 22',
});

export const BorderIcon = (props: any) => Icon({
  ...props,
  component: Border,
  viewBox: '0 0 22 22',
});



