import React, { useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  Typography,
  Box,
  Chip,
  TextField,
  InputAdornment,
  Divider,
  DialogProps,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PublicIcon from "@mui/icons-material/Public";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import { Link } from "react-router-dom";
import Close from "@mui/icons-material/Close";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// import { CommunityMembers } from "../../../models/community-user.model";



interface CommunityPreviewProps {
  isOpen: boolean;
  handleClose: () => void;
  coverImageSrc: string;
  id: string;
  logo: string;
  description: string;
  title: string;
  city: string;
  members: [];
  totalmembers: number;
  status: string;
  type: string;
  handleCopyLink: () => void;
  copied: boolean;
  whatsappShareUrl: string | null;
  facebookShareUrl: string | null;
  linkedinShareUrl: string | null;
  twitterShareUrl: string | null;
  link: string;

}

const CommunityPreview: React.FC<CommunityPreviewProps> = ({
  isOpen,
  handleClose,
  coverImageSrc,
  id,
  logo,
  title,
  description,
  city,
  members,
  type,
  totalmembers,
  status,
  handleCopyLink,
  copied,
  whatsappShareUrl,
  facebookShareUrl,
  linkedinShareUrl,
  twitterShareUrl,
  link

}) => {
  // const [showFullContents, setShowFullContents] = React.useState(false);
  // const [showFullContent, setShowFullContent] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [fullWidth,] = React.useState(true);
  const [maxWidth,] = React.useState<DialogProps["maxWidth"]>("sm");
  // const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);


  // const handleToggleContents = () => {
  //   setShowFullContents((prevShowFullContents) => !prevShowFullContents);
  // };

  // const handleToggleContent = () => {
  //   setShowFullContent(!showFullContent);
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleCloses = () => {
    setOpen(false);
  };





  // const handleClickSocial = () => {
  //   setOpen(true);
  // };

  // const handleCloseSocial = () => {
  //   setOpen(false);
  // };

  const [maxImgWidth,] = React.useState<DialogProps["maxWidth"]>("sm");

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };


  const maxLength = 6; // Maximum length for the title

  const truncatedTitle = title?.length > maxLength ? title?.substring(0, maxLength) : title;

  const label = `${USER_APP_BASE_URL}/${truncatedTitle}`;


  return (
    <>
      {/* Popup  */}
      {/* Pop Up for Image Display */}
      <Stack sx={{ padding: '0px' }}>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxImgWidth}
          open={isOpen}
          onClose={handleClose}


        >
          <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ backgroundColor: '#F0F9FF' }}
            >
              <Stack></Stack>
              <Stack>

              </Stack>
              <Stack sx={{ backgroundColor: '#F0F9FF' }}>

                <Box
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "#50A1CA",
                    padding: "4px",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontSize: "10px",
                    mt: "0.5px",
                    mr: "10px",
                    cursor: "pointer",
                    "&:hover": {
                      // backgroundColor: "#50A1CA", // Background color on hover
                      cursor: "pointer",
                      border: "none",
                    },
                  }}
                >
                  <Close sx={{ fontSize: "15px" }} />
                </Box>
              </Stack>
            </Stack>
          </DialogActions>


          <DialogContent sx={{ padding: '0px' }}>
            {/* <Stack
          sx={{
            marginTop: "12vh",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            borderRadius: "0px 0px 15px 15px",
            maxWidth: "600px",
            // height: "auto",
            justifyContent: "center",
            alignContent: "center",
            border: "none",
          }}
        > */}
            <div style={{ position: "relative" }}>
              <img
                src={coverImageSrc}
                alt="Cover"
                style={{
                  width: "100%",
                  height: "auto",  // Set height to auto to maintain aspect ratio
                  maxHeight: 200,  // Set the maximum height
                  objectFit: "cover",  // Use object-fit to handle the cropping
                  position: "relative",
                }}
              />
            </div>

            {/* Bottom Section - Description */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#ffffff",
                marginTop: "-35px",
                position: 'relative',
                borderRadius: "20px 20px 0px 0px",
                borderTop: '1px solid #A9A9A9',
                boxShadow: '0px -5px 5px -5px #A9A9A9', // Adjust values as needed
              }}
            >
              {/* Add display picture (DP) */}
              <Box sx={{ position: "relative" }}>
                <Avatar
                  src={logo}
                  alt="DP"
                  sx={{
                    width: "100px",
                    height: "100px",
                    position: "relative",
                    top: "-70px",
                    zIndex: 1, // Ensure the Avatar is on top of the white circle
                  }}
                />
                <Avatar
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "120px", // Adjust the size to fit your design
                    height: "120px", // Adjust the size to fit your design
                    borderRadius: "100%",
                    position: "absolute",
                    top: "-80px", // Adjust the top position to align with the Avatar
                    left: "-10px", // Adjust the left position to align with the Avatar
                    zIndex: 0, // Set a lower zIndex to place it beneath the Avatar
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add a box shadow
                  }}
                />
              </Box>

              <Typography
                sx={{ fontWeight: "700", fontSize: "18px", mt: "-50px", fontFamily: "Montserrat", }}
                variant="body1"
                gutterBottom
              >
                {/* {title || ""} */}
                {title?.length > 50 ? title.slice(0, 50) + '...' : title}
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ fontFamily: "Montserrat" }}>
                {city ? (
                  <>
                    {city?.length > 20 ? `${city?.slice(0, 20)}...` : city}
                  </>
                ) : (
                  <>
                    No city
                  </>
                )} | {members} Members
              </Typography>

              <Stack
                direction={"row"}
                justifyContent="center"
                sx={{

                  ml: 0.5
                }}
              >
                <Chip
                  label={label}
                  icon={<PublicIcon sx={{ fontSize: '18px' }} />}
                  component={status === 'PUBLISHED' ? 'a' : 'div'}
                  href={status === 'PUBLISHED' ? `${USER_APP_BASE_URL}/community/${title?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${id}` : undefined}
                  target={status === 'PUBLISHED' ? '_blank' : undefined}
                  clickable={status === 'PUBLISHED'}
                  sx={{
                    backgroundColor: '#f1f1f1',
                    fontSize: '12px',
                    textTransform: 'lowercase',
                    cursor: status === 'PUBLISHED' ? 'pointer' : 'default',
                    "@media (max-width: 600px)": {
                      marginBottom: "1rem",
                    },
                  }}
                // component="a"
                // href={`${USER_APP_BASE_URL}/communities/${id}/about`}
                // target="_blank"
                // clickable
                // sx={{
                //   backgroundColor: "#f1f1f1",
                //   fontSize: "12px",
                //   textTransform: 'lowercase',
                //   "@media (max-width: 600px)": {
                //     marginBottom: "1rem",
                //   },
                // }}
                />
                <InputAdornment position="end">
                  <Button
                    sx={{
                      backgroundColor: "#f1f1f1",
                      textTransform: "capitalize",
                      color: "#636363",
                      fontWeight: "normal",
                      mt: 4,
                      fontSize: "12px",
                      display: { xs: 'none', md: 'flex', },
                      "&:hover": { backgroundColor: "#f1f1f1" },
                      borderRadius: "20px 20px 20px 20px",
                    }}
                    // onClick={handleCopyLink}
                    variant="text"
                    color="primary"
                    startIcon={<FileCopyOutlinedIcon />}
                    onClick={handleCopyLink}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </Button>
                </InputAdornment>
                {link && (
                  <Typography
                    component={Link}
                    to={link}
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#50A1CA",
                      textAlign: "right",
                      alignItems: "end",
                      cursor: "pointer",
                      textDecoration: "underline",
                      mt: 1,
                      ml: 1
                    }}

                  >
                    Go to Publish
                  </Typography>
                )}
              </Stack>
            </div>
            <Box sx={{}}>
              <Stack
                gap={1}
                direction="row"
                sx={{ fontWeight: 500, pt: 1, mx: 2, color: "#636363" }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "13px", md: "15px" },
                    fontWeight: 500,
                    textDecoration: "none",
                    color: "#50A1CA",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  About Us :-
                </Typography>
              </Stack>
              {/* <Typography
                sx={{
                  fontWeight: 400,
                  // pt: 1,
                  mx: 2,
                  color: "#636363",
                  fontSize: "14px",
                }}
              >
                {" "}
                {description?.length > 150 ? ( // Check if description has more than 150 characters
                  showFullContents ? (
                    <>{description}</>
                  ) : (
                    <>
                      {description?.slice(0, 130)}...
                      <Button
                        onClick={handleClickOpen}
                        sx={{
                          textTransform: "capitalize",
                          color: "#50A1CA",
                          justifyContent: "right",
                          alignContent: "right",
                          padding: '0px',
                          fontSize:'14px'
                        }}
                      >
                        &nbsp;&nbsp; Show More
                      </Button>
                    </>
                  )
                ) : (
                  // If the description has 150 characters or less, just display the full description
                  <>{description}</>
                )}
              </Typography> */}
              <SimpleBar style={{ height: '60px', overflowY: 'auto' }}>
                <Typography
                  variant="caption"
                  paragraph={true}
                  className="description"
                  sx={{ color: '#565656', fontSize: '13px', overflow: 'hidden', textOverflow: 'ellipsis', mx: 2, fontFamily: "Montserrat", }}
                >
                  {showFullDescription ? (description || '') : (description ? description.slice(0, 150) + ' ' : '')}
                  &nbsp; &nbsp;
                  {description && description.length > 150 && (
                    <Button
                      size="small"
                      onClick={handleToggleDescription}
                      sx={{
                        color: "#50A1CA",
                        fontWeight: "400",
                        fontSize: '12px',
                        textTransform: 'capitalize',
                        textDecoration: 'underline',
                        cursor: "pointer",
                        p: 0
                      }}
                    >
                      {showFullDescription ? 'Show Less' : 'Show More'}
                    </Button>
                  )}
                </Typography>
              </SimpleBar>

            </Box>

            <Box sx={{}}>
              <Stack
                gap={1}
                direction="row"
                sx={{
                  fontWeight: 400,
                  pt: 2,
                  mx: 2,
                  pb: 2,
                  color: "#636363",
                  justifyContent: "center",
                  fontFamily: "Montserrat",
                  alignContent: "center",
                }}
              >
                {/* <Button
                sx={{
                  color: "white",
                  mb: 1,
                  textTransform: "capitalize",
                  backgroundColor: "#3B9B7F",
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "#3B9B7F",
                    borderShadow: "none",
                  },
                }}
                variant="contained"
                startIcon={<ShareIcon />}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Roboto",
                  }}
                >
                  Share Community
                </Typography>
              </Button> */}
              </Stack>

              {status === 'PUBLISHED' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    pb: 3,
                    mx: 2,
                    mt: 1,
                  }}
                >
                  <TextField
                    size="small"
                    sx={{
                      borderRadius: '100px',
                      width: { xs: '100%', sm: '100%', md: "calc(50% - 5px)", },
                      '&:hover': { border: 'none' },
                    }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button
                            sx={{
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              fontSize: { xs: '13px', md: '15px' },
                              fontWeight: 600,
                              backgroundColor: '#3B9B7F',
                              cursor: 'default',
                              p: { xs: 1, md: 0.7 },
                              fontFamily: "Montserrat",
                              '&:hover': { backgroundColor: '#3B9B7F' },
                              ml: '-15px',
                              borderRadius: '20px 0px 0px 20px',
                            }}
                          >
                            Share With
                          </Button>
                          <Divider orientation="vertical" sx={{ height: 38 }} />
                          <Stack
                            direction="row"
                            spacing={{ xs: 2, md: 1.6 }}
                            alignItems="center"
                            ml="10px"
                            sx={{ pt: 1, ml: { xs: "5px", md: "8px", } }}
                          >
                            {whatsappShareUrl && (
                              <a
                                href={whatsappShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <WhatsAppIcon
                                  sx={{
                                    fontSize: { xs: "25px", md: "30px", },
                                    color: '#25d366',
                                  }}
                                />
                              </a>
                            )}
                            {facebookShareUrl && (
                              <a
                                href={facebookShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FacebookIcon
                                  sx={{
                                    fontSize: { xs: "25px", md: "30px", },
                                    color: '#3b5998',
                                  }}
                                />
                              </a>
                            )}
                            {linkedinShareUrl && (
                              <a
                                href={linkedinShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LinkedInIcon
                                  sx={{
                                    fontSize: { xs: "25px", md: "30px", },
                                    color: '#1976d2',
                                  }}
                                />
                              </a>
                            )}
                            {twitterShareUrl && (
                              <a
                                href={twitterShareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <TwitterIcon
                                  sx={{
                                    fontSize: { xs: "25px", md: "30px", },
                                    color: '#1da1f2',
                                  }}
                                />
                              </a>
                            )}
                          </Stack>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '100px',
                        backgroundColor: '#FFFFFF',
                      },
                    }}
                  />
                </Box>
              )}

              {status !== 'PUBLISHED' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    pb: 3,
                    mx: 2,
                    opacity: 0.5,
                  }}
                >
                  <TextField
                    size="small"
                    sx={{
                      borderRadius: '100px',
                      width: { xs: '100%', sm: '100%', md: "calc(50% - 5px)", },
                      '&:hover': { border: 'none' },
                    }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button
                            sx={{
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              fontSize: { xs: "14", md: "16px", },
                              fontWeight: 600,
                              backgroundColor: '#CCCCCC',
                              cursor: 'pointer',
                              ml: '-15px',
                              borderRadius: '20px 0px 0px 20px',
                            }}
                            onClick={handleCopyLink}
                          >
                            Share With
                          </Button>
                          <Divider orientation="vertical" sx={{ height: 38 }} />
                          <Stack direction="row" spacing={1.5} alignItems="center" ml="10px" sx={{ pt: 0.5 }}>
                            <WhatsAppIcon sx={{ fontSize: { xs: "25px", md: "30px", }, color: '#CCCCCC', cursor: 'pointer', }} onClick={handleCopyLink} />
                            <FacebookIcon sx={{ fontSize: { xs: "25px", md: "30px", }, color: '#CCCCCC', cursor: 'pointer', }} onClick={handleCopyLink} />
                            <LinkedInIcon sx={{ fontSize: { xs: "25px", md: "30px", }, color: '#CCCCCC', cursor: 'pointer', }} onClick={handleCopyLink} />
                            <TwitterIcon sx={{ fontSize: { xs: "25px", md: "30px", }, color: '#CCCCCC', cursor: 'pointer', }} onClick={handleCopyLink} />
                          </Stack>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '100px',
                        backgroundColor: '#DDDDDD',
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
            {/* </Stack> */}

          </DialogContent>
        </Dialog>

      </Stack>







      {/* Pop Up for About us Content */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleCloses}
      >

        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"

            sx={{ backgroundColor: "#F0F9FF", }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                textDecoration: "none",
                color: "#50A1CA",

                // textTransform: "uppercase",
                // mr:0.5
              }}
            >
              ABOUT COMMUNITY
            </Typography>
            {/* <Button onClick={() => handleClose()}>X</Button> */}
            <Box
              onClick={handleCloses}
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "10px",
                mt: "0.5px",
                mr: "10px",
                cursor: "pointer",
                "&:hover": {
                  // backgroundColor: "#50A1CA", // Background color on hover
                  cursor: "pointer",
                  border: "none",
                },
              }}
            >
              <Close sx={{ fontSize: "15px" }} />
            </Box>
          </Grid>

        </DialogActions>

        <DialogContent sx={{ p: 2 }}>
          <DialogContentText>
            <Typography sx={{ fontSize: "15px" }}>{description}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CommunityPreview;
