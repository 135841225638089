import axios from 'axios';
import { BASE_URL, BASE_URL_V2 } from '../configurations/url.config';

export const fetchAllAppointments = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/temp-all-user/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchAllPastAppointments = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/all-admin-past/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchAppointmentById = async (
  token: string,
  appointmentId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointments/${appointmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response?.data?.appointments, 'response');
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};


export const fetchNoAppointmentById = async (
  appointmentId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointments/${appointmentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response?.data?.appointments, 'response');
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};



export const fetchAllAppointmentBookings = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/all-user-month/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const initiatePayment = async (
  token: string,
  communityId: string,
  appointmentId: string,
  amount: number,
  availabilityId: string,
  timeSlotId: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/community/${communityId}/appointment/${appointmentId}`,
      {
        amount: amount,
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const paymentRequestFree = async (
  token: string,
  communityId: string,
  appointmentId: string,
  availabilityId: string,
  timeSlotId: string
) => {
  try {
    // console.log('Making request to book appointment...');
    const response = await axios.post(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}/request-to-join`,
      {
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log('Response:', response);
    return response;
  } catch (err: any) {
    const errorMessage =
      err?.response?.data?.message || 'Unknown error occurred';
    // console.error('Error making request:', errorMessage);

    // console.log(err?.response?.status);
    // console.error('Full error:', err);

    return {
      status: err?.response?.status || 500,
      data: [],
      message: errorMessage,
    };
  }
};



export const initiatePaymentNoAuth = async (
  amount: number,
  appointmentId: string,
  availabilityId: string,
  timeSlotId: string,
  name:string,
  email:string,
  phoneNumber:string,
  commId: string,
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payments/appointment/${appointmentId}`,
      {
        amount: amount,
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
        name:name,
        email:email,
        phoneNumber:phoneNumber,
        commId:commId
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const paymentRequestFreeNoAuth = async (
  appointmentId: string,
  availabilityId: string,
  timeSlotId: string,
  name:string,
  email:string,
  phoneNumber:string,
  communityId: string,


) => {
  try {
    // console.log('Making request to book appointment...');
    const response = await axios.post(
      `${BASE_URL_V2}/appointments/${appointmentId}/request-to-join`,
      {
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
        name:name,
        email:email,
        phoneNumber:phoneNumber,
        communityId:communityId
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log('Response:', response);
    return response;
  } catch (err: any) {
    const errorMessage =
      err?.response?.data?.message || 'Unknown error occurred';
    return {
      status: err?.response?.status || 500,
      data: [],
      message: errorMessage,
    };
  }
};

export const cancelAppointment = async (
  token: string,
  appointmentId: string,
  communityId: string,
  availabilityId: string,
  timeSlotId: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}/user-cancel`,
      {
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const submitUserForm = async (
  token: string,
  communityId: string,
  appointmentId: string,
  email: string,
  phoneNumber: string,
  availabilityId: string,
  timeSlotId: string
) => {
  try {
    // console.log('availabilityId123', availabilityId);
    const response = await axios.post(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}/user-form`,
      {
        email,
        phoneNumber,
        availabilityId,
        timeSlotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // console.log('responseinapi', response);
    return response;
  } catch (err: any) {
    const errorMessage =
      err?.response?.data?.message || 'Unknown error occurred';
    // console.error('Error submitting form:', errorMessage);

    return {
      status: err?.response?.status || 500,
      data: [],
      message: errorMessage,
    };
  }
};
