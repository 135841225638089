import { Box, Grid, Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AllClass from './Components/AllClasses';
import React from 'react';
import PastClasses from './Components/PastClasses';

const LiveClass = () => {


  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <>
      <Stack direction={'row'} flexGrow={1} spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 600,
              color: "#000000",
              fontFamily: "Montserrat",
              textAlign: "center",
            }}
          >
            Meetings
          </Typography>
        </Grid>
      </Stack>

      <Box sx={{ width: '100%', mt: 1, }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <TabList onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#50A1CA',
                },
              }}>
              <Tab
                label="Present Meetings"
                value="1"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 600,
                  },
                }}
              />
              <Tab
                label="Past Meetings"
                value="2"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 600,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ px: 0, py: 2, }}>
            <AllClass />
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0, py: 2 }}>
            <PastClasses />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default LiveClass;
