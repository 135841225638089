//BACKEND END POINTS URL ------------------------------------------------------------------------->

// // Local
// export const BASE_URL = 'http://localhost:5002/api/v1';
// export const BASE_URL_V2 = 'http://localhost:5002/api/v2.0';

// UAT
// export const BASE_URL = "https://api-uat.onecommunn.com/api/v1";
// export const BASE_URL_V2 = "https://api-uat.onecommunn.com/api/v2.0";

//Production
export const BASE_URL = "https://api.onecommunn.com/api/v1";
export const BASE_URL_V2 = "https://api.onecommunn.com/api/v2.0";

//  export const ADMIN_APP_BASE_URL2 = "http://localhost:3000";
export const ADMIN_APP_BASE_URL = 'https://admin-uat.onecommunn.com';
// export const ADMIN_APP_BASE_URL = 'https://admin.onecommunn.com';

// export const USER_APP_BASE_URL = 'https://member.onecommunn.com';
export const USER_APP_BASE_URL = 'https://user-uat.onecommunn.com';
// export const USER_APP_BASE_URL = "http://localhost:3001";
export const USER_APP = 'https://member.onecommunn.com';

export const AI_URL = 'http://16.171.174.90/suggestions';
export const AI_URL_V2 = 'http://16.171.174.90/suggestions/v2';

//AI APP URL --------------------------------------------------------------------------------------------
export const AI_APP_BASE_URL = 'https://ai-uat.onecommunn.com';
