import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import { useCommunity } from "../../../../hooks/useCommunity";
import { ICommunity } from "../../../../models/communities.modal";
import SearchCommunityCard from "./SwitchCommunityCard";
import { useNavigate } from "react-router-dom";
import { SearchIcon1 } from "../../../../assets/icons";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import SmallLoader from "../../../Loader/SmallLoader";
// import { ADMIN_APP_BASE_URL } from "../../../../configurations/url.config";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ADMIN_APP_BASE_URL } from "../../../../configurations/url.config";
import { NoCommunities } from "../../../AlternatePages/NoCommunities";
import { useDispatch, } from "react-redux";
import { loadSelectedCommunity } from "..//..//..//..//store/slices/CommunitySlice";

function CustomTabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "13px",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

type Props = {
  getData: (data: any) => void;
  onCommunitySelect: () => void;
};

const MAX_MODAL_HEIGHT = 410;
const SwitchCommunity = ({ getData, onCommunitySelect }: Props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, getCommunitiesList, memberCommunities } = useCommunity();

  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [communities, setCommunities] = useState<ICommunity[]>([]);

  const [selectedCommunityIds] = useState<string[]>([]);
  const [filteredCommunities, setFilteredCommunities] = useState<any[]>([]);
  const [filteredMemberCommunities, setFilteredMemberCommunities] = useState<any[]>([]);
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);


  useEffect(() => {
    getAdminCommunitiesList();
    // getMemberCommunitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  // 
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getAdminCommunitiesList = async () => {
    const response = await getCommunitiesList();
    setCommunities(response);
  };
  // const getMemberCommunitiesList = async () => {
  //   const responseData = await memberCommunities();
  //   setMyMemberCommunities(responseData);
  // };

  useEffect(() => {
    async function fetchData() {
      const responseData = await memberCommunities();
      setMyMemberCommunities(responseData);
    }
    fetchData();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // console.log(myMemberCommunities)
  const handleChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
    setSearchQuery("");
  };

  useEffect(() => {
    const filtered = communities.filter((community) =>
      community.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    const sortedFiltered = filtered.sort((a, b) => {
      if (a?.favorite && !b?.favorite) {
        return -1;
      } else if (b?.favorite && !a?.favorite) {
        return 1;
      } else {
        return 0;
      }
    });

    setFilteredCommunities(sortedFiltered);
  }, [communities, searchQuery, selectedCommunityIds]);


  useEffect(() => {
    let filtered;
    if (searchQuery) {
      filtered = myMemberCommunities.filter((item) =>
        item.community.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
    } else {
      // If searchQuery is empty, display all member communities
      filtered = myMemberCommunities;
    }

    const sortedFiltered = filtered.sort((a, b) => {
      if (a?.favorite && !b?.favorite) {
        return -1;
      } else if (b?.favorite && !a?.favorite) {
        return 1;
      } else {
        return 0;
      }
    });

    setFilteredMemberCommunities(sortedFiltered);
  }, [searchQuery, myMemberCommunities]);
  // eslint-disable-next-line
  const [selectSearch, setSelectSearch] = useState<null | string>(
    "Search Community..."
  );
  // eslint-disable-next-line
  const [communityDataSearch, setCommunityDataSearch] = useState<ICommunity | null>(null);

  const selectedCommunity = (elm: any) => {
    const communityObject = elm?.community;
    const _id = communityObject?._id;
    if ((elm?.subscriptionStatus === "CREATED" || elm?.subscriptionStatus === "EXPIRED") && elm?.community?.collectSubscription === "YES") {
      navigate('/subscriptions', { state: { _id } });
      dispatch(loadSelectedCommunity(communityObject));
    }
    setCommunityDataSearch(communityObject);
    setSelectSearch(communityObject.title);
    onCommunitySelect();
    getData(communityObject);
  };

  const maxHeight = Math.min(
    MAX_MODAL_HEIGHT,
    filteredCommunities.length * 100
  );


  const navigateToAdminFlow = (id: string) => {
    const token = localStorage.getItem("access-token");
    const url = `${ADMIN_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    window.open(url, "_blank");
  };

  // Inside your component
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const mobileViewHeight = 370; // Define the height for mobile view
  const desktopViewHeight = 380; // Define the height for desktop view

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  return (
    <>
      <Box sx={{ width: "100%", height: { xs: "59vh", md: "70vh" }, }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
                p: 0,
                fontFamily: "Montserrat",
              },
              "& .MuiTab-root": {
                width: "50%",
                textTransform: "capitalize",
                fontSize: { xs: "15px", md: "14px" },
                color: "#000000",
                border: "none",
                fontWeight: 500,
                fontFamily: "Montserrat",

                "&.Mui-selected": {
                  backgroundColor: "#50A1CA",
                  borderRadius: "100px",
                  color: "#ffffff",
                  mt: 1,
                  mb: 1,
                  fontFamily: "Montserrat",
                  minHeight: { xs: "30px", md: "35px" },
                  maxHeight: { xs: "30px", md: "35px" },
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "white",
                  fontWeight: 500,
                  color: "#000000",
                  fontFamily: "Montserrat",
                },
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Member " {...a11yProps(1)} />
            <Tab label="Admin " {...a11yProps(0)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            border: "0.1px solid gray",
            marginBottom: { xs: "1vw", md: "1vw" },
            borderRadius: "2vw",
            mt: 1,
          }}
        >
          <Search>
            <SearchIconWrapper
              sx={{ ml: { xs: "0px", md: "-30px" }, mt: "4px" }}
            >
              <SearchIcon1 />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ fontSize: { xs: "15px", md: "1.2vw", xl: "1vw" } }}
              inputProps={{ "aria-label": "search" }}
              placeholder="Search community"
            />
          </Search>
        </Box>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ maxHeight: maxHeight }}>
              <SimpleBar style={{ height: `${height}px` }}>
                {/* Render communities excluding the selected communities */}
                {filteredMemberCommunities?.map((elm: any, i: number) => (
                  <div
                    key={i}
                    onClick={() => {
                      elm.community = {
                        ...elm.community,
                        id: elm.community._id,
                      };

                      // eslint-disable-next-line no-lone-blocks
                      {
                        elm.status !== "BLOCK" &&
                          selectedCommunity(elm);
                      }
                    }}
                  >
                    <SearchCommunityCard
                      data={elm}
                    // onFavoriteIconClick={handleFavoriteIconClick}
                    />
                  </div>
                ))}
              </SimpleBar>
            </Box>
          </CustomTabPanel>
        )}

        {!isLoading && value === 1 && (
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ maxHeight: maxHeight }}>
              <SimpleBar style={{ height: `${height}px` }}>
                {filteredCommunities.length > 0 ? (
                  filteredCommunities.map((elm, i) => (
                    <div key={i} onClick={() => navigateToAdminFlow(elm?._id)}>
                      <SearchCommunityCard
                        data={elm}
                      // onFavoriteIconClick={handleFavoriteIconClick}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <NoCommunities />
                  </div>
                )}
              </SimpleBar>
            </Box>
          </CustomTabPanel>
        )}
      </Box>
      <Box sx={{ width: { xs: "65vw", md: "41vw" } }}>
        {/* You can add additional content if needed */}
      </Box>
    </>
  );
};

export default SwitchCommunity;
