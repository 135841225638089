import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { Login } from "./components/Auth/Login/Login";
import { useContext } from "react";
import { AuthContext } from "./contexts/Auth.context";
import Loader from "./components/Loader/Loader";
import Messages from "./components/messages/Messages";
import Register from "./components/Auth/Login/Register";
import Profile from "./components/Layout/components/NavigationBar/Profile";
import ProfileEdit from "./components/Auth/Login/ProfileEdit";
import ExploreCommunities from "./components/ExploreCommunities/ExploreCommunities";
import ExploreCreateCommunities from "./components/ExploreCommunities/ExploreCreateCommunities";
import Home from "./components/Home/Home";
import Activities from "./components/Activities/Activities";
import NoAuthLayout from "./components/Layout/NoAuthLayout";
import CommunityInvitations from "./components/Invitations/CommunityInvitations";
import TrendingCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/TrendingCommunitiesGird";
import InvitiesCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/InvitiesCommunitiesGird";
import JoinedCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/JoinedCommunitiesGird";
import AdminCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/AdminCommunitiesGird";
import Members from "./components/members/Members";
import UserPayments from "./components/Payments/UserPayments";
import NoAuthCommunityLayout from "./components/AboutShare/NoAuthCommunity/NoAuthCommunity";
import PlansList from "./components/Plans/PlanList";
import About from "./components/community/aboutCommunity/About";
import NewLayout from "./components/Layout/NewLayout";
import SubscriptionList from "./components/Subscription/SubscriptionList";
import SubscriptionLayout from "./components/Subscription/SubscriptionLayout/SubscriptionLayout";
import Courses from "./components/Courses/Courses";
import ViewCourse from "./components/Courses/ViewCourse";
import AiPost from "./components/Posts/AIpost";
import NewAboutShare from "./components/AboutShare/NewAboutShare";
import UserSubscription from "./components/Plans/SubscriptionList/UserSubscription";
import Notifications from "./components/Notifications/Notifications";
import AutoLogin from "./components/Auth/Login/AutoLogin";
import Delete from "./components/Layout/components/NavigationBar/Delete";
import EnablePopupsNotification from "./components/AlternatePages/EnableSafariPopup";
import LiveClass from "./components/LiveClasses/LiveClass";
import ClassDetails from "./components/LiveClasses/ClassDetails";
import LiveClassShare from "./components/LiveClasses/Components/LiveShare/LiveClassShare";
import Appointments from "./components/Appointments/Appointments";
import NewExploreLayout from "./components/Layout/NewExploreLayout";
import NewExplore from "./components/ExploreCommunities/NewExplore";
import Availability from "./components/Appointments/Availability";
import AppointmentShare from "./components/Appointments/AppointmentShare/AppointmentShare";



const RequireAuth = (props: any) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  if (authContext.loading) {
    return <Loader />;
  }

  if (authContext.isAuthenticated) {
    return props.children;
  } else if (location.pathname.includes("/communities/")) {
    return (
      <>
        <NoAuthCommunityLayout />
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

const LoginWrapper = (props: any) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  if (authContext.loading) {
    return <Loader />;
  }
  return authContext.isAuthenticated ? (
    <Navigate to="/" replace={true} />
  ) : location.pathname.includes("/communities/:id/about") ? (
    <NoAuthCommunityLayout />
  ) : (
    props.children
  );
};

function App() {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <>
      <EnablePopupsNotification />
      <Routes>
        <Route
          element={
            <RequireAuth>
              <NewLayout />
            </RequireAuth>
          }
        >
          <Route path="/home/:id/:token" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" replace={true} />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/course-lists/view/:id" element={<ViewCourse />} />
          <Route path="/members" element={<Members />} />
          <Route path="/profile/posts/:id" element={<Profile />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/home" element={<Home />} />
          <Route path="/payment-success" element={<Home />} />
          <Route path="/payment-failure" element={<Home />} />
          <Route path="/ai-generate" element={<AiPost />} />
          <Route path="/invitations" element={<CommunityInvitations />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/:name/:id/about" element={<About />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/delete" element={<Delete />} />
          <Route
            path="/paymentss?status=failed&key=failed&ref_id=S24020914CXG54"
            element={<UserPayments />}
          />
        </Route>
      </Routes>

      <Routes>
        <Route element={
          <RequireAuth>
            <NewExploreLayout />
          </RequireAuth>
        }>
          <Route path='/new-explore' element={<NewExplore />} />
        </Route>
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="/payments" element={<UserPayments />} />
          <Route
            path="/payments/:status/:key/:amount"
            element={<UserPayments />}
          />
          <Route
            path="/plans/subscription/:id"
            element={<UserSubscription />}
          />
          <Route path="/meetings" element={<LiveClass />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/plans" element={<PlansList />} />
          <Route path="/meeting/:id" element={<ClassDetails />} />
          <Route path="/appointment/:name/:id" element={<Availability />} />
          <Route path="/appointment-confirmation/:name/:id" element={<Availability />} />
          <Route path="/profile/:id" element={<ProfileEdit />} />
        </Route>
        <Route
          path="/login"
          element={
            <LoginWrapper>
              <Login />
            </LoginWrapper>
          }
        />
        <Route
          path="/auto-login"
          element={
            <LoginWrapper>
              <AutoLogin />
            </LoginWrapper>
          }
        />
      </Routes>

      <Routes>
        {isAuthenticated ? (
          <Route
            path="/community/:name/:id"
            element={<NewAboutShare />}
          />
        ) : (
          <Route
            path="/community/:name/:id"
            element={<NewAboutShare />}
          />
        )}
      </Routes>

      <Routes>
        {isAuthenticated ? (
          <Route path="/communities/:id/about" element={<NewAboutShare />} />
        ) : (
          <Route path="/communities/:id/about" element={<NewAboutShare />} />
        )}
        <Route path="/live-class/:id" element={<LiveClassShare />} />
        {/* {isAuthenticated ? (
          <Route path="/appointment/:id" element={<AppointmentShare />} />
        ) : (
          <Route path="/appointment/:id" element={<AppointmentShare />} />
        )} */}

        <Route path="/book-appointment/:name/:id" element={<AppointmentShare />} />
        <Route path="/confirmation/:name/:id" element={<AppointmentShare />} />

      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <SubscriptionLayout />
            </RequireAuth>
          }
        >
          <Route path="/subscriptions" element={<SubscriptionList />} />
          <Route path="/subscriptions/:id" element={<UserSubscription />} />
        </Route>
      </Routes>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <NoAuthLayout />
            </RequireAuth>
          }
        >
          <Route path="/explore-communities" element={<ExploreCommunities />} />
          <Route
            path="/admin-communities"
            element={<AdminCommunitiesGird data={[]} />}
          />
          <Route
            path="/joined-communities"
            element={<JoinedCommunitiesGird />}
          />
          <Route
            path="/invites-communities"
            element={<InvitiesCommunitiesGird />}
          />
          <Route
            path="/trending-communities"
            element={<TrendingCommunitiesGird />}
          />
          <Route
            path="/explore-create-community"
            element={<ExploreCreateCommunities />}
          />
          <Route
            path="/explore-create-community/:token"
            element={<ExploreCreateCommunities />}
          />
          <Route path="/profile-edit/:id" element={<ProfileEdit />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default App;
