import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AvailableTime, IAppointment } from '../../../../models/appointments.models';
import { paymentRequestFreeNoAuth } from '../../../../services/appointment.service';
import ShareAvailabilityConfirmation from './ShareAvailabilityConformations';
import { IPaymentList } from '../../../../models/payment.model';
import { useAppointments } from '../../../../hooks/useAppointments';
import { LockOutlined } from '@mui/icons-material';
import PaymentSuccess from '../../../Payments/PaymentSuccessPopUp';
import PaymentFailed from '../../../Payments/PaymentFailedPopUp';
import { usePayment } from '../../../../hooks/usePayment';


interface IAppointmentDetails {
    appointmentDetails: IAppointment[];
    selectedTime: AvailableTime | undefined;
    selectedDate: Date;
}


export enum PaymentStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    PENDING = 'PENDING',
}

interface IForm {
    name: string;
    email: string;
    phoneNumber: string;
}

const ShareAvailabilityDetails: React.FC<IAppointmentDetails> = ({
    appointmentDetails,
    selectedTime,
    selectedDate,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { NoAuthInitiatePaymentByIds } = useAppointments();
    const { getPaymentStatusByIdNoAuth } = usePayment();
    const [isLoading, setIsLoading] = useState(false);
    const [transaction, setTransaction] = useState<IPaymentList | null>(null);
    const [page, setPage] = useState(2);

    const [timer, setTimer] = useState(5);

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);

    const [transactionAmount, setTransactionAmount] = useState('');


    const [formValues, setFormValues] = useState<IForm>({
        name: '',
        email: '',
        phoneNumber: ''
    })



    const formattedSelectedDate = format(selectedDate, 'yyyy-MM-dd');

    const convertTo12HourFormat = (time24: string): string => {
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const appointmentResponse = async (response: any) => {
        try {
            const transaction = response?.appointment as IPaymentList;

            if (appointmentDetails) {
                setTransaction(transaction);
            }
            if (response?.url) {
                const windowFeatures = `width=${Math.min(
                    1000,
                    window.screen.width
                )},height=${Math.min(1000, window.screen.height)},left=${(window.screen.width - 1000) / 2
                    },top=${(window.screen.height - 1000) / 2}`;
                const windowRef = window.open(response.url, windowFeatures);

                let timeoutReached = false;
                const timeoutDuration = 120000;
                const timeout = setTimeout(() => {
                    timeoutReached = true;
                    clearInterval(intervalRef);
                    windowRef?.close();
                    console.error('Appointment timeout reached');
                }, timeoutDuration);

                const intervalRef = setInterval(async () => {
                    if (timeoutReached) return;
                }, 1000);
            } else {
                console.error('Appointment URL not provided in response');
            }
        } catch (error) {
            console.error('An error occurred in appointmentResponse:', error);
        }
    };


    const handleSuccessClose = () => {
        setTimer(3);
        setSuccessOpen(false);
    };

    const handleFailureClose = () => {
        setTimer(3);
        setFailureOpen(false);
    };

    const handlePayment = async (
        amount: number,
        appointmentId: string,
        availabilityId: string,
        timeSlotId: string,
        name: string,
        email: string,
        phoneNumber: string,
        commId: string,
    ) => {
        setIsLoading(true);
        setFormValues((prevState) => ({
            ...prevState,
            name,
            email,
            phoneNumber,
        }));
        try {
            const paymentResponse = await NoAuthInitiatePaymentByIds(
                amount,
                appointmentId,
                availabilityId,
                timeSlotId,
                name,
                email,
                phoneNumber,
                commId,
            );
            if (paymentResponse?.url) {
                const { transactionId, url } = paymentResponse;
                const screenWidth = window.screen.width;
                const screenHeight = window.screen.height;
                const width = Math.min(1000, screenWidth);
                const height = Math.min(1000, screenHeight);
                const left = (screenWidth - width) / 2;
                const top = (screenHeight - height) / 2;

                const windowRef = window.open(
                    url,
                    'paymentWindow',
                    `width=${width},height=${height},left=${left},top=${top},resizable=no`
                );
                const intervalRef = setInterval(async () => {
                    try {
                        const paymentStatus = await getPaymentStatusByIdNoAuth(transactionId);
                        setTransactionAmount(paymentStatus[0]?.amount);
                        if (paymentStatus && paymentStatus.length > 0) {
                            clearInterval(intervalRef);
                            windowRef?.close();
                            if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
                                setSuccessOpen(true);
                                await appointmentResponse(paymentResponse);
                                setPage(3);
                            } else {
                                setFailureOpen(true);
                            }
                        }
                    } catch (statusError) {
                        console.error('Error fetching payment status:', statusError);
                    }
                }, 1000);
            } else {
                enqueueSnackbar('Payment URL not provided in response', {
                    variant: 'error',
                });
            }
        } catch (error) {
            console.error('Error during payment handling:', error);
            enqueueSnackbar('Payment failed, please try again!', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };


    const navigate = useNavigate();

    const handleFreeBook = async (
        appointmentId: string,
        availabilityId: string,
        timeSlotId: string,
        name: string,
        email: string,
        phoneNumber: string,
        communityId: string,
        selectedTimes: string,
        selectedDate: string
    ) => {
        setIsLoading(true)
        try {
            setFormValues((prevState) => ({
                ...prevState,
                name,
                email,
                phoneNumber,
            }));
            const response = await paymentRequestFreeNoAuth(
                appointmentId,
                availabilityId,
                timeSlotId,
                name,
                email,
                phoneNumber,
                communityId,
            );
            if (response?.status === 200) {
                enqueueSnackbar('Appointment booked successfully.', { variant: 'success' });
                const formattedDate = new Date(selectedDate);
                formattedDate.setDate(formattedDate.getDate() + 1);
                const formattedDateString = formattedDate.toISOString().split('T')[0];
                const formattedTime = convertTo12HourFormat(selectedTimes);
                const url = `/confirmation/${appointmentDetails?.[0]?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${appointmentId}?time=${encodeURIComponent(
                    formattedTime + 1
                )}&date=${encodeURIComponent(formattedDateString)}`;
                navigate(url);
                setPage(3);
            }
            else if (response?.status === 400) {
                enqueueSnackbar('Your already part of the community, please login and book appointment.', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('An error occurred during payment initiation', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false)
        }
    };


    return (
        <>
            {appointmentDetails?.map((appointment) => {
                const communityId = appointment?.community;
                const appointmentId = appointment?._id || '';
                const amount = appointment?.pricing || 0;
                const availabilityId =
                    appointment?.availability?.find(
                        (availability) => availability?.day === formattedSelectedDate
                    )?._id || '';
                const timeSlotId = selectedTime?._id || '';

                return (
                    <>
                        {page === 2 && (
                            <>

                                <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                                    <Stack >
                                        <Typography
                                            variant='h5'
                                            sx={{
                                                fontSize: '16px',
                                                color: '#000000',
                                                fontWeight: '600',
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            Enter Details
                                        </Typography>
                                        <Grid container spacing={2} mt={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#000000',
                                                        fontWeight: '500',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    Name
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    name="name"
                                                    label="Name"
                                                    size="small"
                                                    value={formValues.name}
                                                    onChange={(e) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            name: e.target.value,
                                                        })
                                                    }
                                                    InputLabelProps={{
                                                        style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                                    }}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 1,
                                                        fontSize: '13px',
                                                        backgroundColor: '#ffffff',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#000000',
                                                        fontWeight: '500',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    Email
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    name='email'
                                                    value={formValues.email}
                                                    onChange={(e) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            email: e.target.value,
                                                        })
                                                    }
                                                    label="Email"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                                                    }}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 1,
                                                        fontSize: '13px',
                                                        backgroundColor: '#ffffff',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#000000',
                                                        fontWeight: '500',
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    Phone number
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    name="phoneNumber"
                                                    value={formValues.phoneNumber}
                                                    onChange={(e) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            phoneNumber: e.target.value,
                                                        })
                                                    }
                                                    label="Phone"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat' },
                                                    }}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 1,
                                                        fontSize: '13px',
                                                        backgroundColor: '#ffffff',
                                                        fontFamily: 'Montserrat'
                                                    }}
                                                />

                                                {/* <Button
                                                    startIcon={<AddOutlined />}
                                                    variant='outlined'
                                                    sx={{
                                                        textTransform: 'capitalize',
                                                        borderRadius: '5px',
                                                        boxShadow: 'none',
                                                        fontFamily: 'Montserrat',
                                                        color: '#50A1CA',
                                                        border: 'none',
                                                        '&:hover': {
                                                            border: 'none',
                                                            backgroundColor: "transparent",
                                                            color: '#50A1CA',
                                                        },
                                                    }}>
                                                    Add Guests
                                                </Button> */}
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                    <Box sx={{ mt: 2 }} display={'flex'} justifyContent={'flex-end'}>
                                        {appointment?.isPaidService ? (
                                            <>
                                                <Button
                                                    endIcon={<LockOutlined />}
                                                    variant='contained'
                                                    sx={{
                                                        background:
                                                            'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                        textTransform: 'capitalize',
                                                        borderRadius: '5px',
                                                        boxShadow: 'none',
                                                        fontFamily: 'Montserrat',

                                                        '&:hover': {
                                                            background:
                                                                'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                            color: 'white',
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        handlePayment(
                                                            amount,
                                                            appointmentId,
                                                            availabilityId,
                                                            timeSlotId,
                                                            formValues?.name,
                                                            formValues?.email,
                                                            formValues?.phoneNumber,
                                                            communityId,
                                                        )
                                                    }
                                                >
                                                    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : " Continue to Payment"}
                                                </Button>
                                            </>

                                        ) : (
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    background:
                                                        'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                    textTransform: 'capitalize',
                                                    borderRadius: '5px',
                                                    boxShadow: 'none',
                                                    fontFamily: 'Montserrat',

                                                    '&:hover': {
                                                        background:
                                                            'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                        color: 'white',
                                                    },
                                                }}
                                                onClick={() =>
                                                    handleFreeBook(
                                                        appointmentId,
                                                        availabilityId,
                                                        timeSlotId,
                                                        formValues?.name,
                                                        formValues?.email,
                                                        formValues?.phoneNumber,
                                                        communityId,
                                                        selectedTime?.startTime || "",
                                                        selectedDate.toISOString().split("T")[0]
                                                    )
                                                }
                                            >
                                                {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : " Book Appointment"}
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )
                        }
                        {page === 3 && (
                            <ShareAvailabilityConfirmation
                                transaction={transaction}
                                appointmentDetails={appointmentDetails}
                                selectedTime={selectedTime}
                                selectedDate={selectedDate}
                            />
                        )}
                        <PaymentSuccess
                            txnid={transaction?.txnid || ''}
                            open={successOpen}
                            amount={transactionAmount || ''}
                            timer={timer}
                            dialogText={''}
                            onClose={handleSuccessClose}
                            onClickNavigateButton={() => {
                                navigate('/home');
                                setSuccessOpen(false);
                            }}
                        />
                        <PaymentFailed
                            open={failureOpen}
                            onClose={handleFailureClose}
                            amount={transactionAmount || ''}
                            txnid={transaction?.txnid || ''}
                            onClickNavigateButton={() => {
                                navigate('#');
                                setFailureOpen(false);
                            }}
                            dialogText={''}
                            timer={timer}
                            OnClickRetryButtonFunction={() => {
                                setFailureOpen(false);
                            }}
                        />
                    </>
                );
            })}
        </>
    );
};

export default ShareAvailabilityDetails;
