import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AddPost } from "../../../sub-components/AddPost";
import { IPost } from "../../../Posts/Posts";
import Fab from "@mui/material/Fab";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import store from "../../../../store";


const MobileNav = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState<"Add" | "Edit">("Add");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setMode("Add");
  };

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  // console.log(selectedCommunity, "selectedCommunity")

  const StyledFab = styled(Fab)({
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  });


  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const usersData = store.getState();

  const [userId] = useState(usersData.loggedInUser.user?.id);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, p: 0.3 }}
        elevation={3}
      >
        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Toolbar>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: "auto" }}
            >
              <BottomNavigationAction
                label={
                  <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                    Home
                  </span>
                }
                icon={
                  <HomeRoundedIcon
                    sx={{
                      fontSize: "20px",
                      color:
                        location.pathname !== "/home" ? "#92929D" : "#50A1CA",
                      "& .Mui-selected": {
                        color:
                          location.pathname === "/home" ? "#50A1CA" : "#92929D",
                      },
                    }}
                  />
                }
                sx={{
                  color: location.pathname !== "/home" ? "#92929D" : "#50A1CA",
                  "& .Mui-selected": {
                    color:
                      location.pathname === "/home" ? "#50A1CA" : "#92929D",
                  },
                }}
                component={RouterLink}
                to="/home"
              />
              {/* {selectedCommunity?.selectedCommunity?.collectSubscription === "YES" ? (
                <BottomNavigationAction
                  label={
                    <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                      Payments
                    </span>
                  }
                  icon={
                    <PaymentsRoundedIcon
                      sx={{
                        fontSize: "20px",
                        color:
                          location.pathname !== "/payments"
                            ? "#92929D"
                            : "#50A1CA",
                        "& .Mui-selected": {
                          color:
                            location.pathname === "/payments"
                              ? "#50A1CA"
                              : "#92929D",
                        },
                      }}
                    />
                  }
                  sx={{
                    color:
                      location.pathname !== "/payments" ? "#92929D" : "#50A1CA",
                    "& .Mui-selected": {
                      color:
                        location.pathname === "/payments" ? "#50A1CA" : "#92929D",
                    },
                  }}
                  component={RouterLink}
                  to="/payments"
                />

              ) : (
                <BottomNavigationAction
                  label={
                    <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                      About
                    </span>
                  }
                  icon={
                    <InfoRoundedIcon
                      sx={{
                        fontSize: "20px",
                        color:
                          location.pathname !== `/community/${selectedCommunity?.selectedCommunity?._id}/about`
                            ? "#92929D"
                            : "#50A1CA",
                        "& .Mui-selected": {
                          color:
                            location.pathname === `/community/${selectedCommunity?.selectedCommunity?._id}/about`
                              ? "#50A1CA"
                              : "#92929D",
                        },
                      }}
                    />
                  }
                  sx={{
                    color:
                      location.pathname !== `/community/${selectedCommunity?.selectedCommunity?._id}/about` ? "#92929D" : "#50A1CA",
                    "& .Mui-selected": {
                      color:
                        location.pathname === `/community/${selectedCommunity?.selectedCommunity?._id}/about` ? "#50A1CA" : "#92929D",
                    },
                  }}
                  component={RouterLink}
                  to={`/community/${selectedCommunity?.selectedCommunity?._id}/about`}
                />
              )} */}
              <BottomNavigationAction
                label={
                  <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                    Payments
                  </span>
                }
                icon={
                  <PaymentsRoundedIcon
                    sx={{
                      fontSize: "20px",
                      color:
                        location.pathname !== "/payments"
                          ? "#92929D"
                          : "#50A1CA",
                      "& .Mui-selected": {
                        color:
                          location.pathname === "/payments"
                            ? "#50A1CA"
                            : "#92929D",
                      },
                    }}
                  />
                }
                sx={{
                  color:
                    location.pathname !== "/payments" ? "#92929D" : "#50A1CA",
                  "& .Mui-selected": {
                    color:
                      location.pathname === "/payments" ? "#50A1CA" : "#92929D",
                  },
                }}
                component={RouterLink}
                to="/payments"
              />
            </BottomNavigation>
            <StyledFab color="secondary" aria-label="add">
              <BottomNavigationAction
                icon={<AddIcon />}
                sx={{
                  background:
                    "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                  padding: 0,
                  borderRadius: "50%",
                  minWidth: "15%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  "&.Mui-selected": {
                    color: "#ffffff",
                  },
                }}
                onClick={handleOpenModal}
              />
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: "auto" }}
            >
              <BottomNavigationAction
                label={
                  <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                    Plans
                  </span>
                }
                icon={
                  <AccountBalanceWalletRoundedIcon
                    sx={{
                      fontSize: "20px",
                      color:
                        location.pathname !== "/plans" ? "#92929D" : "#50A1CA",
                      "& .Mui-selected": {
                        color:
                          location.pathname === "/plans"
                            ? "#50A1CA"
                            : "#92929D",
                      },
                    }}
                  />
                }
                sx={{
                  color: location.pathname !== "/plans" ? "#92929D" : "#50A1CA",
                  "& .Mui-selected": {
                    color:
                      location.pathname === "/plans" ? "#50A1CA" : "#92929D",
                  },
                }}
                component={RouterLink}
                to="/plans"
              />


              <BottomNavigationAction
                label={
                  <span style={{ fontSize: "12px", fontFamily: "Montserrat" }}>
                    Members
                  </span>
                }
                icon={
                  <PeopleRoundedIcon
                    sx={{
                      fontSize: "20px",
                      color:
                        location.pathname !== "/members"
                          ? "#92929D"
                          : "#50A1CA",
                      "& .Mui-selected": {
                        color:
                          location.pathname === "/members"
                            ? "#50A1CA"
                            : "#92929D",
                      },
                    }}
                  />
                }
                sx={{
                  color:
                    location.pathname !== "/members" ? "#92929D" : "#50A1CA",
                  "& .Mui-selected": {
                    color:
                      location.pathname === "/members" ? "#50A1CA" : "#92929D",
                  },
                }}
                component={RouterLink}
                to="/members"
              />
            </BottomNavigation>
          </Toolbar>
        </AppBar>

        <AddPost
          open={isModalOpen}
          onClose={handleCloseModal}
          postHeder={mode === "Edit" ? "Edit Post" : "Add New Post"}
          selectedCommunity={[selectedCommunity?.selectedCommunity]}
          placeHolder=""
          updateExistingPost={function (): void {
            throw new Error("Function not implemented.");
          }}
          updatePosts={function (newPost: IPost): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Paper >
    </Box >
  );
};
export default MobileNav;
