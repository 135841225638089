import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    Toolbar,
} from "@mui/material"
import { useState } from "react";

import { MenuIcon } from "../../../../assets/icons";



interface Props {
    windows?: () => Window;

}


const AppointmentShareHeader = (props: Props) => {
    const { windows } = props;

    // console.log(props?.data, "Props")

    const drawerWidth = 240;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ p: 1 }}>
            <Box
                component={"img"}
                src={require("../../../../assets/images/communnlanding.png")}
                sx={{
                    backgroundColor: "white",
                    padding: 2,
                    // height: { xs: "120px", md: "100px" },
                    width: { xs: "200px", md: "100px" },
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "10px",
                }}
            />
            <Divider />
            <List>

            </List>
        </Box>
    );

    const container =
        windows !== undefined ? () => windows().document.body : undefined;

    return (
        <>
            {/* Header  Start*/}
            <Box sx={{ display: "flex", justifyContent: "center", }}>
                <CssBaseline />
                <AppBar
                    component="nav"
                    sx={{ backgroundColor: "white", boxShadow: "none" }}
                >
                    <Toolbar sx={{ justifyContent: "space-between", borderBottom: '1px solid #E7EBF1' }}>
                        <Box
                            component={"img"}
                            src={require("../../../../assets/images/communnlanding.png")}
                            sx={{
                                backgroundColor: "white",
                                padding: { xs: 1, md: 2 },
                                width: { xs: "50%", md: '20%' },
                                zIndex: 1,
                                borderRadius: "10px",
                            }}
                        />
                        <Box sx={{ display: { xs: "block", sm: "block" } }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                sx={{ display: { sm: "none" } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: "block", sm: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box>
            {/* Header  End*/}
        </>
    )
}

export default AppointmentShareHeader;