import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styles } from "./Sidebar.styles";
import { NavLink } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  BoxTickIcon,
  Setting2Icon,
} from "./../../../../assets/icons";
import { AuthContext } from "../../../../contexts/Auth.context";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import EventIcon from '@mui/icons-material/Event';
import CastConnectedIcon from '@mui/icons-material/CastConnected';

const getIcon = (name: string) => {
  switch (name) {
    case "HomeIcon":
      return <HomeRoundedIcon />;
    case "CardTickIcon":
      return <PaymentsRoundedIcon />;
    case "IcDashboardIcon":
      return <AccountBalanceWalletRoundedIcon />;
    case "PeopleIcon":
      return <PeopleRoundedIcon />;
    case "BoxTickIcon":
      return <BoxTickIcon />;
    case "SmsTrackingIcon":
      return <AssignmentRoundedIcon />;
    case "CommunityIcon":
      return <Diversity2RoundedIcon />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon />;
    case "Setting2Icon":
      return <Setting2Icon />;
    case "LogoutIcon":
      return <LogoutIcon />;
    case "AboutIcon":
      return <InfoRoundedIcon />;
    case "ExploreIcon":
      return <FormatIndentDecreaseRoundedIcon />;
    case "ReportsIcon":
      return <AssessmentRoundedIcon />;
    case "CourseIcon":
      return <AutoStoriesRoundedIcon />;
    case 'LiveIcon':
      return <CastConnectedIcon />;
    case 'AppointmentIcon':
      return <EventIcon />;
    default:
      return null;
  }
};

interface IMenuItem {
  name: string;
  icon: string;
  url: string;
  type?: string;
  children: any[];
}

interface ISidebar {
  open: boolean;
}
export default function Sidebar({ open }: ISidebar) {
  const { logout } = useContext(AuthContext);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const community_id = selectedCommunity?._id;

  // console.log(selectedCommunity?.collectSubscription, "selectedCommunity")

  const handleMenuClick = (menu: IMenuItem) => {
    if (menu.url === "logout") {
      logout();
    } else {
      const isExpanded = expandedItems.includes(menu.name);
      let newExpandedItems: string[];

      if (isExpanded) {
        newExpandedItems = expandedItems.filter((item) => item !== menu.name); // Remove the menu from expandedItems
      } else {
        newExpandedItems = [menu.name]; // Only expand the clicked menu and close others
      }
      setExpandedItems(newExpandedItems);
      // setParentExpanded(true); // Always set parentExpanded to true on menu click
    }
  };

  // const fullUrl = window.location.href;

  const menusList: IMenuItem[][] = [
    [
      {
        name: "Home",
        icon: "HomeIcon",
        url: "/home",
        children: [],
      },
      {
        name: "Plans",
        icon: "IcDashboardIcon",
        url: "/plans",
        children: [],
      },

      {
        name: "Payments",
        icon: "CardTickIcon",
        url: "/payments",
        children: [],
      },

      {
        name: "Courses",
        icon: "CourseIcon",
        url: "/courses",
        children: [],
      },
      {
        name: "Members",
        icon: "PeopleIcon",
        url: "/members",
        children: [],
      },

      // {
      //   name: "About",
      //   icon: "CommunityIcon",
      //   url: fullUrl.includes('community')
      // ? `/community/${community_id}/about`
      // : `/communities/${community_id}/about`,
      //   children: [

      //   ],
      // },

      {
        name: 'Meetings',
        icon: 'LiveIcon',
        url: '/meetings',
        children: [],
      },
      {
        name: 'Appointments',
        icon: 'AppointmentIcon',
        url: '/appointments',
        children: [],
      },

      {
        name: "About",
        icon: "AboutIcon",
        url: `/${selectedCommunity?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community_id}/about`,
        children: [],
      },

      {
        name: "Explore ",
        icon: "ExploreIcon",
        url: "/explore-communities",
        children: [],
      },
    ],
  ];

  const menuItem = (menu: IMenuItem) => {
    return (
      <>
        <Stack direction={"row"} alignItems="center" className="menu-item">
          {getIcon(menu.icon)} {open ? menu.name : ""}
        </Stack>
      </>
    );
  };
  // const filteredMenusList = open ? menusList : menusList.map((list) => list.filter((menu) => !menu.children || menu.children.length === 0));
  return (
    <Box sx={styles}>
      <Stack spacing={open ? 1 : 0} className={open ? "expanded" : "collapsed"}>
        {menusList.map((list: IMenuItem[], i: number) => {
          return (
            <React.Fragment key={i}>
              {list.map((menu: IMenuItem, ii: number) => {
                return menu.type !== "button" ? (
                  <React.Fragment key={i + "navlink" + ii}>
                    <NavLink
                      to={menu.url}
                      className={({ isActive }) =>
                        `nav-link ${isActive ? "active" : ""}`
                      }
                      onClick={() => handleMenuClick(menu)}
                    >
                      {menuItem(menu)}
                    </NavLink>
                    {expandedItems.includes(menu.name) &&
                      menu.children &&
                      menu.children.length > 0 && (
                        <Box>
                          <ul>
                            <Stack
                              sx={{
                                fontSize: "14px",
                                marginTop: "-20px",
                                ml: 2.6,
                                color: "#565656",
                              }}
                            >
                              {menu.children.map((subMenu: IMenuItem) => (
                                <li key={subMenu.name}>
                                  <NavLink
                                    to={subMenu.url}
                                    style={{
                                      textDecoration: "none",
                                      color: "#565656",
                                    }}
                                  >
                                    {subMenu.name}
                                  </NavLink>
                                </li>
                              ))}
                            </Stack>
                          </ul>
                        </Box>
                      )}
                  </React.Fragment>
                ) : (
                  <Box
                    key={"sdsd" + ii}
                    className={`nav-link`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    {menuItem(menu)}
                  </Box>
                );
              })}
              {menusList.length > i + 1 && <Divider key={i} />}
              <Stack
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              ></Stack>
            </React.Fragment>
          );
        })}
      </Stack>
    </Box>
  );
}
