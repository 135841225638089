import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Avatar, CircularProgress, Dialog, DialogProps, Divider, Slide, TextField } from '@mui/material';
import { usePosts } from '../../hooks/usePosts';
import { ICommunity } from '../../models/communities.modal';
import { useSelector } from 'react-redux';
import { IPost } from '../Posts/Posts';
import { Close } from '@mui/icons-material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import axios from 'axios';
import { AI_APP_BASE_URL } from '../../configurations/url.config';
import { TransitionProps } from '@mui/material/transitions';
interface AddPostProps {
  open: boolean;
  onClose: () => void;
  postHeder: string;
  editPostData?: IPost;
  selectedCommunity: ICommunity[];
  updatePosts: (newPost: IPost) => void;
  updateExistingPost: (updatedPost: IPost) => void;
  placeHolder: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const AddPost: React.FC<AddPostProps> = ({
  open,
  onClose,
  postHeder,
  editPostData,
  selectedCommunity,
  updatePosts,
  updateExistingPost,
}) => {
  //a

  const { createNewPost, editPost } = usePosts();

  const selected = useSelector((state: any) => state?.selectedCommunity);
  const community = selected?.selectedCommunity;
  useEffect(() => {
    if (community) {
      setSelectedCommunities([community]);
    }
  }, [community]);

  const [selectedCommunities, setSelectedCommunities] = useState<any[]>(
    community ? [community] : []
  );


  const [selectedOption, setSelectedOption] = useState('EVERY_ONE');

  const [richTextContent, setRichTextContent] = useState('');
  const [hashTag, setHashTag] = useState('');

  const [editedPost, setEditedPost] = useState<IPost | undefined>(editPostData);
  const [isEditing, setIsEditing] = useState(false);

  const [loading, setLoading] = useState(false);

  const [aiLoading, setAiLoading] = useState(false);
  const [aiReWriteLoading, setAiRewriteLoading] = useState(false);




  const [files, setFiles] = useState<FileList | null>(null);

  const handleCreatePost = async () => {
    setLoading(true);
    const formData = new FormData();
    // console.log(formData);

    if (postHeder === 'Add New Post' && formData) {
      formData.append('whoCan', selectedOption);
      formData.append('content', richTextContent);
      formData.append('hashTag', hashTag);
      selectedCommunities?.forEach((community) => {
        formData.append('communities', community._id);
      });

      try {
        const response = await createNewPost(formData, files);
        // console.log(response);
        if (response && response.status === 201) {
          setFiles(null);
          onClose();

          updatePosts(response.data.data);
        }
      } catch (error) {
      } finally {
        // Set loading back to false regardless of success or failure
        setLoading(false);
      }
    } else if (isEditing && editedPost) {
      // console.log("edit post is calling");
      const formData = new FormData();
      formData.append('whoCan', selectedOption);
      formData.append('content', richTextContent);
      formData.append('hashTag', hashTag);
      // Append the selected communities to the FormData
      selectedCommunities.forEach((community) => {
        formData.append('communities', community._id);
      });

      try {
        const response = await editPost(editedPost._id, formData, files);

        // console.log(response?.data.data);

        if (response && response.status === 200) {
          setFiles(null);
          setLoading(false);
          onClose();

          updateExistingPost(response?.data?.data);
        }
      } catch (error) { }
    }
  };
  // open Modal Style
  useEffect(
    () => {
      if (postHeder === 'Edit Post' && editPostData) {
        setEditedPost(editPostData);
        setIsEditing(true);
        setSelectedCommunities(editPostData?.communities ?? []);
        setSelectedOption(editPostData.whoCan);
        setRichTextContent(editPostData?.content ?? '');
        setHashTag(editPostData?.hashTag);
      } else {
        // Reset fields for other modes
        setEditedPost(undefined);
        setIsEditing(false);

        setSelectedOption('EVERY_ONE');
        setRichTextContent('');
        setHashTag('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postHeder, open, onclose]
  );

  const handleContentChange = (event: any) => {
    setRichTextContent(event.target.value);
  };

  const handlePostImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      setFiles((prevFiles) => {
        const combinedFiles = prevFiles ? [...Array.from(prevFiles)] : [];
        for (let i = 0; i < files.length; i++) {
          combinedFiles.push(files[i]!);
        }
        const dataTransfer = new DataTransfer();
        combinedFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
        return dataTransfer.files;
      });
    }
  };


  const handleDeleteImage = (index: number) => {
    if (files) {
      const newFiles = Array.from(files);
      newFiles.splice(index, 1);
      setFiles(newFiles.length > 0 ? (newFiles as unknown as FileList) : null);
    }

    // console.log(e.target.files);
  };

  const isDataAvailable =
    selectedCommunities && selectedOption && (files || richTextContent);

  // console.log(selectedCommunities);

  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);


  const selectedCommunityData = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const handleClickWriteWithAI = async () => {
    setAiLoading(true);
    try {
      const response = await axios.get(`${AI_APP_BASE_URL}/social-media-post/${selectedCommunityData?._id}`);
      // console.log(response, "response");
      if (response.status === 200) {
        const { post } = response.data;
        // console.log(post, "post");
        setRichTextContent(post);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setAiLoading(false);
    }
  };


  // const handleClickReWriteWithAI = async () => {
  //   setAiRewriteLoading(true);
  //   try {
  //     const response = await axios.post(`${AI_APP_BASE_URL}/social-media-post/rewrite`,
  //       {
  //         post: richTextContent
  //       }
  //     );
  //     // console.log(response, "response");
  //     if (response.status === 200) {
  //       const { post } = response.data;
  //       // console.log(post, "post");
  //       setRichTextContent(post);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching suggestions:", error);
  //   } finally {
  //     setAiRewriteLoading(false);
  //   }
  // };

  const handleClickReWriteWithAI = async () => {
    setAiRewriteLoading(true);
    try {
      const response = await axios.post(`${AI_APP_BASE_URL}/social-media-post/create`,
        {
          post: richTextContent
        }
      );
      // console.log(response, "response");
      if (response.status === 200) {
        const { post } = response.data;
        // console.log(post, "post");
        setRichTextContent(post);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setAiRewriteLoading(false);
    }
  };
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');




  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={() => {
          onClose();
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
      >
        <Stack direction={'column'} spacing={2}>

          <Box sx={{ position: 'sticky', top: 0, zIndex: 100, backgroundColor: 'white' }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
            >
              <Stack gap={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Stack>
                  <Avatar src={loggedInUserData?.user?.avatar} sx={{}} />
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', md: '15px' },
                      fontWeight: 600,
                      textDecoration: 'none',
                      color: '#50A1CA',
                      fontFamily: 'Montserrat',
                      textTransform: "capitalize",
                    }}
                  >
                    {loggedInUserData?.user?.firstName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '10px', md: '12px' },
                      textDecoration: 'none',
                      color: '#575757',
                      fontFamily: 'Montserrat',
                      textTransform: "Capitalize",
                    }}
                  >
                    {selectedCommunityData?.name}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Box
                  onClick={onClose}
                  sx={{
                    backgroundColor: '#50A1CA',
                    padding: '4px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '10px',
                    mt: '0.5px',
                    cursor: 'pointer',
                    '&:hover': {
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </Box>
              </Stack>
            </Stack>
          </Box>

          <Stack spacing={2} sx={{ mt: 2, pl: 2, pr: 2, pt: 1, pb: 3 }}>
            <TextField
              label="Share your thoughts"
              placeholder="Share your thoughts"
              multiline
              rows={7}
              variant="outlined"
              fullWidth
              value={richTextContent}
              onChange={handleContentChange}
              sx={{
                display: { xs: 'none', md: "block" }, fontFamily: 'Montserrat',
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                },
              }}
            />
            <TextField
              label="Share your thoughts"
              placeholder="Share your thoughts"
              multiline
              rows={11}
              variant="outlined"
              fullWidth
              value={richTextContent}
              onChange={handleContentChange}
              sx={{
                display: { xs: 'block', md: "none" }, fontFamily: 'Montserrat',
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                },
              }}
            />
            <Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: '10px' }}>
              {files && (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0px', width: '100%' }}>
                  {Array.from(files).map((file, index) => {
                    const width = files.length === 1 ? '100%' : files.length === 2 ? '47%' : '30.5%';
                    return (
                      <div
                        key={index}
                        style={{
                          position: 'relative',
                          margin: '5px',
                          flex: `0 0 ${width}`,
                          height: 'auto',
                        }}
                      >
                        <img
                          src={URL.createObjectURL(file)}
                          alt={URL.createObjectURL(file)}
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: '4px',
                          }}
                        />
                        <button
                          onClick={() => handleDeleteImage(index)}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            color: 'white',
                            right: '5px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '3px',
                            fontSize: '0px',
                            backgroundColor: '#50A1CA',
                            borderRadius: '100%',
                          }}
                        >
                          <Close sx={{ fontSize: '15px' }} />
                        </button>
                      </div>
                    );
                  })}
                  {files.length > 4 && (
                    <div
                      style={{
                        position: 'relative',
                        margin: '5px',
                        flex: '0 0 33.33%',
                        height: 'auto',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          fontSize: '16px',
                          borderRadius: '4px',
                        }}
                      >
                        +{files.length - 4}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Stack>

            <Stack
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              direction={'row'}
              sx={{
                width: { xs: '100%', md: '100%' },
                borderRadius: '5px',
                padding: '8px',
                backgroundColor: '#ffffff',
                color: '#5E6C84',
                height: '10vh'
              }}
            >
              <input
                type="file"
                name="uploadfile"
                id="img"
                multiple
                accept="image/jpeg, image/png, image/gif"
                style={{ display: 'none' }}
                onChange={handlePostImages}
              />
              {postHeder === 'Add New Post' && (
                <>
                  <label htmlFor="img">
                    <Stack display={'flex'} flexDirection={'row'} gap={2}>
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Button
                          onClick={() => {
                            if (richTextContent?.length > 10) {
                              handleClickReWriteWithAI();
                            } else {
                              handleClickWriteWithAI();
                            }
                          }}
                          startIcon={<AutoAwesomeOutlinedIcon />}
                          variant="contained"
                          sx={{
                            fontFamily: 'Montserrat',
                            textTransform: 'capitalize',
                            backgroundColor: '#3B9C80',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: '#3B9C80',
                              boxShadow: 'none',
                            },
                          }}
                        >
                          {aiLoading || aiReWriteLoading ? (
                            <CircularProgress size={24} sx={{ color: 'white' }} />
                          ) : richTextContent?.length > 10 ? (
                            'Rewrite With AI'
                          ) : (
                            'Write With AI'
                          )}
                        </Button>
                      </Stack>
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '10px',
                          backgroundColor: '#f4f5f7',
                          cursor: 'pointer',
                        }}
                      >
                        <AddPhotoAlternateOutlinedIcon />
                      </Stack>

                    </Stack>
                  </label>
                </>
              )}
              <Stack direction="row" spacing={1} sx={{ mt: 1, ml: 2 }}>
                {editPostData?.images.map((image, index) => (
                  <div
                    key={index}
                    style={{ position: 'relative', textAlign: 'center' }}
                  >
                    <img
                      src={image}
                      alt={`item ${index}`}
                      style={{
                        maxWidth: '140px',
                        maxHeight: '200px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
            <Divider></Divider>
            <Stack spacing={0} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
              <Stack
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                spacing={0}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={!isDataAvailable || loading}
                  onClick={handleCreatePost}
                  sx={{
                    px: 5,
                    pl: 2,
                    pr: 2,
                    textTransform: 'capitalize',
                    borderRadius: '5px',
                    width: '100px',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    boxShadow: 'none',
                    background: isDataAvailable
                      ? 'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)'
                      : '#B0B0B0',
                    '&:hover': {
                      background: isDataAvailable
                        ? 'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)'
                        : '#B0B0B0',
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  ) : postHeder === 'Edit Post' ? (
                    'Update'
                  ) : (
                    'Post'
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>

        </Stack>
      </Dialog>


    </>
  );
};
