import { Box, Stack, Typography } from "@mui/material";
import CommunityBrief from "./CommunityBrief";
import HomeCreateCommunity from "./HomeCreateCommunity";
import { Suggestions } from "./SuggestionsCommunity/Suggestions";

const HomeRightBar = () => {

  return (
    <Box
      sx={{
        display: { xs: "none", md: "none", lg: "block" },
        position: "sticky",
        overflowY: "auto",
      }}
    >
      <Box>
        <Stack spacing={1.5}>
          <CommunityBrief />
          <HomeCreateCommunity />
        </Stack>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          pt: 1,
          borderRadius: "10px",
        }}
      >
        <Typography
          mt={1}
          sx={{ color: "#787878", fontWeight: 600, fontSize: "14px", pb: 1, fontFamily: 'Montserrat' }}
        >
          Suggestions for you
        </Typography>
        <Box sx={{ height: '33vh', backgroundColor: 'white', borderRadius: '10px' }}>
          <Suggestions />
        </Box>
      </Box>
    </Box>
  );
};
export default HomeRightBar;
