import { Avatar, Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useLiveClasses } from "../../../../hooks/useLiveClassess";
import { ILiveClass } from "../../../../models/liveClasses.model";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LiveShareFooter from "./LiveShareFooter";
import LiveShareHeader from "./LiveShareHeader";



const LiveClassShare = () => {


    const { id } = useParams();

    // console.log(id, "id")

    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getClassByIdNoAuth } = useLiveClasses();

    const [classDetails, setClassDetails] = React.useState<ILiveClass[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // console.log(classDetails, "classDetails")

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getClassByIdNoAuth(id || " ");
            if (Array.isArray(response)) {
                setClassDetails(response);
            } else {
                setClassDetails([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setClassDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClassDetails();
    }, []);


    const defaultImages = 'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png'


    return (
        <>
            {classDetails?.map((classDetail, index) => {
                return (
                    <>

                        {/* Header?\ */}
                        <Stack>
                            <LiveShareHeader data={classDetail?.community} />

                        </Stack>
                        <Stack>
                            <Grid container>
                                <Grid item xs={1}>

                                </Grid>
                                <Grid item xs={10} sx={{ mt: 10 }}>
                                    <Box>
                                        <Paper sx={{ p: 1, backgroundColor: '#f3f5fe', height: { xs: '60vh', md: '80vh' }, borderRadius: '10px' }} elevation={0}>
                                            <Stack
                                                component="div"
                                                sx={{
                                                    backgroundImage: classDetail?.coverImage?.value
                                                        ? `url(${classDetail.coverImage.value})`
                                                        : `url(${defaultImages})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    p: 5,
                                                    borderRadius: '5px',
                                                    height: { xs: '25vh', md: '40vh' }
                                                }}
                                            >
                                            </Stack>
                                            <Box sx={{ p: { xs: 1.5, md: 5 } }}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} md={7}>
                                                        <Typography
                                                            sx={{ fontWeight: 'bold', fontSize: '30px', color: '#575757' }}>
                                                            {classDetail?.name}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '13px',
                                                                color: '#5E6C84'
                                                            }}>
                                                            {classDetail?.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        position="relative"
                                                        sx={{ mt: 5 }}
                                                    >
                                                        <Paper
                                                            sx={{
                                                                position: 'absolute',
                                                                top: { xs: '-50%', md: "-50%" },
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                border: '1px solid #E9E9E9',
                                                                borderRadius: '15px',
                                                                backgroundColor: 'white',
                                                                width: { xs: '100%', sm: '70%', md: '400px' },
                                                                // maxWidth: '400px',
                                                                p: { xs: 1, md: 5 },
                                                                ml: '20px'
                                                            }}
                                                            elevation={0}
                                                        >
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '25px', md: '30px' }, color: '#575757' }}>Meeting Details</Typography>
                                                            <Stack display={"flex"} flexDirection={'row'} gap={1} alignItems={'center'} py={3} justifyContent={'space-between'}>
                                                                <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                                                    <CalendarMonthOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                                                    <Typography sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        color: '#565656',
                                                                        fontFamily: 'Montserrat',
                                                                    }}>
                                                                        {new Date(classDetail?.startDate).toLocaleString('en-GB', {
                                                                            timeZone: 'Asia/Kolkata',
                                                                            year: 'numeric',
                                                                            month: 'short',
                                                                            day: '2-digit',
                                                                            hour12: false,
                                                                        })}
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                                                    <AccessTimeOutlinedIcon
                                                                        sx={{ fontSize: '22px', color: '#0000008a', }} />
                                                                    <Typography sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        color: '#565656',
                                                                        fontFamily: 'Montserrat',
                                                                    }}>
                                                                        {classDetail?.startTime} - {classDetail?.endTime}
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                                                <VideoCameraFrontOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                                                <Typography sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#565656',
                                                                    fontFamily: 'Montserrat',
                                                                }}>
                                                                    {classDetail?.googleMeetLink}
                                                                </Typography>
                                                            </Stack>
                                                            <Stack display={"flex"} flexDirection={'row'} gap={1} alignItems={'center'} py={3} justifyContent={'space-between'}>
                                                                <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                                                    <PersonOutlineOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                                                    <Typography sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        color: '#565656',
                                                                        fontFamily: 'Montserrat',
                                                                    }}>
                                                                        {classDetail?.instructorName}
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>

                                                                    <Avatar src={classDetail?.community?.logo} />
                                                                    <Typography sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 600,
                                                                        background:
                                                                            '-webkit-linear-gradient(180deg, #50A1CA 0%, #3B9B7F 100%);',
                                                                        WebkitBackgroundClip: 'text',
                                                                        WebkitTextFillColor: 'transparent',
                                                                        fontFamily: 'Montserrat',
                                                                    }}>
                                                                        {classDetail?.community?.name}
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack>
                                                                <Button
                                                                    size="medium"
                                                                    fullWidth
                                                                    variant="contained"
                                                                    sx={{
                                                                        textTransform: 'capitalize',
                                                                        backgroundColor: '#3B9C80',
                                                                        borderRadius: '10px',
                                                                        height: '6vh',
                                                                        boxShadow: 'none',
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        '&:hover': {
                                                                            backgroundColor: '#3B9C80',
                                                                            boxShadow: 'none',
                                                                        },
                                                                    }}
                                                                    component="a"
                                                                    href={classDetail?.googleMeetLink || '#'}
                                                                    target="_blank"


                                                                >
                                                                    Join
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        fontFamily: 'Montserrat',
                                                                        textTransform: 'capitalize',
                                                                        color: '#50A1CA',
                                                                        border: '1px solid #50A1CA',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        borderRadius: '10px',
                                                                        mt: 2,
                                                                        height: '6vh',
                                                                    }}
                                                                    component="a"
                                                                    href={`https://wa.me/?text=${encodeURIComponent(
                                                                        `Join the class using this link: ${classDetail?.googleMeetLink}`
                                                                    )}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Share
                                                                </Button>
                                                            </Stack>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Box>


                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                            </Grid>
                        </Stack>

                        {/* Footer */}

                        <Stack>
                            <LiveShareFooter />
                        </Stack>
                    </>
                )
            })}

        </>
    )
}
export default LiveClassShare