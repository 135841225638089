import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import BreadCrumbs from '../AlternatePages/BreadCrumbs';
import React, { useContext, useState } from 'react';
import { AvailableTime, IAppointment } from '../../models/appointments.models';
import Logo from './../../assets/images/Communn-favicon.png';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useAppointments } from '../../hooks/useAppointments';
import { IPaymentList } from '../../models/payment.model';
import Confirmation from './Confirmation';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { paymentRequestFree } from '../../services/appointment.service';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { SubNavbar } from '../Layout/components/SubNavbar';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { FormState } from '../../models/form.model';
import { usePayment } from '../../hooks/usePayment';
import PaymentSuccess from '../Payments/PaymentSuccessPopUp';
import PaymentFailed from '../Payments/PaymentFailedPopUp';
interface IAppointmentDetails {
  appointmentDetails: IAppointment[];
  selectedTime: AvailableTime | undefined;
  selectedDate: Date;
}

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

const AppointmentDetails: React.FC<IAppointmentDetails> = ({
  appointmentDetails,
  selectedTime,
  selectedDate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { initiatePaymentByIds, submitForm } = useAppointments();
  const { getPaymentStatusById } = usePayment();

  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(5);

  const [successOpen, setSuccessOpen] = useState(false);
  const [failureOpen, setFailureOpen] = useState(false);

  const [transaction, setTransaction] = useState<IPaymentList | null>(null);
  const [transactionAmount, setTransactionAmount] = useState('');

  const [formState, setFormState] = useState<FormState>({
    email: '',
    phone: '',
  });

  const [page, setPage] = useState(2);

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);

  const formattedSelectedDate = format(selectedDate, 'yyyy-MM-dd');

  const convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const appointmentResponse = async (response: any) => {
    try {
      const transaction = response?.appointment as IPaymentList;
      if (appointmentDetails) {
        setTransaction(transaction);
      }

      if (response?.url) {
        const windowFeatures = `width=${Math.min(
          1000,
          window.screen.width
        )},height=${Math.min(1000, window.screen.height)},left=${
          (window.screen.width - 1000) / 2
        },top=${(window.screen.height - 1000) / 2}`;
        const windowRef = window.open(response.url, windowFeatures);

        let timeoutReached = false;
        const timeoutDuration = 120000;
        const timeout = setTimeout(() => {
          timeoutReached = true;
          clearInterval(intervalRef);
          windowRef?.close();
          // console.error('Appointment timeout reached');
        }, timeoutDuration);

        const intervalRef = setInterval(async () => {
          if (timeoutReached) return;
        }, 1000);
      } else {
        console.error('Appointment URL not provided in response');
      }
    } catch (error) {
      console.error('An error occurred in appointmentResponse:', error);
    }
  };

  const handlePayment = async (
    communityId: string,
    appointmentId: string,
    amount: number,
    availabilityId: string,
    timeSlotId: string,
    email: string,
    phone: string
  ) => {
    setIsLoading(true);
    try {
      setFormState((prevState) => ({
        ...prevState,
        email,
        phone,
      }));

      const paymentResponse = await initiatePaymentByIds(
        communityId,
        appointmentId,
        amount,
        availabilityId,
        timeSlotId
      );

      if (paymentResponse?.url) {
        const { transactionId, url } = paymentResponse;

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = Math.min(1000, screenWidth);
        const height = Math.min(1000, screenHeight);
        const left = (screenWidth - width) / 2;
        const top = (screenHeight - height) / 2;

        const windowRef = window.open(
          url,
          'paymentWindow',
          `width=${width},height=${height},left=${left},top=${top},resizable=no`
        );

        const intervalRef = setInterval(async () => {
          try {
            const paymentStatus = await getPaymentStatusById(transactionId);
            setTransactionAmount(paymentStatus[0]?.amount);

            if (paymentStatus && paymentStatus.length > 0) {
              clearInterval(intervalRef);
              windowRef?.close();

              if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
                setSuccessOpen(true);
                await appointmentResponse(paymentResponse);
                handleFormData(
                  email,
                  phone,
                  communityId,
                  appointmentId,
                  availabilityId,
                  timeSlotId
                );
                setPage(3);
              } else {
                setFailureOpen(true);
              }
            }
          } catch (statusError) {
            console.error('Error fetching payment status:', statusError);
          }
        }, 1000);
      } else {
        enqueueSnackbar('Payment URL not provided in response', {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error during payment handling:', error);
      enqueueSnackbar('Payment failed, please try again!', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessClose = () => {
    setTimer(3);
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(3);
    setFailureOpen(false);
  };

  const handleFreeBook = async (
    communityId: string,
    appointmentId: string,
    availabilityId: string,
    timeSlotId: string,
    selectedTimes: string,
    selectedDates: string,
    email: string,
    phone: string
  ) => {
    setIsLoading(true);
    try {
      setFormState((prevState) => ({
        ...prevState,
        email,
        phone,
      }));

      const response = await paymentRequestFree(
        getAccessToken(),
        communityId,
        appointmentId,
        availabilityId,
        timeSlotId
      );

      if (response?.status === 200) {
        enqueueSnackbar('Appointment booked successfully.', {
          variant: 'success',
        });

        handleFormData(
          email,
          phone,
          communityId,
          appointmentId,
          availabilityId,
          timeSlotId
        );

        const formattedDate = new Date(selectedDates);
        formattedDate.setDate(formattedDate.getDate() + 1);
        const formattedDateString = formattedDate.toISOString().split('T')[0];
        const formattedTime = convertTo12HourFormat(selectedTimes);
        const url = `/appointment-confirmation/${appointmentDetails?.[0]?.title
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '-')}/${appointmentId}?time=${encodeURIComponent(
          formattedTime
        )}&date=${encodeURIComponent(formattedDateString)}`;

        navigate(url);
        setPage(3);
      } else {
        if (response?.status === 400) {
          enqueueSnackbar(
            'Please subscribe to a plan to book an appointment.',
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('Failed Booking Appointment.', {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormData = async (
    email: string,
    phone: string,
    communityId: string,
    appointmentId: string,
    availabilityId: string,
    timeSlotId: string
  ) => {
    try {
      const response = await submitForm(
        communityId,
        appointmentId,
        email,
        phone,
        availabilityId,
        timeSlotId
      );
      if (response) {
      } else {
        console.error('Failed to submit form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      enqueueSnackbar('Form submission failed, please try again!', {
        variant: 'error',
      });
    }
  };

  const navigate = useNavigate();

  const formatDate = (selectedDate: string) => {
    const dateObj = new Date(selectedDate);

    // Get day, month, and year, and pad single digits with a leading zero
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Return formatted date as "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
  };

  const dataAvailable = formState?.email && formState?.phone?.length === 10;

  return (
    <>
      {appointmentDetails?.map((appointment) => {
        const communityId = appointment?.community;
        const appointmentId = appointment?._id || '';
        const amount = appointment?.pricing || 0;
        const availabilityId =
          appointment?.availability?.find(
            (availability) => availability?.day === formattedSelectedDate
          )?._id || '';
        const timeSlotId = selectedTime?._id || '';

        return (
          <>
            {page === 2 && (
              <>
                <Stack
                  flexGrow={1}
                  sx={{ display: { xs: 'flex', md: 'none' }, pb: 2 }}
                  flexDirection={'row'}
                  gap={1}
                  display={'flex'}
                  justifyItems={'center'}
                  alignItems={'center'}
                >
                  <ArrowBackIosNewOutlinedIcon
                    sx={{ fontSize: '18px' }}
                    onClick={() => {
                      navigate('/appointments');
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: '16px' },
                      fontWeight: 600,
                      color: '#000000',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Appointments
                  </Typography>
                  <Button
                    disabled
                    variant='contained'
                    sx={{
                      backgroundColor: '#3E3E3E',
                      borderRadius: '30px',
                      boxShadow: 'none',
                      textTransform: 'capitalize',
                      color: '#3E3E3E',
                      height: { xs: '2.5vh', md: '3vh' },
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '10px', md: '12px' },
                    }}
                  >
                    {appointment?.title}
                  </Button>
                </Stack>

                <SubNavbar>
                  <Stack
                    direction={'row'}
                    flexGrow={1}
                    alignItems={'center'}
                    ml={0}
                    sx={{ py: { xs: 0, md: 2 } }}
                  >
                    <Stack
                      direction={'row'}
                      flexGrow={1}
                      sx={{
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                      }}
                      gap={1}
                    >
                      <ArrowBackIosNewOutlinedIcon
                        sx={{ fontSize: '18px', cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/appointments');
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: { xs: '16px', md: '18px' },
                          fontWeight: 600,
                          color: '#000000',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: '15px', md: '18px' },
                            fontWeight: 600,
                            color: '#000000',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          Appointments
                        </Typography>
                      </Typography>
                      <Button
                        disabled
                        variant='contained'
                        sx={{
                          backgroundColor: '#3E3E3E',
                          borderRadius: '30px',
                          boxShadow: 'none',
                          textTransform: 'capitalize',
                          color: '#3E3E3E',
                          height: { xs: '2.5vh', md: '3vh' },
                          fontFamily: 'Montserrat',
                          fontSize: { xs: '10px', md: '12px' },
                        }}
                      >
                        {appointment?.title}
                      </Button>
                    </Stack>
                  </Stack>
                </SubNavbar>
                <Box>
                  <Paper
                    sx={{
                      p: 2,
                      mt: 1,
                      border: '1px solid #D4DDED',
                      borderRadius: '12px',
                    }}
                    elevation={0}
                  >
                    <Stack sx={{ p: { xs: 1, md: 1 }, borderRadius: '5px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2} md={2}>
                          <Box
                            component={'img'}
                            src={Logo}
                            width='100%'
                            sx={{
                              borderRadius: '10px',
                              objectFit: 'contain',
                              height: { xs: '50px', md: '100px' },
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography
                            sx={{
                              fontWeight: { xs: 500, md: 600 },
                              fontFamily: 'Montserrat',
                              fontSize: { xs: '14px', md: '16px' },
                              color: '#000000',
                            }}
                          >
                            {appointment?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: '12px', md: '13px' },
                              color: '#646464',
                              fontFamily: 'Montserrat',
                              fontWeight: { xs: 400, md: 500 },
                            }}
                          >
                            {appointment?.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>

                    <Grid
                      container
                      spacing={3}
                      p={3}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid item>
                        <Stack spacing={2}>
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <CalendarTodayOutlinedIcon
                              sx={{ fontSize: '22px', color: '#0000008a' }}
                            />
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', md: '14px' },
                                fontWeight: { xs: 500, md: 500 },
                                color: '#000000',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {selectedDate?.toLocaleDateString()} |{' '}
                              {convertTo12HourFormat(
                                selectedTime?.startTime ?? ''
                              )}{' '}
                              -{' '}
                              {convertTo12HourFormat(
                                selectedTime?.endTime ?? ''
                              )}
                            </Typography>
                          </Stack>
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <LocalOfferOutlinedIcon
                              sx={{ fontSize: '22px', color: '#0000008a' }}
                            />
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', md: '14px' },
                                fontWeight: { xs: 500, md: 500 },
                                color: '#000000',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {appointment?.pricing
                                ? ` ₹${appointment?.pricing}`
                                : 'Free'}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={2}>
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <PinDropOutlinedIcon
                              sx={{ fontSize: '22px', color: '#0000008a' }}
                            />
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', md: '14px' },
                                fontWeight: { xs: 500, md: 500 },
                                color: '#000000',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {appointment?.location}
                            </Typography>
                          </Stack>
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <PersonOutlineOutlinedIcon
                              sx={{ fontSize: '22px', color: '#0000008a' }}
                            />
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: { xs: 500, md: 500 },
                                color: '#000000',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {appointment?.createdBy?.firstName}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>

                    <Divider />

                    <Stack sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant='h5'
                            sx={{
                              fontSize: '14px',
                              color: '#000000',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Email
                          </Typography>
                          <TextField
                            fullWidth
                            name='email'
                            label='Email'
                            size='small'
                            value={formState.email}
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                email: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              style: {
                                fontSize: '13px',
                                color: '#646464',
                                fontFamily: 'Montserrat',
                              },
                            }}
                            sx={{
                              mt: 1,
                              mb: 1,
                              fontSize: '13px',
                              backgroundColor: '#ffffff',
                              fontFamily: 'Montserrat',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant='h5'
                            sx={{
                              fontSize: '14px',
                              color: '#000000',
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Phone number
                          </Typography>
                          <TextField
                            fullWidth
                            name='phone'
                            label='Phone'
                            size='small'
                            value={formState.phone}
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                phone: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              style: {
                                fontSize: '13px',
                                color: '#646464',
                                fontFamily: 'Montserrat',
                              },
                            }}
                            sx={{
                              mt: 1,
                              mb: 1,
                              fontSize: '13px',
                              backgroundColor: '#ffffff',
                              fontFamily: 'Montserrat',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>

                    <Box
                      sx={{ mt: 2 }}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      {appointment?.isPaidService ? (
                        <>
                          <Button
                            disabled={!dataAvailable}
                            endIcon={<LockOutlinedIcon />}
                            variant='contained'
                            sx={{
                              background:
                                'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                              textTransform: 'capitalize',
                              borderRadius: '5px',
                              boxShadow: 'none',
                              fontFamily: 'Montserrat',

                              '&:hover': {
                                background:
                                  'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                color: 'white',
                              },
                            }}
                            onClick={() =>
                              handlePayment(
                                communityId,
                                appointmentId,
                                amount,
                                availabilityId,
                                timeSlotId,
                                formState.email,
                                formState.phone
                              )
                            }
                          >
                            {isLoading ? (
                              <CircularProgress
                                size={20}
                                sx={{ color: 'white' }}
                              />
                            ) : (
                              ' Continue to Payment'
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          disabled={!dataAvailable}
                          variant='contained'
                          sx={{
                            background:
                              'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                            textTransform: 'capitalize',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontFamily: 'Montserrat',

                            '&:hover': {
                              background:
                                'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                              color: 'white',
                            },
                          }}
                          onClick={() =>
                            handleFreeBook(
                              communityId,
                              appointmentId,
                              availabilityId,
                              timeSlotId,
                              selectedTime?.startTime || '',
                              selectedDate.toISOString().split('T')[0],
                              formState.email,
                              formState.phone
                            )
                          }
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: 'white' }}
                            />
                          ) : (
                            ' Book Appointment'
                          )}
                        </Button>
                      )}
                    </Box>
                  </Paper>
                </Box>
              </>
            )}

            {page === 3 && (
              <Confirmation
                transaction={transaction}
                appointmentDetails={appointmentDetails}
                selectedTime={selectedTime}
                selectedDate={selectedDate}
              />
            )}

            <PaymentSuccess
              txnid={transaction?.txnid || ''}
              open={successOpen}
              amount={transactionAmount || ''}
              timer={timer}
              dialogText={''}
              onClose={handleSuccessClose}
              onClickNavigateButton={() => {
                navigate('/home');
                setSuccessOpen(false);
              }}
            />
            <PaymentFailed
              open={failureOpen}
              onClose={handleFailureClose}
              amount={transactionAmount || ''}
              txnid={transaction?.txnid || ''}
              onClickNavigateButton={() => {
                navigate('#');
                setFailureOpen(false);
              }}
              dialogText={''}
              timer={timer}
              OnClickRetryButtonFunction={() => {
                handlePayment(
                  communityId,
                  appointmentId,
                  amount,
                  availabilityId,
                  timeSlotId,
                  formState.email,
                  formState.phone
                );
                setFailureOpen(false);
              }}
            />
          </>
        );
      })}
    </>
  );
};

export default AppointmentDetails;
