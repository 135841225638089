import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react";
import { loadSelectedCommunity } from "../../../../store/slices/CommunitySlice";
import { BASE_URL } from "../../../../configurations/url.config";
import axios from "axios";
import { ICommunity } from "../../../../models/communities.modal";


const LiveShareFooter = () => {


    const [data, setData] = useState<ICommunity>();
    const [loading, setLoading] = useState(true); // Add loading state

    const community_id = ""

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/communities/${community_id}/home`
                );
                // console.log(response?.data?.community);
                setData(response.data?.community);
                // console.log(response?.data?.community);

                setLoading(false);
            } catch (error) {
                console.log("error");
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);



    const openSocialMediaProfile = (type: string) => {
        const socialLink = data?.socialLinks?.find((link) => link.type === type);

        if (socialLink) {
            window.open(socialLink.value, "_blank");
        }
    };

    const handleFacebookClick = () => {
        openSocialMediaProfile("facebook");
    };
    const handleYoutubeClick = () => {
        openSocialMediaProfile("youtube");
    };

    const handleTwitterClick = () => {
        openSocialMediaProfile("twitter");
    };

    const handleLinkedInClick = () => {
        openSocialMediaProfile("linkedin");
    };

    const handleInstagramClick = () => {
        openSocialMediaProfile("instagram");
    };

    const navigate = useNavigate();

    const handleFacebookClickCommunn = () => {
        navigate("/");
    };
    const handleYoutubeClickCommunn = () => {
        navigate("/");
    };

    const handleTwitterClickCommunn = () => {
        navigate("/");
    };

    const handleLinkedInClickCommunn = () => {
        navigate("https://www.linkedin.com/company/one-communn/");
    };

    const handleInstagramClickCommunn = () => {
        navigate("https://www.instagram.com/onecommunn_official/");
    };

    const socialMediaIcons = [
        { title: "Facebook", icon: FacebookIcon, onClick: handleFacebookClick },
        { title: "Youtube", icon: YouTubeIcon, onClick: handleYoutubeClick },
        { title: "Twitter", icon: TwitterIcon, onClick: handleTwitterClick },
        { title: "Linkedin", icon: LinkedInIcon, onClick: handleLinkedInClick },
        { title: "Instagram", icon: InstagramIcon, onClick: handleInstagramClick },
    ];
    const socialCommunnMediaIcons = [
        {
            title: "Facebook",
            icon: FacebookIcon,
            onClick: handleFacebookClickCommunn,
        },
        { title: "Youtube", icon: YouTubeIcon, onClick: handleYoutubeClickCommunn },
        { title: "Twitter", icon: TwitterIcon, onClick: handleTwitterClickCommunn },
        {
            title: "Linkedin",
            icon: LinkedInIcon,
            onClick: handleLinkedInClickCommunn,
        },
        {
            title: "Instagram",
            icon: InstagramIcon,
            onClick: handleInstagramClickCommunn,
        },
    ];
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#F2F2F2",
                    pt: 1,
                    pl: 10,
                    pr: 10,
                    pb: 2,
                    mt: 5,
                }}
            >

                <Stack
                    direction={"row"}
                    spacing={{ xs: 2, sm: 2, md: 5, lg: 5 }}
                    alignContent={"center"}
                    justifyContent={"space-between"}
                    pt={1}
                    mt={1}
                >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                            <Typography
                                sx={{
                                    color: "#000000",
                                    fontSize: { xs: "14px", md: "16px" },
                                    textDecoration: "none",
                                    fontWeight: 700,
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                }}
                            >
                                Built With
                            </Typography>
                            <Box
                                component={"img"}
                                src={require("../../../../assets/images/communnlanding.png")}
                                width={"100%"}
                                sx={{
                                    py: 1,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Typography
                                sx={{
                                    color: "#000000",
                                    fontSize: { xs: "14px", md: "16px" },
                                    textDecoration: "none",
                                    fontWeight: 700,
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                }}
                            >
                                Follow us
                            </Typography>
                            <Stack direction={"row"} py={1} sx={{ justifyContent: "center" }}>
                                {socialCommunnMediaIcons.map((icon, index) => (
                                    <Tooltip key={index} title={icon.title}>
                                        <IconButton onClick={icon.onClick}>
                                            {React.createElement(icon.icon, {
                                                sx: {
                                                    color: "#464646",
                                                    fontSize: { xs: "20px", md: "25px" },
                                                },
                                            })}
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                {/* <Divider></Divider>
                <Stack>
                    <Typography
                        sx={{
                            color: "#000000",
                            fontSize: { xs: "10px", md: "14px" },
                            textDecoration: "none",
                            fontFamily: "Montserrat",
                            textAlign: "center",
                            pt: 2,
                        }}
                    >
                        © 2024 All Rights Reserved By
                    </Typography>
                </Stack> */}
            </Box>
        </>
    )
}
export default LiveShareFooter;