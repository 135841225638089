import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from "@mui/material"
import BreadCrumbs from "../AlternatePages/BreadCrumbs"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import { useLiveClasses } from "../../hooks/useLiveClassess";
import { ILiveClass } from "../../models/liveClasses.model";
import Happiness from "../../assets/images/Happiness.svg"
import Loader from "../Loader/Loader";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";


const ClassDetails = () => {


    const { id } = useParams();

    // console.log(id, "id")

    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getClassById } = useLiveClasses();


    const [classDetails, setClassDetails] = React.useState<ILiveClass[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // console.log(classDetails, "classDetails")

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getClassById(id || "");
            if (Array.isArray(response)) {
                setClassDetails(response);
            } else {
                setClassDetails([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setClassDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClassDetails();
    }, []);


    const defaultImages = 'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png'

    const navigate = useNavigate()


    return (
        <>
            {isLoading && <Loader />
            }
            {classDetails?.map((classDetail, index) => {
                return (
                    <>
                        <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' } }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                            <ArrowBackIosNewOutlined sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
                                navigate('/meetings');
                            }} />
                            <Typography
                                sx={{
                                    fontSize: { xs: '16px', md: '18px' },
                                    fontWeight: 600,
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                }}
                            >
                                {classDetail?.name}
                            </Typography>
                        </Stack >
                        <Box>
                            <Paper sx={{ p: 2, mt: 2, borderRadius: '10px' }} elevation={0}>
                                <Stack sx={{ backgroundColor: '#F0F9FF', p: { xs: 1, md: 5 }, borderRadius: '5px' }}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontFamily: 'Montserrat',
                                                    fontSize: { xs: '20px', md: '25px' },
                                                    color: '#50A1CA'
                                                }}>
                                                {classDetail?.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    color: '#5E6C84',
                                                    fontFamily: 'Montserrat'
                                                }}>
                                                {classDetail?.description}
                                            </Typography>
                                            <Button
                                                startIcon={<VideoCameraFrontOutlinedIcon />}
                                                size="medium"
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: '5px',
                                                    fontFamily: 'Montserrat',
                                                    textTransform: 'capitalize',
                                                    color: '#50A1CA',
                                                    border: '1px solid #50A1CA',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    mt: 2,
                                                    "&:hover": {
                                                        border: '1px solid #50A1CA',
                                                    }
                                                }}
                                                component="a"
                                                href={classDetail?.googleMeetLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Join
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                component={'img'}
                                                src={classDetail?.coverImage?.value
                                                    ? `${classDetail.coverImage.value}`
                                                    : `${defaultImages}`}
                                                width="100%"
                                                height={200}
                                                sx={{ borderRadius: '10px', objectFit: 'cover' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                                <Paper
                                    sx={{
                                        mt: 5, border: '1px solid #E9E9E9',
                                        borderRadius: '10px',
                                        display: { xs: 'block', md: 'none' }
                                    }} elevation={0}>
                                    <Stack>
                                        <List>
                                            {[
                                                {
                                                    icon: <CalendarMonthOutlinedIcon />,
                                                    text: `${new Date(classDetail?.startDate).toLocaleString('en-GB', {
                                                        timeZone: 'Asia/Kolkata',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: '2-digit',
                                                        hour12: false,
                                                    })} | ${classDetail?.startTime} - ${classDetail?.endTime}`
                                                },
                                                {
                                                    icon: classDetail?.coverNote?.value ? (
                                                        <a
                                                            href={classDetail?.coverNote?.value}
                                                            download
                                                            style={{ textDecoration: 'none', color: '#575757', flexWrap: 'wrap' }}
                                                        >
                                                            <Tooltip title="Download Document" placement="left-start">
                                                                <UploadFileOutlinedIcon
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </a>
                                                    ) : (
                                                        <UploadFileOutlinedIcon />
                                                    ),
                                                    text: classDetail?.coverNote?.value + "Download Document" || 'No document available',
                                                },
                                                {
                                                    icon: <UploadFileOutlinedIcon />,
                                                    text: classDetail?.googleMeetLink,
                                                },
                                            ].map((item, index) => (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemButton>
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        color: '#565656',
                                                                        fontFamily: 'Montserrat',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    {item.text}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>

                                        <List sx={{ backgroundColor: '#F2F2F2', borderRadius: '0px 0px 10px 10px ' }}>
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton
                                                    sx={{
                                                        backgroundColor: '#F2F2F2',
                                                    }}>
                                                    <ListItemIcon>
                                                        <PhotoCameraFrontOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#565656',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                By -  {classDetail?.instructorName}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Stack>

                                </Paper>

                                <Paper sx={{ mt: 5, border: '1px solid #E9E9E9', borderRadius: '10px', display: { xs: 'none', md: 'block' } }} elevation={0}>
                                    <Stack display={"flex"} flexDirection={'row'} gap={5} alignItems={'center'} p={3} justifyContent={'space-between'}>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            <CalendarMonthOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#565656',
                                                fontFamily: 'Montserrat',
                                            }}>
                                                {
                                                    `${new Date(classDetail?.startDate).toLocaleString('en-GB', {
                                                        timeZone: 'Asia/Kolkata',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: '2-digit',
                                                        hour12: false,
                                                    })} | ${classDetail?.startTime} - ${classDetail?.endTime}`

                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            {classDetail?.coverNote ? (
                                                <a href={classDetail?.coverNote?.value} download style={{ color: 'inherit' }}>
                                                    <Tooltip title="Download Document" placement="left-start">
                                                        <UploadFileOutlinedIcon
                                                            sx={{
                                                                cursor: 'pointer',
                                                                color: 'inherit'
                                                            }}
                                                        />

                                                    </Tooltip>


                                                </a>
                                            ) : (
                                                <>
                                                    <UploadFileOutlinedIcon
                                                        sx={{
                                                            cursor: 'pointer',
                                                            color: '#565656'
                                                        }}
                                                    />
                                                    <Typography sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        color: '#565656',
                                                        fontFamily: 'Montserrat',
                                                    }}>

                                                        No document available
                                                    </Typography>
                                                </>
                                            )}
                                        </Stack>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            <VideoCameraFrontOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#565656',
                                                fontFamily: 'Montserrat',
                                            }}>
                                                {classDetail?.googleMeetLink}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        <List sx={{ backgroundColor: '#F2F2F2', borderRadius: '0px 0px 10px 10px ' }}>
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton
                                                    sx={{
                                                        backgroundColor: '#F2F2F2',
                                                    }}>
                                                    <ListItemIcon>
                                                        <PhotoCameraFrontOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#565656',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                By -  {classDetail?.instructorName}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Paper>
                                <Stack display={"flex"} flexDirection={'row'} gap={5} alignItems={'center'} sx={{ p: 2 }}>
                                    <Stack>
                                        <Box component={'img'}
                                            src={Happiness}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography sx={{ color: '#3760A9', fontSize: { xs: '20px', md: '30px' }, fontFamily: 'Montserrat', fontWeight: 500 }}>
                                            You joined the meeting!
                                        </Typography>
                                        <Typography sx={{ color: '#565656', fontSize: { xs: '12px', md: '15px' }, fontFamily: 'Montserrat' }} >
                                            Click on the play button to start the meeting
                                        </Typography>

                                    </Stack>
                                </Stack>
                            </Paper>

                        </Box >

                    </>
                )
            })}

        </>
    )
}
export default ClassDetails