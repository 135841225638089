import React, { useState } from "react";

import {
  Grid,
  Typography,
  Divider,
  Stack,
  Button,
  Popover,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { interfaceCourse } from "../../models/courses.model";
import { useCourses } from "../../hooks/useCourses";

interface CourseInfoCardProps {
  data: interfaceCourse[];
  refetchCourseData: () => void
}

const CourseInfoCard: React.FC<CourseInfoCardProps> = ({ data, refetchCourseData }) => {
  const courseData = data[0]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)

  const { deleteCourseByIds } = useCourses();
  const [deleteCourseAnchor, setDeleteCourseAnchor] = useState<null | HTMLElement>(null);
  const openDeleteCoursePopOver = Boolean(deleteCourseAnchor);
  const idDeleteCourse = openDeleteCoursePopOver
    ? "delete-popover"
    : undefined;
  const navigate = useNavigate();







  const handleCloseDelete = () => {
    setDeleteCourseAnchor(null)
  }

  const handleCourseDelete = async () => {
    setLoading(true)
    await deleteCourseByIds(courseData?.community?._id, courseData?._id);
    navigate("/courses");
    setLoading(false);
    refetchCourseData()
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box
            component={"img"}
            src={courseData?.coverImage ? courseData?.coverImage.value : require("./../../assets/images/courseIcon@2x.png")}
            sx={{
              height: "180px",
              width: { xs: "85%", md: "100%", objectFit: 'cover' },
              display: { borderRadius: '10px' },
            }}
            alt=""
          ></Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography

            sx={{
              fontFamily: 'Montserrat',
              fontWeight: "bold",
              fontSize: { xs: "13px", md: "16px" },
              // lineHeight: "1.5",
              marginBottom: "2px",
            }}
          >
            {courseData?.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: { xs: "12px", md: "13px" },
              marginBottom: "2px",
              pr: { xs: 1, md: 0 },
              width: { xs: "85%", md: "92%" },
              color: "#575757",
              textAlign: 'justify'
            }}
          >
            {courseData?.description}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              fontSize: { xs: "12px", md: "14px" },
              lineHeight: "1.5",
              marginBottom: "5px",
              color: '#50A1CA'

            }}
          >
            {courseData?.pricing === "FREE" ? "Free" : `₹ ${courseData?.plan?.offerValue}`}
            {/* ₹ {courseData?.plan.offerValue ? courseData?.plan.offerValue : courseData?.pricing === "FREE"  } */}
          </Typography>
        </Grid>
        <Divider sx={{ mx: 2 }} />
      </Grid>




      <Popover
        id={idDeleteCourse}
        open={openDeleteCoursePopOver}
        anchorEl={deleteCourseAnchor}
        onClose={() => handleCloseDelete()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "Montserrat",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Sectifron?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseDelete}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleCourseDelete}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {/* {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )} */}
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>

    </Stack>
  );
};

export default CourseInfoCard;
