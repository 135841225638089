import { Button } from "@mui/material"

interface IButton {
    url?: string;
    name: string;
    icon?: React.ReactNode;
    onClick?: () => void;

}

const OutlineButton: React.FC<IButton> = ({ url, name, onClick, icon }) => {
    return (
        <>
            <Button variant="outlined"
                sx={{
                    border: '1px solid #50A1CA',
                    fontSize: { xs: "10px", md: "15px" },
                    color: '#50A1CA',
                    textTransform: "capitalize",
                    fontWeight: 400,
                    fontFamily: 'Montserrat'
                }}
                onClick={onClick}
                startIcon={icon}
                href={onClick ? undefined : url}
            >
                {name}
            </Button>
        </>
    )
}

export default OutlineButton