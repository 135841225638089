import {
    Box,
    Grid,
    Stack,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { useEffect, useState } from 'react';
import { JoinedAllCommunities } from '../AllCommunities/JoinedAllCommunities';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import NoInvites from "./../../../assets/icons/svg-icons/NoInvitation.png"
import { RotatingLines } from "react-loader-spinner"
import { ExploreAlternative } from '../../AlternatePages/ExploreAlternative';


interface ITableProps {
    searchQuery: string;
}

const JoinedCommunitiesNew = ({ searchQuery }: ITableProps) => {
    const { isLoading, memberCommunities } = useCommunity();
    const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);

    // console.log(myMemberCommunities, "myMemberCommunities");

    useEffect(() => {
        async function fetchData() {
            const responseData = await memberCommunities();
            // console.log(responseData);
            setMyMemberCommunities(responseData?.data);
        }
        fetchData();
    }, []);


    const filteredCommunities = myMemberCommunities?.filter((community) =>
        community?.community?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // console.log(filteredCommunities, "filteredCommunities")



    return (
        <>
            <Helmet>
                <title>{metaConfig.title.joinedCommunities}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <Stack>
                {isLoading ? (
                    <Stack
                        sx={{
                            color: 'grey.500',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40vh',
                        }}
                        spacing={2}
                        direction="row"
                    >
                        <RotatingLines
                            visible={true}
                            width="30"
                            strokeWidth="5"
                            strokeColor="#777777"
                            ariaLabel="rotating-lines-loading"
                        />
                    </Stack>
                ) : (
                    <Grid container spacing={0}>
                        {filteredCommunities.map((item) => (
                            <Grid item key={item._id} xs={6} sm={2} md={1.5} lg={1.5} xl={1.5}>
                                <JoinedAllCommunities {...item?.community} />
                            </Grid>
                        ))}
                    </Grid>
                )}
                {!isLoading && filteredCommunities?.length === 0 && (
                    <Box sx={{ textAlign: 'center', }}>
                        <ExploreAlternative title='No Joined Communities ' image={NoInvites} />
                    </Box>
                )}
            </Stack>


        </>
    );
};

export default JoinedCommunitiesNew;
