import React, { useState } from "react";
import {
    Card,
    CardMedia,
    Button,
    Stack,
    Dialog,
    Grid,

    Box,
    DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface ImageGalleryProps {
    images: string[];
    imageHeight: number;
    imageWidth: number;
}

const AboutGallery: React.FC<ImageGalleryProps> = ({
    images,

}) => {
    const [showCarousel, setShowCarousel] = useState(false);


    const openCarousel = () => {
        setShowCarousel(true);
    };

    const closeCarousel = () => {
        setShowCarousel(false);
    };

    return (
        <Stack
            sx={{
                gap: 1,
                // paddingLeft: "20px",
                // paddingRight: "20px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {images.length > 0 && (
                <Grid container spacing={{ xs: 2, md: 1 }}>
                    {images.slice(0, 1).map((image, index) => (
                        <Grid key={index} item xs={images.length === 1 ? 6 : 6} md={images.length === 1 ? 6 : 6}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: { xs: 150, md: 300 },
                                    maxHeight: { xs: 150, md: 300 },
                                    width: '100%',
                                    marginBottom: "2px",
                                    borderRadius: '10px',
                                }}
                                onClick={openCarousel}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{
                                        height: { xs: 150, md: 300 },
                                        maxHeight: { xs: 150, md: 300 },
                                        width: '100%',
                                        objectFit: 'cover',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        marginBottom: '16px',
                                    }}
                                    image={image}
                                />

                            </Card>
                        </Grid>
                    ))}
                    {images.length > 1 && (
                        <Grid item xs={6}>
                            <Card
                                elevation={0}
                                sx={{
                                    height: { xs: 150, md: 300 },
                                    maxHeight: { xs: 150, md: 300 },
                                    width: '100%',
                                    marginBottom: "2px",
                                    borderRadius: '5px',
                                }}
                                onClick={openCarousel}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{
                                        height: { xs: 150, md: 300 },
                                        maxHeight: { xs: 150, md: 300 },
                                        width: '100%',
                                        objectFit: 'cover',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        marginBottom: '16px',
                                    }}
                                    image={images[1]}
                                />
                                {images.length > 2 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                            mt: -12,
                                            ml: 1.5,
                                            left: { xs: '125px', md: "91%" },
                                            textTransform: "capitalize",
                                            transform: "translateX(-50%)",
                                            borderRadius: '5px',
                                            "&:hover": {
                                                backgroundColor: "#4BA1CB",
                                            },
                                        }}
                                        onClick={openCarousel}
                                    >
                                        + {images.length - 2}
                                    </Button>
                                )}

                            </Card>
                        </Grid>
                    )}

                </Grid>
            )}

            <Dialog open={showCarousel} onClose={closeCarousel} maxWidth="sm">
                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ backgroundColor: '#F0F9FF' }}
                    >
                        <Stack></Stack>
                        <Stack>

                        </Stack>
                        <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                            <Box
                                onClick={closeCarousel}
                                sx={{
                                    backgroundColor: "#50A1CA",
                                    padding: "4px",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    fontSize: "10px",
                                    mt: "0.5px",
                                    mr: "10px",
                                    cursor: "pointer",
                                    // "&:hover": {
                                    //   backgroundColor: "#50A1CA", // Background color on hover
                                    //   cursor: "pointer",
                                    //   border: "none",
                                    // },
                                }}
                            >
                                <Close sx={{ fontSize: "15px" }} />
                            </Box>
                        </Stack>
                    </Stack>
                </DialogActions>
                <Carousel
                    showThumbs={false}
                    infiniteLoop={false}
                    width={"fit-content"}
                    showStatus={false}
                    showArrows={true}
                >
                    {images.map((image, index) => (
                        <Box key={index}>
                            <img
                                src={image}
                                alt=""
                                style={{

                                    width: "100%", // Set a fixed width for all images
                                    objectFit: "contain", // This ensures that the image covers the entire box without stretching
                                    cursor: "pointer",
                                }}
                            />
                        </Box>
                    ))}
                </Carousel>
            </Dialog>
        </Stack>
    );
};

export default AboutGallery;
