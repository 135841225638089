import {
    Box,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import React, { } from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Lottie from 'react-lottie';
import { IPaymentList } from '../../../../models/payment.model';
import { AvailableTime, IAppointment } from '../../../../models/appointments.models';
import paymentSuccess from '../../../../assets/lotties/payment-success.json';

interface IAppointmentDetails {
    transaction?: IPaymentList | null;
    appointmentDetails?: IAppointment[];
    selectedTime?: AvailableTime | undefined;
    selectedDate?: Date;
}

const ShareAvailabilityConfirmation: React.FC<IAppointmentDetails> = ({
    appointmentDetails,
    selectedTime,
    selectedDate,
}) => {


    // console.log('Appointment Details:', appointmentDetails);
    // console.log('Selected Time:', selectedTime);
    // console.log('Selected Date:', selectedDate);


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: paymentSuccess,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const convertTo12HourFormat = (time24: string): string => {
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    return (
        <>
            {appointmentDetails?.map((appointment, index) => {
                return (
                    <>
                        <Box
                            sx={{
                                mt: 1,
                                borderRadius: '12px',
                            }}
                        >
                            <Stack sx={{ borderRadius: '5px' }}>
                                <Grid container sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, mt: 0 }} >
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '12px',
                                            // border: '1px solid #D4DDED',
                                            p: { xs: 0, md: 2 },
                                        }}
                                    >

                                        <Paper
                                            sx={{
                                                borderRadius: '10px',
                                                display: { xs: 'block', md: 'block' },
                                            }}
                                            elevation={0}
                                        >
                                            <Stack
                                                direction='row'
                                                display='flex'
                                                justifyContent='space-between'
                                                alignItems='center'
                                                sx={{}}
                                            >

                                                <Lottie
                                                    options={defaultOptions}
                                                    height={100}
                                                    width={100}
                                                />
                                            </Stack>
                                            <Stack
                                                direction='row'
                                                display='flex'
                                                flexDirection={'column'}
                                                justifyContent='center'
                                                alignItems='center'
                                                sx={{ py: 1 }}
                                            >

                                                <Typography
                                                    sx={{
                                                        fontSize: '16px',
                                                        color: '#000000',
                                                        fontFamily: 'Montserrat',
                                                        fontWeight: 600,
                                                        textAlign: 'center'
                                                    }}>
                                                    Appointment Booked!
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        color: '#000000',
                                                        fontFamily: 'Montserrat',
                                                        fontWeight: 500,
                                                        textAlign: 'center'
                                                    }}>
                                                    A confirmation email has been sent to your registered mail
                                                </Typography>
                                            </Stack>
                                            <Divider />
                                            <Grid container spacing={2} p={3} direction="column">
                                                <Grid item>
                                                    <Stack spacing={2}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#000000',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                {selectedDate?.toDateString()} |{' '}
                                                                {convertTo12HourFormat(selectedTime?.startTime ?? '')} -{' '}
                                                                {convertTo12HourFormat(selectedTime?.endTime ?? '')}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <LocalOfferOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#000000',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                {appointment?.pricing ? ` ₹${appointment?.pricing}` : 'Free'}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Stack spacing={2}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <PinDropOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#000000',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                {appointment?.location}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Person2OutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#000000',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                {appointment?.createdBy?.firstName}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </>
                );
            })}
        </>
    );
};
export default ShareAvailabilityConfirmation;
