import { useContext } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  cancelAppointment,
  fetchAllAppointmentBookings,
  fetchAllAppointments,
  fetchAllPastAppointments,
  fetchAppointmentById,
  fetchNoAppointmentById,
  initiatePayment,
  initiatePaymentNoAuth,
  paymentRequestFree,
  paymentRequestFreeNoAuth,
  submitUserForm,
} from '../services/appointment.service';
import { useSnackbar } from 'notistack';

export const useAppointments = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const getAllAppointments = async (id: string) => {
    try {
      const response = await fetchAllAppointments(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };

  const submitForm = async (
    communityId: string,
    appointmentId: string,
    email: string,
    phoneNumber: string,
    availabilityId: string,
    timeSlotId: string
  ) => {
    try {
      // console.log('communityId111', communityId);
      // console.log('appointmentId', appointmentId);
      // console.log('email', email);
      // console.log('phoneNumber', phoneNumber);

      const response = await submitUserForm(
        getAccessToken(),
        communityId,
        appointmentId,
        email,
        phoneNumber,
        availabilityId,
        timeSlotId
      );

      if (response?.status === 201) {
        // enqueueSnackbar('Form submitted successfully.', {
        //   variant: 'success',
        // });
        return response?.data;
      } else {
        enqueueSnackbar('Failed to submit the form.', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during form submission.', {
        variant: 'error',
      });
    }
  };

  const getAllPastAppointments = async (id: string) => {
    try {
      const response = await fetchAllPastAppointments(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };

  const getAppointmentById = async (appointmentId: string) => {
    try {
      const response = await fetchAppointmentById(
        getAccessToken(),
        appointmentId
      );
      // console.log(response, 'response');
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
      console.log(error, 'error');
    }
  };


  const getNoAuthAppointmentById = async (appointmentId: string) => {
    try {
      const response = await fetchNoAppointmentById(
        appointmentId
      );
      // console.log(response, 'response');
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {
      console.log(error, 'error');
    }
  };



  const getAllBookings = async (id: string) => {
    try {
      const response = await fetchAllAppointmentBookings(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };

  const cancelAppointmentById = async (
    appointmentId: string,
    communityId: string,
    availabilityId: string,
    timeSlotId: string
  ) => {
    try {
      const response = await cancelAppointment(
        getAccessToken(),
        appointmentId,
        communityId,
        availabilityId,
        timeSlotId
      );
      if (response?.status === 200) {
        enqueueSnackbar('Appointment cancelled successfully.', {
          variant: 'success',
        });
        // navigate('/appointments');
      } else {
        enqueueSnackbar("Couldn't updated appointment", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return { class: response };
    } catch (error) {
      enqueueSnackbar("Couldn't updated appointment", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const initiatePaymentByIds = async (
    communityId: string,
    appointmentId: string,
    amount: number,
    availabilityId: string,
    timeSlotId: string
  ) => {
    try {
      const response = await initiatePayment(
        getAccessToken(),
        communityId,
        appointmentId,
        amount,
        availabilityId,
        timeSlotId
      );

      if (response?.status === 200) {
        return response?.data;
      } else {
        enqueueSnackbar('Payment initiation failed or No bank account found', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation', {
        variant: 'error',
      });
    }
  };

  const freeAppointmentBooking = async (
    communityId: string,
    appointmentId: string,
    availabilityId: string,
    timeSlotId: string
  ) => {
    try {
      const response = await paymentRequestFree(
        getAccessToken(),
        communityId,
        appointmentId,
        availabilityId,
        timeSlotId
      );

      if (response?.status === 200) {
        enqueueSnackbar('Appointment booked successfully.', {
          variant: 'success',
        });
        return response?.data;
      } else {
        enqueueSnackbar('Failed Booking Appointment', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation', {
        variant: 'error',
      });
    }
  };




  const NoAuthFreeAppointmentBooking = async (
    appointmentId: string,
    availabilityId: string,
    timeSlotId: string,
    name:string,
    email:string,
    phoneNumber:string,
    communityId: string,
  ) => {
    try {
      const response = await paymentRequestFreeNoAuth(
        appointmentId,
        availabilityId,
        timeSlotId,
        name,
        email,
        phoneNumber,
        communityId,

      );

      if (response?.status === 200) {
        enqueueSnackbar('Appointment booked successfully.', {
          variant: 'success',
        });
        return response?.data;
      } else {
        enqueueSnackbar('Failed Booking Appointment', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation', {
        variant: 'error',
      });
    }
  };


  const NoAuthInitiatePaymentByIds = async (
    amount: number,
    appointmentId: string,
    availabilityId: string,
    timeSlotId: string,
    name:string,
    email:string,
    phoneNumber:string,
    communityId: string,
  ) => {
    try {
      const response = await initiatePaymentNoAuth(
        amount,
        appointmentId,
        availabilityId,
        timeSlotId,
        name,
        email,
        phoneNumber,
        communityId,
      );

      if (response?.status === 200) {
        return response?.data;
      } else {
        enqueueSnackbar('Payment initiation failed or No bank account found', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation', {
        variant: 'error',
      });
    }
  };



  return {
    getAllAppointments,
    getAppointmentById,
    getAllPastAppointments,
    getAllBookings,
    initiatePaymentByIds,
    freeAppointmentBooking,
    cancelAppointmentById,
    submitForm,
    getNoAuthAppointmentById,
    NoAuthFreeAppointmentBooking,
    NoAuthInitiatePaymentByIds
  };
};
