import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import './Bookings.css';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Close, FiberManualRecord } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import GooglesIcon from '../../../assets/icons/svg-icons/googlemeet.svg';
import { useAppointments } from '../../../hooks/useAppointments';
import { useSelector } from 'react-redux';
import { IEvent } from '../../../models/appointments.models';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { useNavigate } from 'react-router-dom';
import { IPaymentList } from '../../../models/payment.model';
import { usePayment } from '../../../hooks/usePayment';
import CustomPayModel from '../../Payments/CustomPayPopUp';
import PaymentSuccess from '../../Payments/PaymentSuccessPopUp';
import PaymentFailed from '../../Payments/PaymentFailedPopUp';

const Bookings = () => {
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;

  const _id = singleCommunity?._id;

  const { getPaymentStatusById, CustomPay } = usePayment();
  const { getAllBookings, cancelAppointmentById } = useAppointments();
  const [appointments, setAppointments] = React.useState<IEvent[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [transaction, setTransaction] = useState<IPaymentList>();
  const [customloading, setCustomLoading] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredPaymentsList, setFilteredPaymentsList] = useState<
    IPaymentList[]
  >([]);
  const [reson, setReson] = React.useState('');
  const [amount, setAmount] = React.useState<any>('');
  const navigate = useNavigate();
  // UseEffect for  Success and Failed Model Open
  const [timer, setTimer] = useState(5);

  const [loader, setLoader] = useState(false);

  //////PaymentPopup state and Functions/////
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [customPay, setCustomPay] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  //const [fullWidth, setFullWidth] = React.useState(true);

  const [readonly, setReadOnly] = useState(false);

  const appointmentId = selectedEvent?.extendedProps?._id;
  const availabilityId = selectedEvent?.extendedProps?.availability[0]?._id;
  const timeSlotId = selectedEvent?.extendedProps?.availability[0]?.availableTimes[0]?._id;

  // console.log(appointmentId, "appointmentId");
  // console.log(availabilityId, "availabilityId");
  // console.log(timeSlotId, "timeSlotId");
  // console.log(selectedEvent, "selectedEvent");

  const [currentDate] = useState(() => {
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, '0');
    const day = String(today.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  });

  const fetchAppointments = async () => {
    setIsLoading(true);
    try {
      const response = await getAllBookings(community_id);
      if (Array.isArray(response)) {
        setAppointments(response);
      } else {
        setAppointments([]);
      }
    } catch (err) {
      console.error('Error fetching live classes:', err);
      setAppointments([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const handleOpen = (event: any) => {
    setSelectedEvent(event);
    setOpen(true);
  };
  const handleCloses = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  const cancelAppointment = async () => {
    try {
      setLoader(true);
      const response = await cancelAppointmentById(
        appointmentId || '',
        community_id,
        availabilityId || '',
        timeSlotId || ''
      );
      handleCloses();
      fetchAppointments();
      return response;
    } catch (err) {
      console.log(err);
    } finally {
      handleCloses();
      setLoader(false);
    }
  };

  const convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'pm' : 'am';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  enum PaymentStatus {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    USERCANCELLED = 'USERCANCELLED',
  }

  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.min(1000, screenWidth);
    const height = Math.min(1000, screenHeight);
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;
    const windowRef = window.open(
      response.url,
      // "_self",
      `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
    );
    const intervalRef = setInterval(async () => {
      const paymentStatus = await getPaymentStatusById(tnxId);
      if (paymentStatus && paymentStatus.length > 0) {
        clearInterval(intervalRef);
        windowRef?.close();
        if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
          setSuccessOpen(true);
        } else {
          setFailureOpen(true);
        }
      }
    }, 1000);
  };


  const handleSuccessClose = () => {
    setTimer(5);
    navigate('/appointments');
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate('/appointments');
    setFailureOpen(false);
  };
  const handleCustomPayOpen = () => {
    setCustomPay(true);
  };

  const handleCustomPayClose = () => {
    setCustomPay(false);
    setReson('');
    setAmount('');
    setReadOnly(false);
  };

  const handleAmountChange = (newValue: any) => {
    setAmount(newValue);
  };
  const handleResonChange = (newValue: string) => {
    setReson(newValue);
  };

  const handleRetryClick = async () => { };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      // const formattedAmount = amount.toString() + ".00";
      // formData.append("amount",amount.toString() + ".00");

      const regex = /\.(\d{2})$/;
      //e.log(amount);

      if (regex.test(amount)) {
        formData.append('amount', amount);
      } else {
        formData.append('amount', amount.toString() + '.00');
      }
      formData.append('description', reson);
      setCustomLoading(true);

      if (_id) {
        const updateResponse = await CustomPay(_id, formData);
        paymentResponse(updateResponse);
        if (updateResponse.status === 200) {
          // setCustomLoading(false);
          // setCustomPay(false);
        } else {
          console.error('Update failed:', updateResponse);
        }
      } else {
        // Handle the case where _id is null or undefined
        console.error('Selected community ID is null or undefined');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setCustomLoading(false);
      setAmount('');
      setReson('');
      setCustomPay(false);
    }
  };

  const mappedEvents = appointments?.flatMap((appointment) =>
    appointment.availability.flatMap((availability) =>
      availability.availableTimes.map((timeSlot) => {
        const formDetail = appointment.form.find(
          (form: any) => form.timeSlotId === timeSlot._id
        );
        return {
          _id: appointment._id,
          title: appointment.title,
          start: `${availability.day}T${timeSlot.startTime}`,
          end: `${availability.day}T${timeSlot.endTime}`,
          description: appointment.description,
          id: timeSlot._id,
          location: appointment.location,
          attendees: timeSlot.attendees,
          availability: appointment.availability,
          meetingLink: timeSlot.meetingLink,
          pricing: appointment.pricing,
          createdBy: {
            name: appointment.createdBy.firstName,
            emailId: appointment.createdBy.emailId,
            phoneNumber: appointment.createdBy.phoneNumber,
          },
          formDetails: formDetail || {},
        };
      })
    )
  );

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.appointments}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.author} />
        <meta name='keywords' content={metaConfig.keywords} />
      </Helmet>
      <Box sx={{ width: '100%', height: '100%', pb: { xs: 5, md: 0 } }}>
        <FullCalendar
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin,
          ]}
          initialView='dayGridMonth'
          events={mappedEvents}
          height='auto'
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth',
          }}
          buttonText={{
            month: 'Calendar',
            list: 'List',
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          eventClick={(info) => {
            // console.log('Clicked event details:', info.event);
            handleOpen(info.event);
            info.jsEvent.preventDefault();
          }}
          eventContent={(eventInfo) => (
            <>
              <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
                <p
                  style={{
                    padding: '5px',
                    borderRadius: '15px',
                    fontSize: '11px',
                    color: 'white',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {eventInfo?.event?.title}
                  <br></br>
                  {eventInfo?.event?.start && (
                    <>
                      {/* {convertTo12HourFormat(
                        eventInfo.event.start.toLocaleTimeString()
                      )} */}
                      {eventInfo.event.start.toLocaleTimeString()}
                    </>
                  )}
                </p>
              </Stack>
              <Stack
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  display: { xs: 'flex', md: 'none' }, // Show only on xs screens and hide on md and above
                  height: '100%', // Ensure the Stack takes full height of its parent
                  width: '100%', // Ensure the Stack takes full width of its parent
                }}
              >
                <FiberManualRecord sx={{ fontSize: '12px' }} />
              </Stack>
            </>
          )}
          initialDate={currentDate}
          weekends={true}
          weekNumbers={false}
          firstDay={0}
          showNonCurrentDates={true}
          navLinks={true}
        />

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloses}
          sx={{
            '& .MuiDialog-paper': {
              width: '500px',
              // maxWidth: '80%',
            },
          }}
        >
          <DialogActions>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ backgroundColor: '#ffffff' }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  textDecoration: 'none',
                  color: '#50A1CA',
                  textTransform: 'uppercase',
                  marginLeft: 2,
                }}
              >
                {selectedEvent?._id}
              </Typography>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: '#50A1CA',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#50A1CA',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Grid>
          </DialogActions>

          <DialogContent sx={{ px: 2 }}>
            <DialogContentText>
              <Stack spacing={1.5}>
                {/* Participant Name */}
                <Stack direction='column' gap={1}>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: '600',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {selectedEvent?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {selectedEvent?.extendedProps?.createdBy?.emailId}
                  </Typography>
                </Stack>

                {/* Event Date & Time */}
                <Stack direction='row' alignItems='center' gap={1}>
                  <CalendarMonthOutlinedIcon
                    sx={{ fontSize: '20px', color: 'gray' }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {/* {selectedEvent?.start} */}
                    {selectedEvent?.start.toString()}
                  </Typography>
                </Stack>

                {/* Event Price */}
                <Stack direction='row' alignItems='center' gap={1}>
                  <CurrencyRupeeIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {selectedEvent?.extendedProps?.pricing || 'Free'}
                  </Typography>
                </Stack>

                {/* Event Host */}
                <Stack direction='row' alignItems='center' gap={1}>
                  <Person2OutlinedIcon
                    sx={{ fontSize: '20px', color: 'gray' }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    With {selectedEvent?.extendedProps?.createdBy?.name}
                  </Typography>
                </Stack>

                {/* Contact Information */}
                <Stack direction='row' alignItems='center' gap={1}>
                  <PhoneOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {selectedEvent?.extendedProps?.createdBy?.phoneNumber}
                  </Typography>
                </Stack>

                {/* Conference Link */}
                <Stack direction='row' alignItems='center' gap={1}>
                  {selectedEvent?.extendedProps?.location &&
                    selectedEvent?.extendedProps?.location !== 'ONLINE' ? (
                    <>
                      <PinDropOutlinedIcon />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {selectedEvent?.extendedProps?.location}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box
                        component={'img'}
                        src={GooglesIcon}
                        width={'18px'}
                        height={'18px'}
                      />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          fontFamily: 'Montserrat',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: '#50A1CA',
                        }}
                        onClick={() => {
                          const meetingLink =
                            selectedEvent?.extendedProps?.meetingLink;
                          if (meetingLink) {
                            window.open(meetingLink, '_blank');
                          }
                        }}
                      >
                        Join the conference on Google Meet
                      </Typography>
                    </>
                  )}
                </Stack>

                <Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        fontFamily: 'Montserrat',
                        color: '#000000',
                      }}
                    >
                      Phone No
                    </Typography>
                    <TextField
                      value={
                        selectedEvent?.extendedProps?.formDetails
                          ?.phoneNumber || ''
                      }
                      size='small'
                      disabled
                      sx={{
                        backgroundColor: '#F9F9F9',
                        mt: 1,
                      }}
                      InputProps={{
                        sx: {
                          fontSize: '12px',
                          color: '#646464',
                          textAlign: 'center',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                  </Stack>

                  <Stack sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        fontFamily: 'Montserrat',
                        color: '#000000',
                      }}
                    >
                      Email ID
                    </Typography>
                    <TextField
                      value={
                        selectedEvent?.extendedProps?.formDetails?.email || ''
                      }
                      size='small'
                      disabled
                      sx={{
                        backgroundColor: '#F9F9F9',
                        mt: 1,
                      }}
                      InputProps={{
                        sx: {
                          fontSize: '12px',
                          color: '#646464',
                          textAlign: 'center',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <Stack direction='row' spacing={1} gap={2} mx={2}>
            <Button
              fullWidth
              size='large'
              variant='outlined'
              sx={{
                textTransform: 'capitalize',
                border: '1px solid #E7EBF1',
                borderRadius: '8px',
                boxShadow: 'none',
                color: '#000000',
                fontFamily: 'Montserrat',
                backgroundColor: '#ffffff',
                '&:hover': {
                  border: '1px solid #E7EBF1',
                },
              }}
              onClick={cancelAppointment}
            >
              {loader ? (
                <CircularProgress size={20} sx={{ color: '#3B9B7F' }} />
              ) : (
                'Cancel '
              )}
            </Button>

            <Button
              fullWidth
              size='large'
              variant='contained'
              sx={{
                backgroundColor: '#3B9B7F',
                textTransform: 'capitalize',
                border: 'none',
                borderRadius: '8px',
                boxShadow: 'none',
                color: 'white',
                fontFamily: 'Montserrat',
                '&:hover': {
                  backgroundColor: '#3B9B7F',
                  color: 'white',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
            >
              Reschedule
            </Button>
          </Stack>

          <Stack
            sx={{ p: 2, cursor: 'pointer' }}
            component={'a'}
            onClick={handleCustomPayOpen}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily: 'Montserrat',
                textAlign: 'center',
                textDecoration: 'underline',
                color: '#3B9B7F',
              }}
            >
              Custom Pay
            </Typography>
          </Stack>
        </Dialog>
      </Box>

      <CustomPayModel
        fullWidth={true}
        maxWidth={'xs'}
        customPay={customPay}
        handleCustomPayClose={handleCustomPayClose}
        buttonText={
          customloading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Pay now'
          )
        }
        handleCustomPayIcon={handleCustomPayClose}
        handleSubmitClick={() => {
          //if (amount && amount > 99.0) {
          handleSubmit();
          //}
        }}
        paymentvalue={amount || ''}
        paymentOnChange={handleAmountChange}
        paymenReason={reson}
        paymentReasonOnChange={handleResonChange}
        readonly={readonly}
      />
      {/* Payment Success Pop up */}
      <PaymentSuccess
        txnid={transaction?.txnid || ''}
        open={successOpen}
        amount={transaction?.amount || ''}
        timer={timer}
        dialogText={''}
        onClose={handleSuccessClose}
        onClickNavigateButton={() => {
          navigate('/payments');
          setSuccessOpen(false);
        }}
      />

      <PaymentFailed
        open={failureOpen}
        onClose={handleFailureClose}
        amount={transaction?.amount || ''}
        txnid={transaction?.txnid || ''}
        onClickNavigateButton={() => {
          navigate('/payments');
          setFailureOpen(false);
        }}
        dialogText={''}
        timer={timer}
        OnClickRetryButtonFunction={() => {
          if ((transaction?.udf2 as any) === '65cf774d1c183d386c3d3911') {
            setFailureOpen(false);
            setCustomPay(true);
            setAmount(transaction?.amount);
            setReson(transaction?.udf4 || '');
          } else {
            handleRetryClick();
          }
        }}
      />
    </>
  );
};
export default Bookings;
