import { Grid, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InvitesList from './CommunityInvitations/InvitesList';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';

const CommunityInvitations = () => {
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.communityInvitations}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.description} />
        <meta name='keywords' content={metaConfig.description} />
      </Helmet>
      <Stack direction={'row'} flexGrow={1} spacing={1}>
        <Box component='form' noValidate autoComplete='off'>
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              fontWeight: 500,
              color: '#50A1CA',
              fontFamily: 'Montserrat',
              marginBottom: '10px',
            }}
          >
            Invitations
          </Typography>
        </Box>
      </Stack>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ p: 1 }} elevation={0}>
            <Box>
              <InvitesList />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CommunityInvitations;
