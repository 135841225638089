
import { Stack, Typography } from "@mui/material";

const Messages = () => {
  return <>
    <Stack>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#50A1CA', fontFamily: 'Montserrat' }}>
        Messages
      </Typography>
    </Stack>
  </>;
};
export default Messages