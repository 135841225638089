import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useAppointments } from '../../../../hooks/useAppointments';
import { AvailableTime, IAppointment } from '../../../../models/appointments.models';
import Loader from '../../../Loader/Loader';
import ShareAvailabilityDetails from './ShareAvailabilityDetail';

const ShareAvailability = () => {
    const { id } = useParams();


    const { getNoAuthAppointmentById } = useAppointments();

    const [appointmentDetails, setAppointmentDetails] = useState<IAppointment[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [page, setPage] = useState(1);
    const [selectedTime, setSelectedTime] = useState<AvailableTime | undefined>(
        undefined
    );

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getNoAuthAppointmentById(id || '');
            // console.log(response, "response")
            if (Array.isArray(response)) {
                setAppointmentDetails(response);
            } else {
                setAppointmentDetails([]);
            }
        } catch (err) {
            console.error('Error fetching appointments:', err);
            setAppointmentDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchClassDetails();
        }
    }, []);

    const handleTimeClick = (time: any) => {
        setSelectedTime(time);
    };

    const convertTo12HourFormat = (time24: any) => {
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const formatToApiDate = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    // console.log(selectedDate, "selectedDate")

    const isDataAvailable = selectedDate && selectedTime;

    return (
        <>
            {page === 1 && (
                <>
                    {isLoading && <Loader />}
                    {appointmentDetails?.map((appointment, index) => {
                        const formattedSelectedDate = formatToApiDate(selectedDate);

                        return (
                            <React.Fragment key={index}>
                                <Box>
                                    <Paper
                                        sx={{
                                            borderRadius: '12px',
                                        }}
                                        elevation={0}
                                    >
                                        <Stack
                                            sx={{
                                                p: { xs: 1, md: 1 },
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={5}
                                                py={2}
                                            >
                                                <Grid item xs={12} md={7}>
                                                    <Calendar
                                                        onChange={handleDateChange}
                                                        value={selectedDate}
                                                        minDate={new Date()}
                                                        tileClassName={({ date, view }) =>
                                                            view === 'month' &&
                                                                selectedDate?.toDateString() ===
                                                                date.toDateString()
                                                                ? 'selected-date'
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontFamily: 'Montserrat',
                                                            fontSize: { xs: '14px', md: '16px' },
                                                            color: '#000000',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        {selectedDate.toDateString()}
                                                    </Typography>

                                                    {appointment?.availability?.filter(
                                                        (availability) =>
                                                            availability?.day === formattedSelectedDate
                                                    ).length > 0 ? (
                                                        appointment?.availability
                                                            ?.filter(
                                                                (availability) =>
                                                                    availability?.day === formattedSelectedDate
                                                            )
                                                            .map((availability, index) => (
                                                                <div key={index}>
                                                                    {availability?.availableTimes?.filter(
                                                                        (time) => {
                                                                            const currentTime = new Date();
                                                                            const timeStart = new Date(
                                                                                `${formattedSelectedDate}T${time.startTime}`
                                                                            );
                                                                            return timeStart > currentTime;
                                                                        }
                                                                    ).length > 0 ? (
                                                                        availability?.availableTimes
                                                                            ?.filter((time) => {
                                                                                const currentTime = new Date();
                                                                                const timeStart = new Date(
                                                                                    `${formattedSelectedDate}T${time.startTime}`
                                                                                );
                                                                                return timeStart > currentTime;
                                                                            })
                                                                            .slice(0, 4)
                                                                            .map((time, timeIndex) => {
                                                                                const isBooked =
                                                                                    time.attendees &&
                                                                                    time.attendees.length > 0;
                                                                                return (
                                                                                    <Button
                                                                                        fullWidth
                                                                                        key={timeIndex}
                                                                                        variant='outlined'
                                                                                        sx={{
                                                                                            fontFamily: 'Montserrat',
                                                                                            fontWeight: 500,
                                                                                            color:
                                                                                                selectedTime === time
                                                                                                    ? '#ffffff'
                                                                                                    : isBooked
                                                                                                        ? '#888888'
                                                                                                        : '#000000',
                                                                                            backgroundColor:
                                                                                                selectedTime === time
                                                                                                    ? '#3B9B7F'
                                                                                                    : isBooked
                                                                                                        ? '#E0E0E0'
                                                                                                        : 'transparent',
                                                                                            height: {
                                                                                                xs: '40px',
                                                                                                md: '50px',
                                                                                            },
                                                                                            border:
                                                                                                selectedTime === time
                                                                                                    ? '1px solid #3B9B7F'
                                                                                                    : isBooked
                                                                                                        ? '1px solid #BDBDBD'
                                                                                                        : '1px solid black',
                                                                                            fontSize: {
                                                                                                xs: '14px',
                                                                                                md: '15px',
                                                                                            },
                                                                                            marginBottom: '16px',
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#3B9B7F',
                                                                                                color: 'white',
                                                                                                border: '1px solid #3B9B7F',
                                                                                            },
                                                                                            pointerEvents: isBooked
                                                                                                ? 'none'
                                                                                                : 'auto',
                                                                                            opacity: isBooked ? 0.5 : 1,
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            !isBooked && handleTimeClick(time)
                                                                                        }
                                                                                    >
                                                                                        {convertTo12HourFormat(
                                                                                            time.startTime
                                                                                        ) || 'Unavailable'}
                                                                                        {isBooked && ' (Booked)'}
                                                                                    </Button>
                                                                                );
                                                                            })
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            ))
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontFamily: 'Montserrat',
                                                                fontSize: { xs: '14px', md: '16px' },
                                                                color: '#000000',
                                                                mb: 2,
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            No slots available
                                                        </Typography>
                                                    )}

                                                    <Box
                                                        sx={{ mt: 2, display: { xs: 'block', md: 'none' } }}
                                                    >
                                                        <Button
                                                            fullWidth
                                                            disabled={!isDataAvailable}
                                                            variant='contained'
                                                            sx={{
                                                                background:
                                                                    'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                                textTransform: 'capitalize',
                                                                borderRadius: '5px',
                                                                boxShadow: 'none',
                                                                fontFamily: 'Montserrat',
                                                                '&:hover': {
                                                                    background:
                                                                        'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                            onClick={() => setPage(2)}
                                                        >
                                                            Continue
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        <Box
                                            sx={{ mt: 2, display: { xs: 'none', md: 'flex' } }}
                                            justifyContent={'flex-end'}
                                        >
                                            <Button
                                                disabled={!isDataAvailable}
                                                variant='contained'
                                                sx={{
                                                    background:
                                                        'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                    textTransform: 'capitalize',
                                                    borderRadius: '5px',
                                                    boxShadow: 'none',
                                                    fontFamily: 'Montserrat',
                                                    '&:hover': {
                                                        background:
                                                            'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                                                        color: 'white',
                                                    },
                                                }}
                                                onClick={() => setPage(2)}
                                            >
                                                Continue
                                            </Button>
                                        </Box>
                                    </Paper>
                                </Box>
                            </React.Fragment>
                        );
                    })}
                </>
            )}
            {page === 2 && (
                <ShareAvailabilityDetails
                    appointmentDetails={appointmentDetails}
                    selectedTime={selectedTime}
                    selectedDate={selectedDate}
                />
            )}
        </>
    );
};

export default ShareAvailability;
