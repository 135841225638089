import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, Grid, Stack, Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../Loader/Loader';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoClass from '../../../assets/icons/svg-icons/noPlans.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAppointments } from '../../../hooks/useAppointments';
import { IAppointment } from '../../../models/appointments.models';
import { CalenderWhiteIcon } from '../../../assets/icons';



const UpComingAppointments = ({ searchQuery }: { searchQuery: string }) => {
    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getAllAppointments } = useAppointments();

    const [appointments, setAppointments] = React.useState<IAppointment[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchAppointments = async () => {
        setIsLoading(true);
        try {
            const response = await getAllAppointments(community_id);
            if (Array.isArray(response)) {
                setAppointments(response);
            } else {
                setAppointments([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setAppointments([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);


    const navigate = useNavigate()

    const filteredAppointments = appointments.filter((appointment) =>
        appointment.title.toLowerCase().includes(searchQuery.toLowerCase())
    );





    return (
        <Box sx={{ position: 'relative', height: { xs: '100vh', md: '75vh' }, overflow: 'auto' }}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Loader />
                </Box>
            ) : appointments.length === 0 ? (
                <AlternativePage title="No UpComing Appointments" image={NoClass} />
            ) : (
                <Grid container gap={1.5} py={1}>
                    {filteredAppointments?.map((classData, index) => (
                        <Grid item xs={12} md={3.9} key={index}>
                            <Card elevation={0} sx={{
                                borderLeft: '6px solid #D4DDED',
                                borderRight: '1px solid #D4DDED',
                                borderTop: '1px solid #D4DDED',
                                borderBottom: '1px solid #D4DDED',
                                borderRadius: '12px',
                                '&:hover': {
                                    borderLeft: '6px solid #50A1CA',
                                    borderRight: '1px solid #50A1CA',
                                    borderTop: '1px solid #50A1CA',
                                    borderBottom: '1px solid #50A1CA',
                                }
                            }}
                            >
                                <CardContent
                                    sx={{
                                        pt: 1,
                                        pb: 0,
                                        "&:last-child": {
                                            pb: '1px',
                                        },
                                    }}>
                                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ py: 1 }}>
                                        <Stack>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    color: '#646464',
                                                }}
                                            >
                                                {classData?.pricing ? `₹${classData.pricing}` : "Free"} - {classData?.location?.length > 30 ? classData?.location?.substring(0, 30) + "..." : classData?.location}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    color: '#000000',
                                                    cursor: 'pointer'
                                                }}

                                                component={'a'}

                                                onClick={() => {
                                                    navigate(`/appointment/${classData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${classData?._id}`)
                                                }}
                                            >
                                                {classData?.title?.length > 30 ? classData?.title?.substring(0, 30) + '...' : classData?.title}
                                            </Typography>
                                        </Stack>

                                        {/* <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                            <IconButton sx={{

                                            }}>
                                                <Share1Icon onClick={() => {
                                                    navigate(`/appointment/${classData?._id}`)
                                                }} sx={{ fontSize: '16px', color: '#969696' }} />
                                            </IconButton>
                                            <IconButton sx={{}}
                                                onClick={(event) => handleClick(event, classData?._id!)}>
                                                <MoreVertOutlinedIcon sx={{ fontSize: '20px', color: '#969696' }} />
                                            </IconButton>
                                            <Menu
                                                id={`menu-${index}`}
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && selectedItemId === classData?._id}
                                                onClose={handleClose}
                                                elevation={1}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        minWidth: '125px',
                                                        borderRadius: '10px',
                                                        border: '1px solid #D4DDED',

                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', }}
                                                    onClick={() => {
                                                        navigate(`/edit-appointment/${classData?._id}`)
                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    <EditIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Edit
                                                </MenuItem>

                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', }}
                                                    onClick={() => {

                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    <DeleteIcon sx={{ fontSize: '15px', marginRight: 1 }} />  Delete
                                                </MenuItem>




                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', }}
                                                    onClick={() => {

                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    <PlayArrowOutlined sx={{ fontSize: '15px', marginRight: 1 }} />  Pause
                                                </MenuItem>
                                            </Menu>
                                        </Stack> */}
                                        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                                            <IconButton sx={{
                                                backgroundColor: '#50A1CA',
                                                ':hover': {
                                                    backgroundColor: '#50A1CA'
                                                }
                                            }}>
                                                <CalenderWhiteIcon onClick={() => {
                                                    navigate(`/appointment/${classData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${classData?._id}`)
                                                }} sx={{ fontSize: '16px', color: '#' }} />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ py: 1.5, }}
                                    >
                                        <Stack
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <Link
                                                to={`/appointment/${classData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${classData?._id}`}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    gap: '10px',
                                                }}
                                            >

                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '12px',
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: '500'
                                                }}>
                                                    {classData?.description?.length > 45 ? classData?.description.substring(0, 45) + "..." : classData?.description}
                                                </Typography>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default UpComingAppointments;
