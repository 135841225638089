import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import UpComingAppointments from './Components/UpComingAppointments';
import { SearchIcon1 } from '../../assets/icons';
import Bookings from './Components/Bookings';

const Appointments = () => {
  const [value, setValue] = React.useState('1');
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.appointments}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.author} />
        <meta name='keywords' content={metaConfig.keywords} />
      </Helmet>

      <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 2 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
        {/* <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                    navigate('/your-target-route');
                }} /> */}
        <Typography
          sx={{
            fontSize: { xs: '16px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          Appointments
        </Typography>
      </Stack>

      <SubNavbar>

        <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
          <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              sx={{
                fontSize: { xs: '15px', md: '18px' },
                fontWeight: 600,
                color: '#000000',
                fontFamily: 'Montserrat',
              }}
            >
              Appointments
            </Typography>
          </Stack>
          <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
            <Box sx={{ width: { xs: '100%', md: '300px' } }}>
              <TextField
                placeholder="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#F9F9F9',
                    fontSize: { xs: '12px', md: '15px' },
                    height: '37px',
                    color: '#000000',
                    fontFamily: 'Montserrat',
                    ml: { xs: -1 },
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth // Ensures the TextField takes full width of the container
              />
            </Box>

          </Stack>
        </Stack >

      </SubNavbar>


      <Box
        sx={{
          width: '100%',
          mt: 1,
          backgroundColor: 'white',
          borderRadius: '12px !important',
          border: '1px solid #E7EBF1',
          px: 2,
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              // backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#50A1CA',
                },
              }}
            >
              <Tab
                label='Appointments'
                value='1'
                sx={{
                  fontSize: { xs: "14px", md: '16px' },
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  fontFamily: 'Montserrat',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 500,
                    '&.MuiTabs-indicator': {
                      backgroundColor: '#50A1CA !important',
                    },
                  },
                }}
              />
              <Tab
                label='Bookings'
                value='2'
                sx={{
                  fontSize: { xs: "14px", md: '16px' },
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  fontFamily: 'Montserrat',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 500,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value='1' sx={{ px: 0, py: 1 }}>
            <UpComingAppointments searchQuery={searchQuery} />
          </TabPanel>
          <TabPanel value='2' sx={{ px: 0, py: 1 }}>
            <Bookings />
          </TabPanel>
        </TabContext>
      </Box>

      {page === 2}
    </>
  );
};

export default Appointments;
