import {
  Avatar,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SubNavbar } from '../../Layout/components/SubNavbar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { useSubscription } from '../../../hooks/useSubscription';
import { ISubscribers } from '../../../models/subscription.model';
import store from '../../../store';
import { getStaticValue } from '../../../utils/StaticValues';
import SmallLoader from '../../Loader/SmallLoader';
import { IPaymentList } from '../../../models/payment.model';
import { usePayment } from '../../../hooks/usePayment';
import { IPlan } from '../Plans';
import PaymentSuccess from '../../Payments/PaymentSuccessPopUp';
import PaymentFailed from '../../Payments/PaymentFailedPopUp';
import React from 'react';
import { ArrowBackIosNewOutlined, KeyboardDoubleArrowRightOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

interface IPlanProps {
  plan?: IPlan;
  onClick?: () => void;
  plans?: any[];
}

enum Status {
  NOT_PAID = 'NOT_PAID',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  PAID_BY_CASH = 'PAID_BY_CASH',
}

const UserSubscription = (props: IPlanProps) => {
  enum PaymentStatus {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    USERCANCELLED = 'USERCANCELLED',
  }

  const getBorderColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7FC41B';
      case 'PAID_BY_CASH':
        return '#7FC41B';
      case 'NOT_PAID':
        return '#50A1CA';
      case 'NA':
        return '#50A1CA';
      case 'EXPIRED':
        return '#DC8A8A';
      case 'CANCELLED':
        return '#F44336';
      default:
        return '#50A1CA';
    }
  };

  const getBackgroundColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7CB92E33';
      case 'PAID_BY_CASH':
        return '#7CB92E33';
      case 'NOT_PAID':
        return '#EEF7FD';
      case 'NA':
        return '#EEF7FD';
      case 'EXPIRED':
        return '#DC8A8A33';
      case 'CANCELLED':
        return '#F4433633';
      default:
        return '#3C5AA0';
    }
  };

  const getTextColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7CB92E';
      case 'PAID_BY_CASH':
        return '#7CB92E';
      case 'NOT_PAID':
        return '#000000';
      case 'NA':
        return '#000000';
      case 'EXPIRED':
        return '#DC8A8A';
      case 'CANCELLED':
        return '#F44336';
      default:
        return '#3C5AA0';
    }
  };

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const { enqueueSnackbar } = useSnackbar();

  const [subscriptionList, setSubscriptionList] = useState<[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscribers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAmounts, setSelectedAmounts] = useState<
    { id: string; amount: number; startDate: string }[]
  >([]);
  const [planId, setplanId] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [failureOpen, setFailureOpen] = useState(false);
  const [transaction, setTransaction] = useState<IPaymentList>();
  const [community, setCommunity] = useState('');
  const [sequenceId, setSequenceId] = useState<string[]>([]);
  const plans = [];
  const [, setRetryLoadingStates] = useState<boolean[]>(
    Array(plans?.length).fill(false)
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    initiatePaymentByIds,
    getPaymentStatusById,
    updateSequencesPaymentStatus,
  } = usePayment();

  const navigate = useNavigate();

  const paymentResponse = async (response: any, selectedSequences: any) => {
    try {
      console.log('response123:', response);
      const tnxId = response?.transactionId;
      const transaction = response?.transaction as IPaymentList;

      if (transaction) {
        setTransaction(transaction);
      }

      if (response?.url) {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = Math.min(1000, screenWidth);
        const height = Math.min(1000, screenHeight);
        const left = (screenWidth - width) / 2;
        const top = (screenHeight - height) / 2;

        const windowRef = window.open(
          response.url,
          `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
        );

        const intervalRef = setInterval(async () => {
          console.log('transactionId:', tnxId);
          const paymentStatus = await getPaymentStatusById(tnxId);

          if (paymentStatus && paymentStatus.length > 0) {
            clearInterval(intervalRef);
            windowRef?.close();

            if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
              await updateSequencesPaymentStatus(
                communityId,
                selectedSequences
              );
              setSuccessOpen(true);
            } else {
              setFailureOpen(true);
            }
          }
        }, 1000);
      } else {
        console.error('Payment URL not provided in response');
      }
    } catch (error) {
      console.error('An error occurred in paymentResponse:', error);
    }
  };

  const handleSuccessClose = () => {
    setTimer(3);
    navigate('/home');
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(3);
    navigate('/subscriptions');
    setFailureOpen(false);
  };

  const handleRetryClick = async (
    communityId: string,
    planId: string,
    id: string
  ) => {
    setCommunity(communityId);
    setplanId(planId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = true;
      return newStates;
    });

    // console.log('ANDRIOD PAYMENT');
    const amount = totalAmount.toString();
    console.log('payment-branch:', amount);
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      sequenceId,
      amount
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = false;
      return newStates;
    });
    const sequenceIds = selectedAmounts
      ?.filter((item: any) => item?.id)
      .map((item: any) => item.id);
    paymentResponse(response, sequenceIds);
  };

  const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleRetryClickIos = async (
    communityId: string,
    planId: string,
    id: string
  ) => {
    setCommunity(communityId);
    setplanId(planId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = true;
      return newStates;
    });
    // console.log('Payment Retry Started for iOS');
    const amount = totalAmount.toString();
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      sequenceId,
      amount
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = false;
      return newStates;
    });
    const sequenceIds = selectedAmounts
      ?.filter((item: any) => item?.id)
      .map((item: any) => item.id);
    handleIOSPaymentResponse(response, sequenceIds);
  };

  const handleIOSPaymentResponse = async (
    response: any,
    selectedSequences: any
  ) => {
    try {
      const tnxId = response?.transactionId;
      const transaction = response?.transaction as IPaymentList;

      if (transaction) {
        setTransaction(transaction);
      }
      if (response?.url) {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          window.location.href = response.url;
        } else {
          window.open(response.url, '_blank');
        }
      } else {
        console.error('Payment URL not provided in response');
      }

      const intervalRef = setInterval(async () => {
        const paymentStatus = await getPaymentStatusById(tnxId);

        if (paymentStatus && paymentStatus.length > 0) {
          clearInterval(intervalRef);

          if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
            await updateSequencesPaymentStatus(communityId, selectedSequences);
            setSuccessOpen(true);
          } else {
            setFailureOpen(true);
          }
        }
      }, 1000);
    } catch (error) {
      console.error('An error occurred in handleIOSPaymentResponse:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleSelectAmount = (
    id: string,
    amount: number,
    startDate: string
  ) => {
    setSelectedAmounts((prev) => {
      if (prev.some((item) => item.id === id)) {
        const updatedAmounts = prev.filter((item) => item.id !== id);
        const sequenceIds = updatedAmounts.map((item) => item.id);
        setSequenceId(sequenceIds);
        setErrorMessage(null);
        return updatedAmounts;
      } else if (prev.length < 10) {
        const updatedAmounts = [...prev, { id, amount, startDate }];
        const sequenceIds = updatedAmounts.map((item) => item.id);
        setSequenceId(sequenceIds);
        setErrorMessage(null);
        return updatedAmounts;
      }
      enqueueSnackbar('You can select a maximum of 10 items.', {
        variant: 'error',
      });
      return prev;
    });
  };

  const totalAmount = selectedAmounts.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  const { createSubscriptionSequencesByPlanAndCommunityId } = useSubscription();
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const { id } = useParams();
  const validId = id ?? '';

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const mobileViewHeight = 200;
  const desktopViewHeight = 340;

  const heights = isMobileView ? mobileViewHeight : desktopViewHeight;

  useEffect(() => {
    if (subscriptionList && subscriptionList.length > 0) {
      const filteredSubscription = subscriptionList.filter(
        (subscription: ISubscribers) =>
          String(subscription?.plan?._id) === String(id)
      );
      if (filteredSubscription && filteredSubscription.length > 0) {
        setSubscriptions(filteredSubscription);
      } else {
        console.warn(
          'No matching subscription found or subscriptionList is empty.'
        );
      }
    }
  }, [subscriptionList, id]);

  const communityId = selectedCommunityId;

  const fetchSequencesByPlanAndCommunityID = async () => {
    setIsLoading(true);
    try {
      const response = await createSubscriptionSequencesByPlanAndCommunityId(
        communityId,
        validId
      );
      setSubscriptions(response);
      return response;
    } catch (err) {
      console.log('Error: ', err);
      setSubscriptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(subscriptions, "subscriptions")

  useEffect(() => {
    fetchSequencesByPlanAndCommunityID();
  }, [communityId, validId]);

  const location = useLocation();

  const pathLocation = /^\/plans\/subscription\/[^/]+$/.test(location.pathname);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.author} />
        <meta name='keywords' content={metaConfig.keywords} />
      </Helmet>
      <SubNavbar>

        <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' }, }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
          <ArrowBackIosNewOutlined sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
            navigate('/plans');
          }} />
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '18px' },
              fontWeight: 600,
              color: '#000000',
              fontFamily: 'Montserrat',
            }}
          >
            Sequence
          </Typography>
        </Stack>
      </SubNavbar>
      <Paper
        sx={{
          px: { xs: 3, md: 2 },
          py: { xs: 2, md: 1 },
          mb: 2,
          mt: 1,
          boxShadow: 'none',
          borderRadius: '12px !important', border: '1px solid #E7EBF1',

        }}
      >
        {Array.isArray(subscriptions) &&
          subscriptions.map((userDetails: ISubscribers, index) => (
            <React.Fragment key={index}>
              {/* Mobile Layout (Grid) */}
              <Grid
                container
                spacing={2}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                {/* Name */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        {loggedInUserData?.user?.firstName}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>

                {/* Plan Name */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Plan Name
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        {userDetails?.plan?.name}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>

                {/* Amount */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        ₹
                        {userDetails?.plan?.offerValue === 0
                          ? userDetails?.plan?.totalPlanValue
                          : userDetails?.plan?.offerValue}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>

                {/* Billing Cycle */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Billing Cycle
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        {userDetails?.plan?.interval}
                        {'   '}
                        {userDetails?.plan?.interval > '1'
                          ? `${getStaticValue(userDetails?.plan?.duration)}s`
                          : getStaticValue(userDetails?.plan?.duration)}{' '}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>

                {/* Created Date */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Created
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        {new Date(userDetails?.createdAt).toLocaleDateString()}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>

                {/* Status */}
                <Grid item xs={4} md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                    }}
                  >
                    Status
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 600,
                      marginBottom: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Stack
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                      gap={1}
                    >
                      <Typography
                        component={'span'}
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Montserrat',
                          color: '#1A1A1A',
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{
                            color:
                              userDetails?.subscription_status === 'ACTIVE'
                                ? '#7CB92E'
                                : '#ffa87d',
                            fontSize: '9px',
                          }}
                        />{' '}
                        {userDetails?.subscription_status === 'ACTIVE'
                          ? 'Active'
                          : 'Inactive'}
                      </Typography>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>

              {/* Desktop Layout (Table) */}
              <TableContainer
                sx={{
                  display: { xs: 'none', md: 'flex' },

                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size='small'
                  aria-label='a dense table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: 'Montserrat', }}>Name</TableCell>
                      <TableCell sx={{ fontFamily: 'Montserrat', }} align='right'>Plan Name</TableCell>
                      <TableCell sx={{ fontFamily: 'Montserrat', }} align='right'>Amount</TableCell>
                      <TableCell sx={{ fontFamily: 'Montserrat', }} align='right'>Billing Cycle</TableCell>
                      <TableCell sx={{ fontFamily: 'Montserrat', }} align='right'>Created</TableCell>
                      <TableCell sx={{ fontFamily: 'Montserrat', }} align='right'>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      key={index}
                    >
                      <TableCell component='th' scope='row'>
                        <Stack
                          sx={{
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                          gap={2}
                        >
                          <Avatar
                            src={loggedInUserData?.user?.avatar}
                            sx={{
                              height: '40px',
                              width: '40px',
                              fontSize: '11px',
                            }}
                            alt={`${loggedInUserData?.user?.firstName}`}
                          />
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            <Typography
                              component={'span'}
                              sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {loggedInUserData?.user?.firstName}
                            </Typography>
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align='right' sx={{ fontFamily: 'Montserrat', }}>
                        {userDetails?.plan?.name}
                      </TableCell>
                      <TableCell align='right' sx={{ fontFamily: 'Montserrat', }}>
                        ₹
                        {userDetails?.plan?.offerValue ||
                          userDetails?.plan?.totalPlanValue}
                        {/* {userDetails?.plan?.offerValue === 0
                            ? userDetails?.plan?.totalPlanValue
                            : userDetails?.plan?.offerValue} */}
                      </TableCell>
                      <TableCell align='right' sx={{ fontFamily: 'Montserrat', }}>
                        {userDetails?.plan?.interval}
                        {'   '}
                        {userDetails?.plan?.interval > '1'
                          ? `${getStaticValue(userDetails?.plan?.duration)}s`
                          : getStaticValue(userDetails?.plan?.duration)}{' '}
                      </TableCell>
                      <TableCell align='right' sx={{ fontFamily: 'Montserrat', }}>
                        {new Date(userDetails?.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell align='right' sx={{ fontFamily: 'Montserrat', }}>
                        <FiberManualRecordIcon
                          sx={{
                            color:
                              userDetails?.subscription_status === 'ACTIVE'
                                ? '#7CB92E'
                                : '#ffa87d',
                            fontSize: '9px',
                          }}
                        />{' '}
                        {userDetails?.subscription_status === 'ACTIVE'
                          ? 'Active'
                          : 'Inactive'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
      </Paper>

      <Paper
        sx={{
          p: { xs: 1, md: 2 },
          mt: 2,
          height: { xs: '45vh', md: '61vh' },
          boxShadow: 'none',
          borderRadius: '12px !important', border: '1px solid #E7EBF1',
        }}
      >
        <Stack display={'flex'} flexDirection={'row'}>
          <Stack>
            {Array.isArray(subscriptions) &&
              subscriptions?.map((subscription: ISubscribers, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: { xs: '15px', md: '18px' },
                    fontWeight: '500',
                    fontFamily: 'Montserrat',
                    color: '#50A1CA',
                    pt: { xs: 1, md: 0 },
                  }}
                >
                  Start Date :{' '}
                  {/* {new Date(subscription?.startDate).toLocaleDateString()} */}
                  {new Date(subscription?.createdAt).toLocaleString('en-GB', {
                    timeZone: 'Asia/Kolkata',
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour12: false,
                  })}
                </Typography>
              ))}
          </Stack>
          <Stack></Stack>
        </Stack>

        <Stack>
          <SimpleBar style={{ height: `${heights}px` }}>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {isLoading && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <SmallLoader />
                </Grid>
              )}
              {!isLoading &&
                Array.isArray(subscriptions) &&
                subscriptions.map(
                  (subscription: ISubscribers) =>
                    Array.isArray(subscription?.sequences) &&
                    subscription.sequences.map((sequence, index) => {
                      const amount =
                        subscription?.plan?.offerValue ||
                        subscription?.plan?.totalPlanValue;
                      const isSelected = selectedAmounts.some(
                        (item) => item.id === sequence._id
                      );
                      return (
                        <Grid
                          item
                          key={sequence._id}
                          xs={4}
                          sm={6}
                          md={2}
                          lg={1.5}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              fontWeight: 400,
                              textAlign: 'center',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {new Date(sequence?.startDate).toLocaleString(
                              'en-GB',
                              {
                                timeZone: 'Asia/Kolkata',
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                hour12: false,
                              }
                            )}
                          </Typography>

                          <Button
                            disabled={
                              sequence?.status === 'PAID' ||
                              sequence?.status === 'PAID_BY_CASH' ||
                              sequence?.status === 'NA' ||
                              subscription?.subscription_status === 'STOP' ||
                              index === subscription.sequences.length - 1
                            }
                            onClick={() =>
                              handleSelectAmount(
                                sequence._id,
                                amount,
                                sequence?.startDate
                              )
                            }
                            sx={{
                              backgroundColor: isSelected
                                ? '#50A1CA'
                                : `${getBackgroundColor(sequence.status)}`,
                              color: isSelected ? '#ffffff' : '#515151',
                              fontSize: { xs: '12px', md: '14px' },
                              border: `1px solid ${getBorderColor(
                                sequence?.status
                              )}`,
                              borderRadius: '30px',
                              overflow: 'hidden',
                              display: 'flex',
                              fontFamily: 'Montserrat',
                              boxShadow: 'none',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: { xs: '100%', md: '90%' },
                              mt: 1,
                              '&:hover': {
                                border: '1px solid #50A1CA',
                                boxShadow: 'none',
                                backgroundColor: isSelected
                                  ? '#50A1CA'
                                  : '#EEF7FD',
                              },
                            }}
                          >
                            ₹{amount}
                          </Button>
                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              color: `${getTextColor(sequence?.status)}`,
                              fontWeight: 400,
                              mt: 1,
                              textAlign: 'center',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {getStaticValue(sequence?.status)}
                          </Typography>
                        </Grid>
                      );
                    })
                )}
            </Grid>
          </SimpleBar>
        </Stack>
        <Divider></Divider>

        <Stack sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
          {Array.isArray(subscriptions) &&
            subscriptions.map((subscription: ISubscribers, index) => (
              <Button
                key={index}
                disabled={
                  subscription?.subscription_status === 'STOP' ||
                  selectedAmounts?.length === 0
                }
                variant='contained'
                sx={{
                  background:
                    'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                  textTransform: 'capitalize',
                  borderRadius: '5px',
                  boxShadow: 'none',
                  width: '150px',
                  fontFamily: 'Montserrat',
                  '&:hover': {
                    background:
                      'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                    color: 'white',
                  },
                }}
                onClick={() =>
                  isIOSDevice
                    ? handleRetryClickIos(communityId, validId, validId)
                    : handleRetryClick(communityId, validId, validId)
                }
              >
                {isIOSDevice ? 'Pay' : 'Pay'} ₹{totalAmount}
              </Button>
            ))}
        </Stack>
      </Paper>

      <PaymentSuccess
        txnid={transaction?.txnid || ''}
        open={successOpen}
        amount={transaction?.amount || ''}
        timer={timer}
        dialogText={''}
        onClose={handleSuccessClose}
        onClickNavigateButton={() => {
          navigate('/home');
          setSuccessOpen(false);
        }}
      />
      <PaymentFailed
        open={failureOpen}
        onClose={handleFailureClose}
        amount={transaction?.amount || ''}
        txnid={transaction?.txnid || ''}
        onClickNavigateButton={() => {
          navigate('/subscriptions');
          setFailureOpen(false);
        }}
        dialogText={''}
        timer={timer}
        OnClickRetryButtonFunction={() => {
          handleRetryClick(community, planId, '');
          setFailureOpen(false);
        }}
      />
    </>
  );
};
export default UserSubscription;
