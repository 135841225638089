// import * as React from "react";
import Box from "@mui/material/Box";
import { AppBar, DialogContent, Grid, IconButton, Stack, Toolbar } from "@mui/material";
import { styles } from "./NoAuthCommunity.styles";
import { Outlet, useParams } from "react-router-dom";
import NoAuthHeader from "./NoAuthHeader";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { ICommunity } from "../../../models/communities.modal";
import { BASE_URL } from "../../../configurations/url.config";
import axios from "axios";
import LoginPopUp1 from "../../Auth/Login/LoginPopUp1";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// import { AddIcon, SearchIcon } from "../../assets/icons";
// import { useState } from "react";

// interface FormValues {
//     firstName: string;
//     lastName: string;
//     emailId: string;
//     phoneNumber: string;
//   }

export default function NoAuthCommunityLayout() {

  const [isAppBarOpen, setAppBarOpen] = React.useState(false);
  // const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');


  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };


  // const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
  //   setMaxWidth(
  //     // @ts-expect-error autofill of arbitrary value is not handled.
  //     event.target.value,
  //   );
  // };

  // const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFullWidth(event.target.checked);
  // };

  // const handleCancelClick = () => {
  //   setAppBarOpen(false);
  // };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setAppBarOpen(true);
    }, 10000); // Display every 5 seconds

    // Clear the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Run once on mount

  const [data, setData] = useState<ICommunity>();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/communities/${id}/home`
        );
        setData(response?.data.community);

      } catch (error) {
        console.log("error");
      }
    };
    fetchData();
  }, [id]);


  return (
    <Grid container>
      {/* Sidebar */}


      {/* Main Content */}
      <Grid item md={12} sm={12} xs={12}>
        <Box sx={styles.main}>
          {/* Header Section */}
          {data && <NoAuthHeader data={data} />}

          {/* Content Section */}
          <Box sx={styles.contentSection}>
            {/* <Dashboard /> */}
            <Outlet />


            {isAppBarOpen && (
              <Stack
                position="sticky"
                top={0}
                sx={{ mt: "20px" }}
                zIndex={1000} // Ensure the Stack is on top of other elements
              >

                <AppBar
                  position="fixed"
                  color="primary"
                  elevation={0}
                  sx={{
                    top: "auto",
                    bottom: 0,
                    backgroundColor: "transparent",
                    justifyContent: "center",
                    transition: 'opacity 0.5s ease-in-out', // Add transition property
                    opacity: 1, // Set initial opacity

                  }}
                >
                  <Toolbar sx={{ justifyContent: "center" }}>

                    <Stack direction={"row"} sx={{ alignItems: 'center' }}>
                      {/* <Button
                        variant="contained"
                        
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "100px",
                          background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                          fontSize: "15px",
                          width:'140px',
                          color:'#ffffff',
                          textAlign:"center"

                        }}
                        onClick={handleClickOpen}
                      >
                        I’ll Join
                      </Button> */}
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        fullWidth
                      >
                        <Box
                          component={"img"}
                          src={require("./../../../assets/images/LoginMbg.png")}
                          sx={{
                            height: "100px",
                            width: "100%",
                            objectFit: "fill",
                            display: { xs: "block", md: "block" },
                          }}
                          alt=""
                        ></Box>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <DialogContent >
                          {data && <LoginPopUp1 data={data} />}
                        </DialogContent>
                      </BootstrapDialog>
                    </Stack>
                    <Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
              </Stack>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item md={2} sm={12} xs={12}>

      </Grid>
    </Grid>
  );
}
