import axios from "axios";
import { AI_APP_BASE_URL } from "../configurations/url.config";


export const generateAiPostSuggestions = async (community_name: string) => {
  try {
    const response = await axios.post(
      `${AI_APP_BASE_URL}`,
      {
        community_name,
      },
      {
        headers: {
          //   Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }
    );

    // console.log(response.data);
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: {} };
  }
};
