import { useEffect, useState } from "react";
import { useCommunity } from "../../../hooks/useCommunity";
import { ICommunity } from "../../../models/communities.modal";
import { SuggestionsList } from "./SuggestionsList";
import { Paper } from "@mui/material";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import SmallLoader from "../../Loader/SmallLoader";

export const Suggestions = () => {
  const { getSuggestionCommunities } = useCommunity();
  const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const trending = await getSuggestionCommunities();
        setTrendingCommunities(trending.communities);
      } catch (error) {
        console.error("Error fetching suggestion communities:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const refetchData = async () => {
    const response = await getSuggestionCommunities();


    if (response) {
      setTrendingCommunities(response.communities);
    }
  };

  return (
    <>
      <Paper elevation={1} sx={{ maxHeight: "33vh", boxShadow: 'none', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
        {loading ? ( // Display loader if loading is true
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
            <SmallLoader /> {/* CircularProgress for loader */}
          </div>
        ) : (
          <SimpleBar style={{ maxHeight: "33vh", borderRadius: '10px' }}>
            {trendingCommunities.map((community) => (
              <SuggestionsList key={community._id} {...community} refetchData={refetchData} />
            ))}
          </SimpleBar>
        )}
      </Paper>
    </>
  );
};
