import React, { Key, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './../../AboutShare/About.css';
import AboutCarousel from './../../AboutShare/AboutCarousal';
import AboutCarouselTeem from '././../../AboutShare/AboutCarouselTeem';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ICommunity } from '../../../models/communities.modal';
import {
  BASE_URL,
  USER_APP,
  USER_APP_BASE_URL,
} from '../../../configurations/url.config';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../../contexts/Auth.context';
import ImageGalleryCraft from '../../ImageUploader/ImageGalleryCraft';
// import { Homecarousel } from "../../Home/HomeCarousel";
import { useCommunity } from '../../../hooks/useCommunity';
import SendRequestCommunityPreview from '../../ExploreCommunities/SendRequestCommunityPreview';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import QrCode from './QrCode';
// import { loadSelectedCommunity } from '../../../store/slices/CommunitySlice';
import { useDispatch } from 'react-redux';

const About = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { id } = useParams();

  // console.log(name, "name");
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const community_id = id ? id : selectedCommunity?._id;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { getCommunityById } = useCommunity();
  const [data, setData] = useState<ICommunity>();
  const [loading, setLoading] = useState(false);
  // const [memberList, setMemberList] = useState<ICommunity[]>([]);

  //join community
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${data?._id}/about`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;

  // const handleClickOpen = () => {
  //   setModalOpen(true);
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let response;
        if (isAuthenticated && community_id) {
          response = await getCommunityById(community_id);
          setData(response?.data);
        } else if (community_id) {
          response = await axios.get(
            `${BASE_URL}/communities/${community_id}/home`
          );
          const { community } = response?.data;
          setData(community);
        }
        setLoading(false);
      } catch (error) {
        console.log('error');
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, community_id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await memberCommunities();
  //       setMemberList(response);
  //     } catch (error) {
  //       console.log("error");
  //     }
  //   };
  //   fetchData();
  // }, []);

  //community when login is there

  // const isAlreadyJoined = memberList?.some((member) => member.id === id);
  // const isAlreadyAdmin = communities?.some((admin) => admin._id === id);

  // const renderActionButton = () => {
  //   if (!isAlreadyJoined && !isAlreadyAdmin) {
  //     return (
  //       <Box sx={{ textAlign: "center" }}>
  //         <Typography sx={{ fontWeight: "700", fontSize: "19px" }}>
  //           Discover the Magic, Join for Free Today!
  //         </Typography>
  //         <Button
  //           variant="contained"
  //           sx={{
  //             borderRadius: "30px",
  //             background:
  //               "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
  //             textTransform: "capitalize",
  //             width: "140px",
  //             mt: 1,
  //           }}
  //           onClick={handleClickOpen}
  //         >
  //           I’ll Join
  //         </Button>
  //       </Box>
  //     );
  //   }
  // };

  const handleViewMap = () => {
    if (data && data?.location) {
      window.open(data?.location, '_blank');
    }
  };

  // console.log(data?.location, "data");

  const openSocialMediaProfile = (type: string) => {
    const socialLink = data?.socialLinks?.find((link) => link.type === type);
    if (socialLink) {
      const url = socialLink.value;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const appUrls: Record<string, string> = {
          facebook: `fb://facewebmodal/f?href=${url}`,
          youtube: `vnd.youtube://${url.split('https://www.youtube.com/').pop()}`,
          twitter: `twitter://user?screen_name=${extractTwitterHandle(url)}`,
          linkedin: `linkedin://company/${extractLinkedInId(url)}`,
          instagram: `instagram://user?username=${extractInstagramHandle(url)}`,
        };
        const deepLink = appUrls[type];
        if (deepLink) {
          window.location.href = deepLink;
          setTimeout(() => {
            window.open(url, '_blank');
          }, 1000);
        } else {
          window.open(url, '_blank');
        }
      } else {
        window.open(url, '_blank');
      }
    }
  };

  const extractTwitterHandle = (url: string) =>
    url.split('https://twitter.com/').pop()?.split('/')[0] || '';

  const extractLinkedInId = (url: string) =>
    url.split('https://www.linkedin.com/company/').pop()?.split('/')[0] || '';

  const extractInstagramHandle = (url: string) =>
    url.split('https://www.instagram.com/').pop()?.split('/')[0] || '';

  const defaultImages = [
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_4.jpeg',
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png',
  ];

  const images = data?.gallery?.length ? data.gallery : defaultImages;

  // const faq = data?.faq
  // console.log("data", data)

  const communityId = `${community_id}`;
  const communityUrl = `${USER_APP}/communities/${community_id}/about`;

  const handleButtonClick = () => {
    navigate('/home', { state: { community_id } });
    // dispatch(loadSelectedCommunity());
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <title>{metaConfig.title.aboutCommunity}</title>
          <meta name='description' content={metaConfig.description} />
          <meta name='format-detection' content='telephone=no' />
          <meta name='author' content={metaConfig.description} />
          <meta name='keywords' content={metaConfig.description} />
        </Helmet>

        <Grid
          container
          justifyContent='center'
          alignItems='center'
          height='100vh' // Set the height as needed
        >
          {loading && (
            <Grid item>
              <Stack>
                <Loader />
              </Stack>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
  return (
    <Grid container>
      <Grid item sm={8} xs={12} md={12} lg={12} xl={12}>
        <>
          <Box>
            <Box sx={{ maxWidth: { xs: 380, md: 635, lg: 800, xl: 1200 } }}>
              <Stack spacing={1}>
                {/* <Homecarousel /> */}
                {/* <img
                  src={
                    data?.banner
                  }
                  alt="Cover"
                  style={{
                    width: "100%",
                    height: 300,
                    maxHeight: 300,
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                /> */}
                <Box
                  component={'img'}
                  src={data?.banner}
                  alt=''
                  sx={{
                    width: '100%',
                    height: { xs: 200, md: 300 },
                    maxHeight: { xs: 200, md: 300 },
                    objectFit: 'cover',
                    borderRadius: '5px',
                  }}
                />
                {/* {renderActionButton()} */}
                {isAuthenticated && (
                  <SendRequestCommunityPreview
                    isOpen={isModalOpen}
                    handleClose={handleCloseModal}
                    logo={data?.logo ?? ''}
                    id={data?._id ?? ''}
                    title={data?.name ?? ''}
                    city={data?.city}
                    members={data?.members?.length || []}
                    message={data?.directorMessage ?? ''}
                    type={data?.type ?? ''}
                    description={data?.description ?? ''}
                    handleCopyLink={handleCopyLink}
                    copied={copied}
                    whatsappShareUrl={whatsappShareUrl}
                    facebookShareUrl={facebookShareUrl}
                    linkedinShareUrl={linkedinShareUrl}
                    twitterShareUrl={twitterShareUrl}
                    coverImageSrc={data?.banner ?? ''}
                    handleButtonClick={handleButtonClick}
                  />
                )}
                {data?.description && data.description.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{
                      // maxWidth: { xs: 370, md: 800 },
                      maxHeight: { xs: 370, md: 800 },
                      padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                      borderRadius: '12px !important', border: '1px solid #E7EBF1',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        mb: '10px',
                      }}
                    >
                      Know more about {data?.name}
                    </Typography>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '13px',
                        color: '#464646',
                        // Set max height and overflow for the scrolling effect
                        maxHeight: '170px',
                        overflowY: 'auto',
                        fontFamily: 'Montserrat',
                        marginBottom: 0,
                      }}
                    >
                      {data?.description}
                    </p>
                  </Card>
                )}

                {data?.services && data.services.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{ padding: { xs: '1.5vw 4vw', md: '1vw 3vw' }, borderRadius: '12px !important', border: '1px solid #E7EBF1', }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        marginBottom: '5px',
                      }}
                    >
                      {data?.name}’s Services
                    </Typography>
                    <br />
                    <AboutCarousel productData={data?.services || []} />
                  </Card>
                )}

                {data?.gallery && data?.gallery.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{ padding: { xs: '1.5vw 4vw', md: '1vw 3vw' }, borderRadius: '12px !important', border: '1px solid #E7EBF1', }}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#50A1CA',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        Gallery
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: '13px', md: '13px' },
                          color: '#000000',
                        }}
                      >
                        {data?.gallery?.length} Images
                      </Typography>
                    </Box>
                    <br />
                    <Stack
                      direction='row'
                      gap={1}
                      flexWrap='wrap'
                      m='auto'
                      sx={{ marginBottom: '10px', alignItems: 'flex-start' }}
                    >
                      <ImageGalleryCraft
                        images={images}
                        imageHeight={100}
                        imageWidth={100}
                      ></ImageGalleryCraft>
                    </Stack>
                  </Card>
                )}
                {data?.mission && data.mission.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{
                      // maxWidth: { xs: 370, md: 800 },
                      maxHeight: { xs: 370, md: 800 },
                      padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                      borderRadius: '12px !important', border: '1px solid #E7EBF1',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        mb: '10px',
                      }}
                    >
                      Mission
                    </Typography>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '13px',
                        color: '#464646',
                        // Set max height and overflow for the scrolling effect
                        maxHeight: '170px',
                        overflowY: 'auto',
                        marginBottom: 0,
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {data?.mission && data.mission.length > 0 && (
                        <>{data.mission}</>
                      )}
                    </p>
                  </Card>
                )}
                {data?.vision && data.vision.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{
                      // maxWidth: { xs: 370, md: 800 },
                      maxHeight: { xs: 370, md: 800 },
                      padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                      borderRadius: '12px !important', border: '1px solid #E7EBF1',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        marginBottom: '10px',
                      }}
                    >
                      Vision
                    </Typography>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '13px',
                        color: '#464646',
                        // Set max height and overflow for the scrolling effect
                        maxHeight: '170px',
                        overflowX: 'auto',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {data?.vision && data.vision.length > 0 && (
                        <>{data.vision}</>
                      )}
                    </p>
                  </Card>
                )}

                {data?.directorMessage && data.directorMessage.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{
                      // maxWidth: { xs: 370, md: 800 },
                      maxHeight: { xs: 370, md: 800 },
                      padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                      borderRadius: '12px !important', border: '1px solid #E7EBF1',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        marginBottom: '10px',
                      }}
                    >
                      Director’s Message
                    </Typography>

                    {/* <CardMedia
                      component="img"
                      image={usersData?.loggedInUser?.user?.avatar}
                      alt="Paella dish"
                      sx={{
                        width: "100%",
                        height: "auto", // Set height to auto to maintain aspect ratio
                        maxHeight: 150, // Set the maximum height
                        objectFit: "cover",
                        marginTop: "2vw",
                        borderRadius: "5px",
                      }}
                    /> */}

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '13px',
                        color: '#464646',
                        // Set max height and overflow for the scrolling effect
                        maxHeight: '170px',
                        // fontStyle: "italic",
                        overflowY: 'auto',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {data?.directorMessage}
                    </p>
                  </Card>
                )}

                {data?.teams && data.teams.length > 0 && (
                  <Card
                    elevation={0}
                    sx={{
                      padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                      borderRadius: '12px !important', border: '1px solid #E7EBF1',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        marginBottom: '10px',
                      }}
                    >
                      Meet our Team
                    </Typography>
                    <br />
                    <AboutCarouselTeem productData={data?.teams || []} />
                  </Card>
                )}

                {data?.faq && data.faq.length > 0 && (
                  <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#50A1CA',
                        fontFamily: 'Montserrat',
                        marginBottom: '5px',
                      }}
                    >
                      FAQ's
                    </Typography>
                    <Stack
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '6px',
                        mt: 2,
                        display: {
                          xs: '0',
                          sm: '600',
                          md: '900',
                          lg: '1200',
                          xl: '1536',
                        },
                      }}
                    >
                      <Stack>
                        {data?.faq?.map(
                          (f: any, index: Key | null | undefined) => (
                            <Accordion
                              elevation={0}
                              disableGutters={true}
                              sx={{
                                borderTop: '1px solid #00000030',
                                minHeight: 'auto',
                                flexShrink: 0,
                              }}
                              key={index}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ borderTop: 'red', padding: '0px' }}
                              >
                                <Typography
                                  sx={{
                                    top: 0,
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    color: '#575757',
                                    fontFamily: 'Montserrat',
                                  }}
                                >
                                  {f?.question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails sx={{ paddingBottom: '8px' }}>
                                <Typography
                                  sx={{
                                    top: 0,
                                    fontSize: '13px',
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                  }}
                                >
                                  {f?.answer}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                        <Divider
                          sx={{ height: '0.8px', backgroundColor: '#cccccc' }}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                )}

                {/* <Card elevation={0} sx={{ padding: "2vw 3vw" }}> */}

                <Card></Card>
                <Card
                  elevation={0}
                  sx={{
                    // maxWidth: { xs: 370, md: 800 },
                    padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                    borderRadius: '12px !important', border: '1px solid #E7EBF1',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '15px', md: '20px' },
                      fontWeight: '500',
                      color: '#50A1CA',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Get in Touch
                  </Typography>
                  {/* {(data?.fullAddress && data.fullAddress.length > 0) ||
                    (data?.city &&
                      data.city.length > 0 &&
                      data.zipcode &&
                      data.zipCode.length > 0 && ( */}
                  <>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        textDecoration: 'underline',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Coffee with us:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '13px',
                        color: '#565656',
                        fontFamily: 'Montserrat',
                        margin: '1vh 0vh',
                      }}
                    >
                      {data?.fullAddress}, {data?.city} - {data?.zipCode}
                    </Typography>
                  </>
                  {/* ))} */}
                  {/* {data?.location && data.location.length > 0 && ( */}
                  <Button
                    sx={{
                      border: '1px solid black',
                      color: 'black',
                      fontWeight: 600,
                      fontSize: '12px',
                      textTransform: 'capitalize',
                      mb: '10px',
                      fontFamily: 'Montserrat'
                    }}
                    onClick={handleViewMap}
                  >
                    View in Map
                  </Button>
                  {/* )} */}
                  <Box
                    sx={{
                      display: { xs: 'inline', md: 'flex' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      // marginTop: "2vw",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '15px',
                          color: '#000000',
                          margin: '1vh 0vh',
                          textDecoration: 'underline',
                          textDecorationColor: '#565656',
                        }}
                      >
                        Call us:
                      </Typography>
                      {data?.phoneNumber && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            color: '#565656',
                            margin: '1vh 0vh',
                          }}
                        >
                          <a
                            style={{ textDecoration: 'none', color: '#565656' }}
                            href={`tel:${data?.phoneNumber}`}
                          >
                            {data?.phoneNumber}
                          </a>
                        </Typography>
                      )}
                      {data?.mobileNumber ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            color: '#565656',
                            margin: '1vh 0vh',
                          }}
                        >
                          <a
                            style={{ textDecoration: 'none', color: '#565656' }}
                            href={`tel:${data?.mobileNumber}`}
                          >
                            {data?.mobileNumber}
                          </a>
                        </Typography>
                      ) : (
                        ' '
                      )}
                    </Box>

                    {data?.email && data.email.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '15px',
                            color: '#000000',
                            margin: '1vh 0vh',
                            textDecoration: 'underline',
                            textDecorationColor: '#565656',
                          }}
                        >
                          Write us:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            color: '#565656',
                            margin: '1vh 0vh',
                          }}
                        >
                          <a
                            style={{ textDecoration: 'none', color: '#565656' }}
                            href={`mailto:${data?.email}`}
                          >
                            {data?.email}
                          </a>
                        </Typography>
                      </Box>
                    )}
                    {/* <Box>
                      <QrCode id={communityId} url={communityUrl} />
                    </Box> */}
                  </Box>
                </Card>

                <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '15px', md: '20px' },
                      fontWeight: '500',
                      // color: "#50A1CA",
                      fontFamily: 'Montserrat',
                      marginBottom: { xs: '1vw', md: '.5vw' },
                    }}
                  >
                    We are social
                  </Typography>
                  <Tooltip title='Facebook'>
                    <IconButton onClick={() => openSocialMediaProfile('facebook')}>
                      <FacebookIcon
                        className='facebook'
                        sx={{
                          color: '#464646',
                          fontSize: { xs: '15px', md: '20px' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Youtube'>
                    <IconButton onClick={() => openSocialMediaProfile('youtube')}>
                      <YouTubeIcon
                        className='youtube'
                        sx={{
                          color: '#464646',
                          fontSize: { xs: '15px', md: '20px' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Twitter'>
                    <IconButton onClick={() => openSocialMediaProfile('twitter')}>
                      <TwitterIcon
                        className='twitter'
                        sx={{
                          color: '#464646',
                          fontSize: { xs: '15px', md: '20px' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Linkedin'>
                    <IconButton onClick={() => openSocialMediaProfile('linkedin')}>
                      <LinkedInIcon
                        className='linkdin'
                        sx={{
                          color: '#464646',
                          fontSize: { xs: '15px', md: '20px' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Instagram'>
                    <IconButton onClick={() => openSocialMediaProfile('instagram')}>
                      <InstagramIcon
                        className='instagram'
                        sx={{
                          color: '#464646',
                          fontSize: { xs: '15px', md: '20px' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Card>
              </Stack>
            </Box>
          </Box>
        </>
      </Grid>
      {/* <Grid item xs={6} md={4} lg={4.9} xl={4}>
        <HomeRightBar />
      </Grid> */}
    </Grid>
  );
};

export default About;
