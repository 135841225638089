import { Card, CardMedia, Typography, } from "@mui/material";
import {


  Paper,
  Stack,
} from "@mui/material";


// Use lowercase for the function name
export const NoPlans = () => {


  return (
    <>
      <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              image={require("./../../assets/icons/svg-icons/noPlans.png")}
              sx={{
                objectFit: "cover",
                width: "100%", // Set the width to 100% to fill the container
                height: { xs: "40vh", md: "50vh" }, // Set the height to 100% to fill the container
              }}
            />
          </Card>
          <Typography sx={{ color: '#777777', fontSize: { xs: '13px', md: '15px' }, fontWeight: '500', fontFamily: 'Montserrat' }}>No Plans Available</Typography>


        </Stack>
      </Paper>
    </>
  );
};
