import React, { useState, useContext, useRef } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth.context';
import { StyledLogin } from './Login.styles';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { useSnackbar } from 'notistack';


export const AutoLogin = () => {

    const { enqueueSnackbar } = useSnackbar();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [emailId, setEmailId] = useState('');

    const [loader, setLoader] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value);
    };

    const handleLoginResponse = (response: any) => {
        setLoader(true)
        if (response?.status === 200) {
            const { user, joinedCommunities, notPaidCommunities } = response?.data;
            console.log(notPaidCommunities, "notPaidCommunities");
            if (notPaidCommunities) {
                navigate('/joined-communities');
                enqueueSnackbar('Login Success', {
                    variant: 'success',
                });
            } else if (joinedCommunities && joinedCommunities.length > 0) {
                navigate('/home', { state: { id: user?.community } });
                enqueueSnackbar('Login Success', {
                    variant: 'success',
                });
            } else if (!joinedCommunities || joinedCommunities.length === 0) {
                navigate('/explore-communities', {
                    state: { id: 2, data: user },
                });
                enqueueSnackbar('Login Success', {
                    variant: 'success',
                });
            } else if (user?.community) {
                navigate('/dashboard', { state: { id: 3, data: user } });
            }
        } else if (response?.status === 403) {
            console.log('Inactive user');
        } else if (response?.status === 404) {
            enqueueSnackbar('User Not Found', {
                variant: 'error',
            });
        }
        setLoader(false)
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const autoEmailLogin = async (): Promise<void> => {
        if (!emailId) {
            enqueueSnackbar('Please enter email ID', { variant: 'error' });
            return;
        }
        try {
            const autoLoginResponse = await authContext.autoLogin('', emailId,);
            handleLoginResponse(autoLoginResponse);
        } catch (error) {
            console.error('Auto Email Login Error:', error);
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.loginPage}</title>
                <meta name="description" content={metaConfig.description} />
            </Helmet>
            <StyledLogin>
                <Grid container>
                    <Grid
                        item
                        md={7}
                        sx={{
                            background: 'linear-gradient(to right bottom, #50a1ca,#469EA7)',
                            display: { xs: 'none', md: 'block' },
                        }}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%', width: '100%' }}
                        >
                            <Box
                                component="img"
                                src={require('./../../../assets/images/registerbckg.png')}
                                alt=""
                            />
                            <Stack alignItems="center" sx={{ mt: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: '28px',
                                        fontWeight: '700',
                                        color: '#ffffff',
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    Create or Join Communities
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Stack
                            sx={{
                                height: '100vh',
                                justifyContent: 'center',
                            }}
                            alignItems="center"
                        >
                            <Box ref={inputRef}
                                component="form"
                                sx={{
                                    width: '328px',
                                    textAlign: 'center',
                                }}
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <Box
                                    component="img"
                                    src={require('./../../../assets/images/Communn-new-logo.png')}
                                    alt=""
                                    width="75%"
                                />

                                <Typography sx={{ color: '#000000', fontSize: '14px' }}>
                                    New user? Enter mobile number please.
                                </Typography>

                                <Stack spacing={2} sx={{ pt: 2, backgroundColor: 'white' }}>
                                    <TextField
                                        inputRef={inputRef}
                                        sx={{ backgroundColor: 'white', borderRadius: '30px' }}
                                        size="small"
                                        label="Enter EmailID"
                                        fullWidth
                                        value={emailId}
                                        onChange={handleEmailChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '100px',
                                                backgroundColor: '#ffffff',

                                            },
                                        }}
                                    />


                                    <Button
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            borderRadius: '30px',
                                            background: 'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                                            textTransform: 'capitalize',
                                            ':hover': {
                                                background: 'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                                            },
                                        }}
                                        onClick={autoEmailLogin}

                                    >
                                        {loader ? (
                                            <CircularProgress size={24} sx={{ color: 'white' }} />
                                        ) : (
                                            <>
                                                Login
                                            </>
                                        )}
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledLogin>
        </>
    );
};

export default AutoLogin;
