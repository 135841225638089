import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import React, { } from 'react';
import { AvailableTime, IAppointment } from '../../models/appointments.models';
import { IPaymentList } from '../../models/payment.model';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
// import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Logo from './../../assets/images/Communn-favicon.png';
import paymentSuccess from '../../assets/lotties/payment-success.json';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { SubNavbar } from '../Layout/components/SubNavbar';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

interface IAppointmentDetails {
  transaction?: IPaymentList | null;
  appointmentDetails?: IAppointment[];
  selectedTime?: AvailableTime | undefined;
  selectedDate?: Date;
}

const Confirmation: React.FC<IAppointmentDetails> = ({
  appointmentDetails,
  selectedTime,
  selectedDate,
}) => {
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  // console.log(singleCommunity, "singleCommunity")
  // console.log('Appointment Details:', appointmentDetails);
  // console.log('Selected Time:', selectedTime);
  // console.log('Selected Date:', selectedDate);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/appointments');
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, [navigate]);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  return (
    <>
      {appointmentDetails?.map((appointment, index) => {
        return (
          <>
            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 2 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
              <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                navigate('/appointments');
              }} />
              <Typography
                sx={{
                  fontSize: { xs: '16px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Appointments
              </Typography>
            </Stack>

            <SubNavbar>
              <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
                <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} gap={1}>
                  <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/appointments');
                    }} />
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', md: '18px' },
                      fontWeight: 600,
                      color: '#000000',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '18px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Appointments
                    </Typography>
                  </Typography>
                  <Button
                    disabled
                    variant='contained'
                    sx={{
                      backgroundColor: '#3E3E3E',
                      borderRadius: '30px',
                      boxShadow: 'none',
                      textTransform: 'capitalize',
                      color: '#3E3E3E',
                      height: { xs: '2.5vh', md: '3vh' },
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '10px', md: '12px' },
                    }}
                  >
                    {appointment?.title}
                  </Button>
                </Stack>

              </Stack >
            </SubNavbar>
            <Box
              sx={{
                mt: 1,
                borderRadius: '12px',
              }}
            >
              <Stack sx={{ borderRadius: '5px' }}>
                <Grid container gap={3} sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' } }} >
                  <Grid
                    item
                    xs={12}
                    md={7.8}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '12px',
                      border: '1px solid #D4DDED',
                      p: 2,
                    }}
                  >
                    <Stack
                      direction='row'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ py: 2 }}
                    >
                      <Stack
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        gap={1}
                      >
                        <Avatar
                          src={singleCommunity?.logo}
                          sx={{
                            objectFit: 'cover',
                            border: '1px solid #E7EBF1',
                            p: 0.5,
                          }}
                        />
                        <Typography
                          sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                          }}
                        >
                          {singleCommunity?.name}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Paper
                      sx={{
                        borderRadius: '10px',
                        display: { xs: 'none', md: 'block' },
                      }}
                      elevation={0}
                    >
                      <Grid
                        container
                        spacing={3}
                        p={3}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <Stack spacing={2}>
                            <Stack
                              direction='row'
                              spacing={1}
                              alignItems='center'
                            >
                              <CalendarTodayOutlinedIcon
                                sx={{ fontSize: '20px', color: 'gray' }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000000',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                {selectedDate?.toDateString()} |{' '}
                                {convertTo12HourFormat(
                                  selectedTime?.startTime ?? ''
                                )}{' '}
                                -{' '}
                                {convertTo12HourFormat(
                                  selectedTime?.endTime ?? ''
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              direction='row'
                              spacing={1}
                              alignItems='center'
                            >
                              <LocalOfferOutlinedIcon
                                sx={{ fontSize: '20px', color: 'gray' }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000000',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                {appointment?.pricing
                                  ? ` ₹${appointment?.pricing}`
                                  : 'Free'}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Stack spacing={2}>
                            <Stack
                              direction='row'
                              spacing={1}
                              alignItems='center'
                            >

                              < PinDropOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                              <Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                {appointment?.location}
                              </Typography>
                            </Stack>
                            <Stack
                              direction='row'
                              spacing={1}
                              alignItems='center'
                            >
                              <Person2OutlinedIcon
                                sx={{ fontSize: '20px', color: 'gray' }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000000',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                {appointment?.createdBy?.firstName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Divider />

                    <Stack
                      direction='row'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ py: 2 }}
                    >
                      <Stack
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Typography
                          sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                          }}
                        >
                          Booking Summary
                        </Typography>
                      </Stack>

                    </Stack>
                    <Stack sx={{ p: { xs: 1, md: 1 }, borderRadius: '5px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                          <Box
                            component={'img'}
                            src={Logo}
                            width='100%'
                            height={50}
                            sx={{ borderRadius: '10px', objectFit: 'contain' }}
                          />
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontFamily: 'Montserrat',
                              fontSize: { xs: '20px', md: '16px' },
                              color: '#000000',
                            }}
                          >
                            {appointment?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: '#646464',
                              fontFamily: 'Montserrat',
                              fontWeight: 500,
                            }}
                          >
                            {appointment?.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={3.8}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '12px',
                      border: '1px solid #D4DDED',
                      p: 2,
                    }}
                  >
                    <Stack
                      direction='row'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{}}
                    >

                      <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      display='flex'
                      flexDirection={'column'}
                      justifyContent='center'
                      alignItems='center'
                      sx={{ py: 1 }}
                    >

                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: '#000000',
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          textAlign: 'center'
                        }}>
                        Appointment Booked!
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: '#000000',
                          fontFamily: 'Montserrat',
                          fontWeight: 500,
                          textAlign: 'center'
                        }}>
                        A confirmation email has been sent to your registered mail
                      </Typography>
                    </Stack>
                    <Divider />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </>
        );
      })}
    </>
  );
};
export default Confirmation;
