import React, { useContext } from "react";
import {
  Box,
  Button,
  Card,
  Stack,
  // SelectChangeEvent,
  DialogContent,
  IconButton,
} from "@mui/material";
// import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Dialog, { } from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import LoginPopUp from "../Auth/Login/LoginPopUp";
import { AuthContext } from "../../contexts/Auth.context";
import { ADMIN_APP_BASE_URL } from "../../configurations/url.config";
// import store from "../../store";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const HomeCreateCommunity = () => {
  const { isAuthenticated } = useContext(AuthContext);

  // const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  // const navigate = useNavigate();



  // const handleCreateNew = () => {
  //   navigate("/communities/add");
  // };


  // const usersData = store.getState();

  // const [userId] = useState(usersData.loggedInUser.user?.id);



  const navigateToAdminFlowCreateCommunnity = () => {
    const token = localStorage.getItem("access-token");
    const url = `${ADMIN_APP_BASE_URL}/login?token=${token}`;
    window.open(url, '_blank');
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
  //   setMaxWidth(
  //     // @ts-expect-error autofill of arbitrary value is not handled.
  //     event.target.value
  //   );
  // };

  // const handleFullWidthChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setFullWidth(event.target.checked);
  // };

  return (
    <>
      <Stack >
        <Card sx={{ padding: "1vw" }} elevation={0}>

          {isAuthenticated ? (
            // <Button
            //   fullWidth
            //   sx={{
            //     padding: "0.5vw",
            //     // textTransform:'capitalize',
            //     backgroundColor: "#50A1CA",
            //     color: "white",
            //     "&:hover": { backgroundColor: "#50A1CA" },
            //   }}
            //   onClick={handleCreateNew}
            // >
            //   Create Community
            // </Button>
            <Button
              fullWidth
              sx={{
                padding: "0.5vw",
                // textTransform:'capitalize',
                backgroundColor: "#50A1CA",
                color: "white",
                fontFamily: 'Montserrat',
                "&:hover": { backgroundColor: "#50A1CA" },
              }}
              onClick={() => navigateToAdminFlowCreateCommunnity()}
            >
              Create Community
            </Button>
          ) : (
            <Button
              fullWidth
              sx={{
                padding: "0.5vw",
                backgroundColor: "#50A1CA",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#3B9B7F" },
              }}
              onClick={handleClickOpen}
            >
              Join Community
            </Button>
          )}
        </Card>

        <React.Fragment>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
          >
            <Box
              component={"img"}
              src={require("./../../../src/assets/images/LoginMbg.png")}
              sx={{
                height: "100px",
                width: "100%",
                objectFit: "fill",
                display: { xs: "block", md: "block" },
              }}
              alt=""
            ></Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
            <DialogContent>
              <LoginPopUp />
            </DialogContent>
          </BootstrapDialog>
        </React.Fragment>
      </Stack>
    </>
  );
};

export default HomeCreateCommunity;
