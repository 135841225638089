import {
  Avatar,
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICommunity } from "../../models/communities.modal";
import { BASE_URL } from "../../configurations/url.config";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { SubNavbar } from "../Layout/components/SubNavbar";
import { AdminPerson } from "../../assets/icons";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getStaticValue } from "../../utils/StaticValues";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { metaConfig } from "../../utils/constants";
interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: number;
  emailId: string;
  role: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  avatar: string;
  slug: string;
}

const Members = () => {
  const [loading, setLoading] = useState(true);

  const formatDateRelative = (dateString: string): string => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const yearsDifference = Math.floor(daysDifference / 365);

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "Yesterday";
    } else if (yearsDifference === 1) {
      const extraDays = daysDifference % 365;
      return `1 year and ${extraDays} day${extraDays !== 1 ? "s" : ""} ago`;
    } else if (yearsDifference > 1) {
      return `${yearsDifference} years ago`;
    } else {
      return `${daysDifference} days ago`;
    }
  };

  const [data, setData] = useState<ICommunity | null>(null);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?._id;
  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (selectedCommunityId !== null) {
          const response = await axios.get(
            `${BASE_URL}/communities/${selectedCommunityId}/home`
          );
          setData(response.data.community);
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  const navigate = useNavigate();
  const handlenavigateUser = (id: string) => {
    navigate(`/profile/posts/${id}`);
  };


  // console.log(data?.members, "UserInfo")

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const mobileViewHeight = 370; // Define the height for mobile view
  const desktopViewHeight = 380; // Define the height for desktop view

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  return (
    // <Box>
    <>
      <Helmet>
        <title>{metaConfig.title.members}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Stack direction={"column"} spacing={1} >
        <SubNavbar>
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 600,
                color: "#000000",
                fontFamily: "Montserrat",
                pb: 1,
              }}
            >
              Members
            </Typography>
          </Stack>
        </SubNavbar>
      </Stack>
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Stack>
            <Paper
              elevation={0}
              sx={{
                height: "55vh",
                // mt: 2,
                // ml: { xs: 0, md: 4},
                // maxWidth: 700,
                borderRadius: '12px !important', border: '1px solid #E7EBF1',

              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 2 }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#1A1A1A",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Total Members : {data?.members?.length}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <Search
                    sx={{
                      // width: "200px",
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search> */}
                  {/* <TextField
                  placeholder="Search…"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton color="primary">
                          <SearchIcon1  />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                </Box>
              </Stack>
              <Divider sx={{ marginTop: "1px" }} />

              <Paper elevation={0} sx={{
                maxHeight: { xs: "65vh", md: "78vh" },
                height: { xs: "65vh", md: "78vh" }, pb: { xs: 0, md: 1 },

              }}>
                <SimpleBar style={{ height: "100%" }}>
                  {loading ? (
                    // Show loader while data is being fetched
                    <Loader />
                  ) : (
                    // Show data when it is available
                    <>
                      {data?.members?.sort((a: { user: IUser }, b: { user: IUser }) => {
                        if (a && a?.user && a?.user._id === loggedInUserData.user.id) return -1; // Logged-in user comes first
                        if (b && b?.user && b?.user._id === loggedInUserData.user.id) return 1;
                        return 0;
                      }).map(
                        (item: {
                          user: IUser;
                          createdAt: string;
                          addedBy: string;
                          community: ICommunity;
                          slug: string;
                        }) => (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  alt="profile"
                                  src={item?.user?.avatar}
                                  sx={{
                                    backgroundColor: "#50A1CA",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlenavigateUser(item?.user?._id)
                                  }
                                >
                                  {item?.user?.avatar
                                    ? null
                                    : item?.user?.firstName?.charAt(0) +
                                    "" +
                                    item?.user?.lastName?.charAt(0)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: "13px", fontWeight: 400, color: "#000000", fontFamily: "Montserrat", }}>
                                      {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                    </Typography>
                                    {item?.slug === "Owner/Director" && (
                                      <AdminPerson sx={{ ml: "5px", color: 'red' }} />
                                    )}
                                    {/* {item?.user?._id === item?.community?.createdBy?._id && (
                                      <AdminPerson sx={{ ml: "5px" }} />
                                    )} */}
                                  </Box>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography

                                      sx={{ display: "inline", fontSize: "11px", fontFamily: "Montserrat", }}
                                      component="span"
                                      variant="body2"
                                      color="text.danger"

                                    >
                                      {`${item?.slug ? item?.slug : getStaticValue(item?.user.role)} | Connected ${formatDateRelative(item?.createdAt)}`}
                                    </Typography>
                                  </React.Fragment>
                                }
                                sx={{
                                  // Adding custom style for the primary text
                                  "& .MuiListItemText-primary": {
                                    fontSize: "13px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 400,
                                    color: "#000000",
                                    mt: "5px",

                                  },
                                  "& .MuiListItemText-secondary": {
                                    marginTop: "-5px", // Adjust the top margin as needed
                                  },
                                }}
                              />
                            </ListItem>
                            <Divider />
                          </>
                        )
                      )}
                    </>
                  )}

                </ SimpleBar >
              </Paper>

            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Members;
