import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IPaymentList, IPaymentRequest } from '../../models/payment.model';
import { usePayment } from '../../hooks/usePayment';
import { useSelector } from 'react-redux';
import { getStaticValue } from '../../utils/StaticValues';
import { NoPayment } from '../AlternatePages/noPaymentTrans';
import { useNavigate } from 'react-router-dom';
import CustomPayModel from './CustomPayPopUp';
import PaymentSuccess from './PaymentSuccessPopUp';
import PaymentFailed from './PaymentFailedPopUp';
import { SearchIcon1 } from '../../assets/icons';
import { Loader } from '../Loader/Loader';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { NoPaymentRequests } from '../AlternatePages/NoPaymentRequest';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { Close } from '@mui/icons-material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
// import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
const UserPayments = () => {
  const [viewAll, setViewAll] = useState(false);
  // const [viewAll1, setViewAll1] = useState(false);
  // const [viewAll2, setViewAll2] = useState(false);
  const [expanded] = useState(false);
  const accordionSummaryStyle = {
    backgroundColor: expanded ? '#E3F0F7' : 'white',
  };
  const [expandedItems, setExpandedItems] = React.useState<Array<boolean>>([]);
  const handleAccordionChange = (index: number) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);
  // console.log(loggedInUserData);
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  console.log('payment request sent');

  // console.log(selectedCommunity?.selectedCommunity?.collectSubscription, "selectedCommunity");

  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  const _id = selectedCommunity?.selectedCommunity?._id;
  /////paymentsList api call////////
  const {
    initiatePaymentByIds,
    getPaymentStatusById,
    CustomPay,
    userPaymentRequest,
    userPaymentTransactionList,
    paymentsDue,
  } = usePayment();
  const [paymentsList, setPaymentList] = useState<IPaymentList[]>([]);
  const [transaction, setTransaction] = useState<IPaymentList>();
  const [loading, setIsLoading] = React.useState(false); // State for loading indicator
  const [customloading, setCustomLoading] = React.useState(false);
  const [filteredPaymentsList, setFilteredPaymentsList] = useState<
    IPaymentList[]
  >([]);
  const [reason, setReason] = React.useState('');
  const [amount, setAmount] = React.useState<any>('');
  const [paymentRequest, setPaymentRequest] = useState<IPaymentRequest[]>();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [paymentUpdated, setPaymentUpdated] = useState(false);
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [dueList, setDueList] = useState([]);
  const [dueLoading, setDueLoading] = useState(true);

  // useEffect(() => {
  //   dueDate();
  // }, [dueList]);

  // const dueDate = () => {
  //   dueList?.map((item, index) => {
  //     console.log(item, "dueList");
  //   });
  // };

  // console.log(paymentsList, "paymentsList")

  const formatDate = (dateString: string | number | Date) => {
    const originalDate = new Date(dateString);
    return format(originalDate, 'do MMM. yyyy ');
  };

  const isExpired = (dueList: string | number | Date) => {
    const currentDate = new Date();
    return new Date(dueList) < currentDate;
  };
  // console.log(dueList, "isExpired")

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const communityPayments = await userPaymentTransactionList();
      if (communityPayments) {
        setPaymentList(communityPayments);
        setFilteredPaymentsList(communityPayments);
        setIsLoading(false);
      } else {
        setFilteredPaymentsList([]);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, paymentUpdated]);

  React.useEffect(() => {
    const fetchPaymentRequest = async () => {
      setRequestLoading(true); // Set loading state to true when fetching data
      const requests = await userPaymentRequest(
        selectedCommunityId,
        loggedInUserData?.user?.id
      );
      // Set loading state to false after data is fetched
      if (requests) {
        setRequestLoading(false);
        setPaymentRequest(requests);
      } else {
        setRequestLoading(false);
        setPaymentRequest([]);
      }
    };
    fetchPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, requestUpdate]);
  React.useEffect(() => {
    const fetchDuePayments = async () => {
      // Set loading state to true when fetching data
      setDueLoading(true);
      const requests = await paymentsDue();
      // Set loading state to false after data is fetched
      setDueLoading(false);
      if (requests) {
        // setRequestLoading(false);
        setDueList(requests?.dues);
      } else {
        // setRequestLoading(false);
        setDueList([]);
      }
    };
    fetchDuePayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(dueList);
  const [retryLoadingStates, setRetryLoadingStates] = useState<boolean[]>(
    Array(filteredPaymentsList?.length).fill(false)
  );
  const [loadingStates, setLoadingStates] = useState<boolean[]>(
    Array(dueList?.length).fill(false)
  );
  /////

  ///////Reinitiate Payment Api///////

  const navigate = useNavigate();
  const [community, setCommunity] = useState('');
  const [plan, setplan] = useState('');
  enum PaymentStatus {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    USERCANCELLED = 'USERCANCELLED',
  }
  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    setAmount(transaction?.amount);
    setReason(transaction?.udf4);
    // console.log(transaction);
    // Set the width and height based on screen dimensions
    const width = Math.min(1000, screenWidth); // Set a maximum width of 900
    const height = Math.min(1000, screenHeight); // Set a maximum height of 900
    // Calculate the position to center the window
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;
    const windowRef = window.open(
      response.url,
      // "_self",
      `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
    );
    const intervalRef = setInterval(async () => {
      const paymentStatus = await getPaymentStatusById(tnxId);
      if (paymentStatus && paymentStatus.length > 0) {
        clearInterval(intervalRef);
        windowRef?.close();
        if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
          setSuccessOpen(true);
        } else {
          setFailureOpen(true);
        }
      }
    }, 1000);
  };

  // const itemArr2 = [1, 2, 3, 4, 5, 6, 7];
  // re initiate api call
  const handleRetryClick = async (
    communityId: string,
    planId: string,
    i: any,
    type: string
  ) => {
    setCommunity(communityId);
    setplan(planId);
    if (type === '') {
      setRetryLoadingStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[i] = true; // Set loading state for this item to true
        return newStates;
      });
    } else {
      setLoadingStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[i] = true; // Set loading state for this item to true
        return newStates;
      });
    }
    const currentDueDate = '24/09/2025';
    const amount = '100';
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      currentDueDate,
      amount
    );
    if (type === '') {
      setRetryLoadingStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[i] = false;
        return newStates;
      });
    } else {
      setLoadingStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[i] = false;
        return newStates;
      });
    }
    paymentResponse(response);
  };

  // UseEffect for  Success and Failed Model Open
  const [timer, setTimer] = useState(5);

  ////payment serch filter
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  //  transaction search
  useEffect(() => {
    const filteredList = paymentsList.filter((item: IPaymentList) =>
      [
        item?.amount,
        item?.status,
        item?.bank_name,
        item?.cardnum,
        item?.addedon,
        item?.txnid,
        item?.udf2?.name,
        item?.bank_ref_num,
        item?.dueDate,
        item?.udf3?.name,
      ].some((prop) =>
        prop?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    );

    // console.log(filteredList, "item");

    // Sort filtered list by addedon in descending order
    const sortedFilteredList = filteredList.sort((a, b) => {
      const dateA = a.addedon ? new Date(a.addedon) : null;
      const dateB = b.addedon ? new Date(b.addedon) : null;

      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      }

      if (dateA === null && dateB === null) {
        return 0; // If both are null, consider them equal
      } else if (dateA === null) {
        return 1; // If only dateA is null, consider dateB to be "greater"
      } else {
        return -1; // If only dateB is null, consider dateA to be "greater"
      }
    });

    setFilteredPaymentsList(sortedFilteredList);
  }, [paymentsList, searchQuery]);

  ///// time converstion fucnction for date
  const formatDateRelative = (dateString: string): string => {
    const inputDate = new Date(dateString);
    inputDate.setHours(inputDate.getHours() + 5);
    inputDate.setMinutes(inputDate.getMinutes() + 30);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    if (secondsDifference < 60) {
      return 'Just Now';
    } else if (minutesDifference < 60) {
      return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''
        } ago`;
    } else if (hoursDifference < 24) {
      return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    } else if (daysDifference === 1) {
      return 'Yesterday';
    } else if (daysDifference < 7) {
      return `${daysDifference} days ago`;
    } else {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return inputDate.toLocaleDateString(undefined, options);
    }
  };

  //////PaymentPopup state and Functions/////
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [customPay, setCustomPay] = React.useState(false);
  const [bank, setBank] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);

  const [readonly, setReadOnly] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [mode, setMode] = useState(false);
  const handleSuccessClose = () => {
    setRequestUpdate(true);
    setTimer(5);
    navigate('/payments');
    setSuccessOpen(false);
    setPaymentUpdated(!paymentUpdated);
    setRequestId('');
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate('/payments');
    setFailureOpen(false);
    setPaymentUpdated(!paymentUpdated);
    setRequestId('');
    setMode(false);
    setReadOnly(false);
    setCommunity('');
    setplan('');
  };
  const handleCustomPayOpen = () => {
    setCustomPay(true);
  };
  const handleCustomPayOpenforPaymentRequest = (
    amount: any,
    reason: any,
    Id: string
  ) => {
    setMode(true);
    setRequestId(Id);
    setCustomPay(true);
    setReadOnly(true);
    setAmount(amount);
    setReason(reason);
  };

  const handleCustomPayClose = () => {
    // setRequestId("");
    setMode(false);
    setCustomPay(false);
    setReason('');
    setAmount(0);
    setReadOnly(false);
  };
  const handleBankPopUpClose = () => {
    // setRequestId("");
    setBank(true);
  };

  const handleAmountChange = (newValue: any) => {
    setAmount(newValue);
  };
  const handleResonChange = (newValue: string) => {
    setReason(newValue);
  };
  // custom pay api handle submit
  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // if (requestId) {
      //   formData.append("amount", amount.toString());
      // } else {
      //   formData.append("amount", amount.toString() + ".00");
      // }

      // const regex = /\.(\d{2})$/;
      // if (requestId) {
      //   formData.append("paymentRequestId", requestId);
      // }

      // if (regex.test(amount)) {
      //   formData.append("amount", amount);
      // } else {
      //   formData.append("amount", amount.toString() + ".00");
      // }

      // const regex = /\.(\d{2})$/;
      // if (requestId) {
      //   formData.append("paymentRequestId", requestId);
      // }
      // if (regex.test(amount)) {
      //   formData.append("amount", amount);
      // } else {
      //   formData.append("amount", `${amount}.00`);
      // }
      // formData.append("description", reson);
      setCustomLoading(true);
      const regex = /\.(\d{2})$/;
      if (requestId) {
        formData.append('paymentRequestId', requestId);
      }
      if (regex.test(amount)) {
        formData.append('amount', amount);
      } else if (/\.\d$/.test(amount)) {
        formData.append('amount', `${amount}0`);
      } else {
        formData.append('amount', `${amount}.00`);
      }
      formData.append('description', reason);
      // Check if _id exists and is not null
      if (_id) {
        const updateResponse = await CustomPay(_id, formData);

        paymentResponse(updateResponse);
      }
    } finally {
      setReadOnly(false);
      //  setRequestId("");
      setCustomLoading(false);
      setAmount(0);
      setReason('');
      setCustomPay(false);
    }
  };
  //Payment  request Sorting
  const sortedRequest = paymentRequest?.sort((a, b) => {
    const dateB = new Date(b.updatedAt).getTime();
    const dateA = new Date(a.updatedAt).getTime();
    return dateB - dateA;
  });
  // console.log(filteredPaymentsList);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const mobileViewHeight =
    paymentRequest && paymentRequest.length > 0 ? '65' : '65';
  const desktopViewHeight =
    paymentRequest && paymentRequest.length > 0 ? '41.5' : '80';

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.payments}</title>
        <meta name='description' content={metaConfig.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='author' content={metaConfig.author} />
        <meta name='keywords' content={metaConfig.keywords} />
      </Helmet>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' }, // Corrected the typo here
        }}
      >
        <Grid item xs={12} md={8.4} xl={8.4} lg={8.5} sm={8.4}>
          <Stack spacing={1}>
            {paymentRequest && paymentRequest.length > 0 && (
              <Paper sx={{ p: 1, backgroundColor: '#FFF1F1' }} elevation={0}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography
                      sx={{
                        color: '#B72626',
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '15px', md: '15px' },
                        fontWeight: 500,
                      }}
                    >
                      Payment Request
                    </Typography>{' '}
                    {paymentRequest && paymentRequest.length > 0 && (
                      <Typography
                        sx={{
                          color: '#B72626',
                          fontWeight: 500,
                          fontSize: '15px',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        ({paymentRequest.length})
                      </Typography>
                    )}
                  </Stack>

                  {viewAll ? (
                    <Button
                      variant='text'
                      size='small'
                      sx={{
                        textTransform: 'capitalize',
                        color: '#464646',
                        fontWeight: '600',
                        fontSize: '11px',
                        display:
                          paymentRequest && paymentRequest.length <= 4
                            ? 'none'
                            : 'block',
                      }}
                      onClick={() => setViewAll(false)}
                    >
                      View less
                    </Button>
                  ) : (
                    <Button
                      size='small'
                      variant='text'
                      sx={{
                        textTransform: 'capitalize',
                        color: '#464646',
                        fontWeight: '600',
                        fontSize: '11px',
                        cursor: 'pointer',
                        display:
                          paymentRequest && paymentRequest.length <= 4
                            ? 'none'
                            : 'block',
                      }}
                      disabled={paymentRequest && paymentRequest?.length < 5}
                      onClick={() => setViewAll(true)}
                    >
                      View More
                    </Button>
                  )}
                </Stack>
                <Divider style={{ background: '#FFCFCF' }}></Divider>
                <SimpleBar style={{ height: '235px' }} autoHide={false}>
                  {requestLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {paymentRequest?.length === 0 && !requestLoading ? (
                        <Stack
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {/* <NoPayment /> */}
                          <NoPaymentRequests />
                        </Stack>
                      ) : (
                        sortedRequest
                          ?.slice(0, viewAll ? sortedRequest?.length : 4)
                          .map((item: IPaymentRequest, i: number) => (
                            <>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                sx={{ pt: 1, pb: 0.5 }}
                                onClick={() =>
                                  handleCustomPayOpenforPaymentRequest(
                                    item?.amount,
                                    item?.description,
                                    item?._id
                                  )
                                }
                              >
                                <Stack>
                                  <Typography
                                    sx={{
                                      display: 'inline',
                                      fontSize: '13px',
                                      fontWeight: '400',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    {item?.createdBy?.firstName}{' '}
                                    {item?.createdBy?.lastName} |{' '}
                                    {formatDateRelative(item?.updatedAt)}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: 'inline',
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    {item?.description?.length > 100
                                      ? item?.description?.substring(0, 100) +
                                      '...'
                                      : item?.description}{' '}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction={'column'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  spacing={0.3}
                                  sx={{ mr: 2 }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '12px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    ₹{item?.amount}
                                  </Typography>
                                  <Button
                                    variant='outlined'
                                    size='small'
                                    onClick={() => {
                                      handleCustomPayOpenforPaymentRequest(
                                        item.amount,
                                        item.description,
                                        item._id
                                      );
                                      setMode(true);
                                      // setBank(true)
                                    }}
                                    sx={{
                                      backgroundColor: '#50A1CA',
                                      textTransform: 'none',
                                      borderColor: '#FFF1F1',
                                      fontSize: '10px',
                                      color: '#ffffff',
                                      boxShadow: 'none',
                                      '&:hover': {
                                        backgroundColor: '#50A1CA',
                                        borderColor: '#FFF1F1',
                                        boxShadow: 'none',
                                      },
                                    }}
                                  >
                                    Pay Now
                                  </Button>
                                </Stack>
                              </Stack>
                              <Divider
                                style={{ background: '#FFCFCF' }}
                              ></Divider>
                            </>
                          ))
                      )}
                    </>
                  )}
                </SimpleBar>
              </Paper>
            )}
            <Paper elevation={0} sx={{ p: 1, borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontWeight: '600',
                    fontSize: { xs: '14px', md: '16px' },
                    fontFamily: 'Montserrat',
                  }}
                >
                  Recent Transactions
                </Typography>
                <TextField
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder='Search'
                  id='outlined-start-adornment'
                  size='small'
                  sx={{
                    height: { xs: '3.5vh', md: '4.5vh' },
                    width: { xs: '35vw', md: '25vw' },
                    fontSize: '13px',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ mt: 1 }}>
                        <SearchIcon1 />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: '20px',
                      height: { xs: '3vh', md: '4.2vh' },
                      fontSize: { xs: '13px', md: '15px' },
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                    },
                  }}
                ></TextField>
                <Stack
                  direction={'row'}
                  spacing={2}
                  alignItems={'center'}
                  sx={{ ml: { xs: '5px' } }}
                >
                  <Tooltip
                    title={
                      <Grid container display={'flex'}>
                        <Grid item>
                          <Stack display={'flex'} spacing={1}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: 'white',
                                fontWeight: '700',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Contact Onecommunn’s Support
                            </Typography>
                            <Divider
                              style={{ background: '#FFCFCF' }}
                            ></Divider>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: 'white',
                                fontFamily: 'Montserrat',
                                display: 'flex',
                              }}
                            >
                              <MailOutlineIcon sx={{ fontSize: '15px' }} />
                              info@communn.io
                            </Typography>
                            <Divider
                              style={{ background: '#FFCFCF' }}
                            ></Divider>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: 'white',
                                fontFamily: 'Montserrat',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <CallIcon sx={{ fontSize: '18px' }} />
                              +91 74065 21189
                            </Typography>
                          </Stack>
                          {/* <Divider style={{ background: "#FFCFCF" }}></Divider> */}
                        </Grid>
                      </Grid>
                    }
                    arrow
                    placement='top'
                  >
                    <ContactSupportOutlinedIcon
                      sx={{
                        color: '#50A1CA',
                        fontWeight: 700,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 0.5 }}></Divider>
              <SimpleBar style={{ height: `${height}vh` }} autoHide={false}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {filteredPaymentsList?.length === 0 && !loading ? (
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <NoPayment />
                      </Stack>
                    ) : (
                      filteredPaymentsList?.map((item: IPaymentList, i) => (
                        <>
                          <Accordion
                            elevation={0}
                            disableGutters={true}
                            expanded={expandedItems[i]}
                            onChange={() => handleAccordionChange(i)}
                            key={i}
                            sx={{ alignItems: 'center', padding: '0px' }}
                          >
                            <AccordionSummary
                              style={accordionSummaryStyle}
                              expandIcon={
                                <Avatar
                                  sx={{
                                    width: 26,
                                    height: 26,
                                    backgroundColor: 'white',
                                    border: '1px solid #8F8F8F',
                                  }}
                                >
                                  {' '}
                                  <ExpandMoreIcon sx={{ color: '#8F8F8F' }} />
                                </Avatar>
                              }
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                              sx={{ backgroundColor: 'white' }}
                            >
                              <ListItem
                                alignItems='flex-start'
                                sx={{ margin: '-15px' }}
                              >
                                <Tooltip
                                  title={item?.udf3?.name}
                                  arrow
                                  placement='top'
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      style={{
                                        width: '35px',
                                        height: '35px',
                                      }}
                                      alt='Remy Sharp'
                                      src={item?.udf3?.logo}
                                    />
                                  </ListItemAvatar>
                                </Tooltip>
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        sx={{
                                          fontFamily: 'Montserrat',
                                          fontSize: '13px',
                                        }}
                                      >
                                        {item?.udf2?.name
                                          ? item.udf2.name.length > 30
                                            ? `${item.udf2.name.substring(
                                              0,
                                              30
                                            )}...`
                                            : item.udf2.name
                                          : item?.appointment?.title || ''}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Stack
                                        direction={'row'}
                                        spacing={2}
                                        alignItems={'center'}
                                      >
                                        <Typography
                                          sx={{
                                            display: 'inline',
                                            fontSize: '12px',
                                            fontFamily: 'Montserrat',
                                          }}
                                          component='span'
                                          color='text.danger'
                                        >
                                          {formatDateRelative(
                                            item?.addedon || item?.updatedAt
                                          )}
                                        </Typography>
                                      </Stack>
                                    </React.Fragment>
                                  }
                                />
                                <Stack
                                  direction='row'
                                  spacing={2}
                                  display='flex'
                                  justifyContent='flex-end'
                                  alignItems='flex-end'
                                  sx={{ ml: { xs: '0px' } }}
                                >
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'flex-end',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                            fontSize: {
                                              xs: '12px',
                                              md: '14px',
                                            },
                                            color:
                                              getStaticValue(item.status) ===
                                                'Success' ||
                                                getStaticValue(item.status) ===
                                                'Paid By Cash' ||
                                                getStaticValue(item.status) ===
                                                'Settled'
                                                ? '#04AE48'
                                                : 'red',
                                          }}
                                          component='span'
                                          variant='body2'
                                        >
                                          ₹ {item?.amount}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          sx={{
                                            display: 'inline',
                                            fontFamily: 'Montserrat',
                                            fontSize: '11px',

                                            color:
                                              getStaticValue(item.status) ===
                                                'Success' ||
                                                getStaticValue(item.status) ===
                                                'Paid By Cash' ||
                                                getStaticValue(item.status) ===
                                                'Settled'
                                                ? '#04AE48'
                                                : 'red',
                                          }}
                                          component='span'
                                          variant='body2'
                                          color='text.danger'
                                        >
                                          {getStaticValue(item?.status) ||
                                            item.status}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </Stack>
                              </ListItem>
                              <Divider />
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid
                                  item
                                  xs={item?.udf2?.name === 'Custom Pay' ? 0 : 6}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                >
                                  <Stack
                                    display={'flex'}
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-start'}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {' '}
                                      Paid to : {item?.udf3?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                      }}
                                    >
                                      From Your : {item?.mode}
                                    </Typography>
                                    {/* <Typography
                                        sx={{
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                        }}
                                      >
                                        A/C No : {item?.cardnum}
                                      </Typography> */}
                                    <Typography
                                      sx={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Paid at:{' '}
                                      {(item?.addedon || item?.updatedAt) && (
                                        <>
                                          {(() => {
                                            const originalDate = new Date(
                                              item?.addedon || item?.updatedAt
                                            );
                                            const istDate = new Date(
                                              originalDate.getTime() +
                                              5.5 * 60 * 60 * 1000
                                            );
                                            return istDate.toLocaleString(
                                              'en-GB',
                                              {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false,
                                              }
                                            );
                                          })()}
                                        </>
                                      )}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {' '}
                                      Transaction ID :
                                      {/* {item?.bank_ref_num} */}
                                      {item?.txnid}
                                    </Typography>
                                  </Stack>
                                </Grid>
                                <Grid
                                  item
                                  xs={item?.udf2?.status === 'CUSTOM' ? 0 : 6}
                                  md={6}
                                  lg={6}
                                  xl={5.5}
                                  sx={{
                                    display:
                                      item?.udf2?.status === 'CUSTOM' ||
                                        item?.udf2?.status === 'custom' ||
                                        item?.status === 'PAID_BY_CASH' ||
                                        item?.status === 'Settled' ||
                                        item?.status === 'NA'
                                        ? 'none'
                                        : 'block',
                                  }}
                                >
                                  {item.status.toLowerCase() === 'success' && (
                                    <Stack>
                                      <Typography
                                        sx={{
                                          fontFamily: 'Montserrat',
                                          fontSize: '12px',
                                          textAlign: 'end',
                                          color: '#FF4F00',
                                        }}
                                      >
                                        Due on:
                                        {item?.dueDate && (
                                          <>{formatDate(item?.dueDate)}</>
                                        )}
                                      </Typography>
                                    </Stack>
                                  )}
                                  <Stack
                                    direction={'row'}
                                    display={'flex'}
                                    alignItems={'flex-end'}
                                    justifyContent={'flex-end'}
                                  >
                                    {item.status.toLowerCase() !==
                                      'success' && (
                                        <Button
                                          variant='contained'
                                          size='small'
                                          disabled={retryLoadingStates[i]}
                                          onClick={() => {
                                            if (item?.udf2?.status === 'CUSTOM') {
                                              handleCustomPayOpenforPaymentRequest(
                                                item?.net_amount_debit,
                                                item?.udf4,
                                                item?._id
                                              );
                                            } else {
                                              handleRetryClick(
                                                item?.udf3?._id,
                                                item?.udf2?._id,
                                                i,
                                                ''
                                              );
                                            }
                                          }}
                                          sx={{
                                            backgroundColor: '#50A1CA',
                                            textTransform: 'capitalize',
                                            fontFamily: 'Montserrat',
                                            boxShadow: 'none',
                                            '&:hover': {
                                              backgroundColor: '#50A1CA',
                                            },
                                          }}
                                        >
                                          {retryLoadingStates[i] ? (
                                            <CircularProgress
                                              size={20}
                                              style={{ color: '#ffffff' }}
                                            />
                                          ) : (
                                            'Pay Again'
                                          )}
                                        </Button>
                                      )}
                                  </Stack>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <Divider></Divider>
                        </>
                      ))
                    )}
                  </>
                )}
              </SimpleBar>
            </Paper>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3.5} xl={3.5} lg={3.5} sm={3.5}>
          <Stack direction={'row'} spacing={2}>
            <Paper elevation={0} sx={{ width: '100%', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
              <Stack
                direction={'row'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ p: 1.5 }}
              >
                {selectedCommunity?.selectedCommunity?.collectSubscription ===
                  'YES' ? (
                  <Button
                    variant='text'
                    component={Link}
                    to='/plans'
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      color: '#50A1CA',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                      textTransform: 'capitalize',
                      height: '25px',
                    }}
                  >
                    Plans
                  </Button>
                ) : (
                  <Typography
                    // disabled
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      color: '#50A1CA',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                      textTransform: 'capitalize',
                      height: '25px',
                    }}
                  >
                    Free Community
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#1E1E1E',
                    fontFamily: 'Montserrat',
                  }}
                ></Typography>
              </Stack>
            </Paper>
            <Button
              variant='contained'
              onClick={handleCustomPayOpen}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#3B9B7F',
                textAlign: 'center',
                fontSize: '13px',
                fontFamily: 'Montserrat',
                whiteSpace: 'nowrap',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#3B9B7F' },
                boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.15)',
              }}
            >
              Custom Pay
            </Button>
          </Stack>

          {/* Desktop Version */}
          <Paper
            elevation={0}
            sx={{
              mt: 1,
              height: { xs: '45vh', md: '80vh' },
              display: { xs: 'none', md: 'block' },
              borderRadius: '12px !important', border: '1px solid #E7EBF1',
            }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}
            >
              <Typography
                sx={{
                  color: '#B72626',
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                }}
              >
                Subscription Payment ({dueList?.length || 0})
              </Typography>
            </Stack>
            <Divider></Divider>
            <Paper
              elevation={0}
              sx={{
                maxHeight: { xs: '40vh', md: '70vh' },
                height: { xs: '40vh', md: '70vh' },
                pb: { xs: 0, md: 1 },
              }}
            >
              <SimpleBar style={{ height: '100%' }} autoHide={false}>
                {dueLoading ? (
                  <Loader />
                ) : (
                  <>
                    {dueList && dueList.length > 0 ? (
                      dueList.map((item: any, index) => (
                        <>
                          <ListItem alignItems='flex-start' key={index}>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontFamily: 'Montserrat',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {item?.plan?.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontFamily: 'Montserrat',
                                      fontWeight: '500',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat',
                                      }}
                                    >
                                      (
                                      {item?.community?.name
                                        ? item.community.name.substring(0, 25)
                                        : ''}
                                      {item?.community?.name.length > 25 &&
                                        '...'}
                                      )
                                    </span>
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component='span'
                                    sx={{
                                      fontWeight: 'bold',
                                      fontSize: '13px',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    ₹
                                    {item?.plan?.offerValue === 0
                                      ? item?.plan?.totalPlanValue
                                      : item?.plan?.offerValue}{' '}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: 'inline',
                                      fontSize: '13px',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    Billed Every {item.plan?.interval}{' '}
                                    {item.plan?.interval > '1'
                                      ? `${getStaticValue(
                                        item.plan?.duration
                                      )}s`
                                      : getStaticValue(
                                        item.plan?.duration
                                      )}{' '}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {' '}
                                    {isExpired(item?.dueDate)
                                      ? 'Expired on: '
                                      : 'Next due on: '}
                                    <Typography
                                      component='span'
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {formatDate(item?.dueDate)}
                                    </Typography>
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                            <Stack
                              direction='column'
                              spacing={2}
                              sx={{ pt: 2 }}
                              justifyContent='center'
                              alignItems='center'
                            >
                              <Button
                                variant='outlined'
                                size='small'
                                disabled={loadingStates[index]}
                                onClick={() => {
                                  navigate(
                                    `/plans/subscription/${item?.plan?._id}`
                                  );
                                }}
                                sx={{
                                  backgroundColor: '#50A1CA',
                                  textTransform: 'none',
                                  borderColor: '#FFF1F1',
                                  color: '#ffffff',
                                  fontFamily: 'Montserrat',
                                  fontSize: '11px',
                                  '&:hover': {
                                    backgroundColor: '#50A1CA',
                                    borderColor: '#FFF1F1',
                                  },
                                }}
                              >
                                {loadingStates[index] ? (
                                  <CircularProgress
                                    size={20}
                                    style={{ color: 'green' }}
                                  />
                                ) : (
                                  'Pay Now'
                                )}
                              </Button>
                              {isExpired(item?.dueDate) ? (
                                <CircleRoundedIcon
                                  sx={{ color: 'red', fontSize: '12px' }}
                                />
                              ) : (
                                ' '
                              )}
                            </Stack>
                          </ListItem>
                          <Divider></Divider>
                        </>
                      ))
                    ) : (
                      <>
                        <Stack
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          height={'65vh'}
                        >
                          <Typography
                            sx={{
                              color: '#777777',
                              fontSize: { xs: '13px', md: '15px' },
                              fontWeight: '500',
                              fontFamily: 'Montserrat',
                              textAlign: 'center',
                            }}
                          >
                            No dues available
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </>
                )}
              </SimpleBar>
            </Paper>
          </Paper>

          {/* Mobile Version */}
          {dueList?.length > 0 && (
            <Paper
              elevation={0}
              sx={{
                mt: 1,
                height: { xs: '45vh', md: '80vh' },
                display: { xs: 'block', md: 'none' },
                borderRadius: '12px !important', border: '1px solid #E7EBF1',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}
              >
                <Typography
                  sx={{
                    color: '#B72626',
                    fontSize: { Xs: '14px', md: '16px' },
                    fontWeight: '500',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Subscription Payment ({dueList?.length})
                </Typography>
                {/* {viewAll1 ? (
                 <Button
                   variant="text"
                   size="small"
                   sx={{
                     textTransform: "capitalize",
                     color: "#464646",
                     fontWeight: "500",
                     fontSize: "12px",
                   }}
                   onClick={() => setViewAll1(false)}
                 >
                   {" "}
                   View Less
                 </Button>
               ) : (
                 <Button
                   size="small"
                   variant="text"
                   sx={{
                     textTransform: "capitalize",
                     color: "#464646",
                     fontWeight: "500",
                     fontSize: "12px",
                     fontFamily: "Montserrat",
                     display: dueList && dueList?.length <= 4 ? "none" : "block",
                   }}
                   disabled={itemArr2 && itemArr2.length < 6}
                   onClick={() => setViewAll1(true)}
                 >
                   View more
                 </Button>
               )} */}
              </Stack>
              <Divider></Divider>
              <Paper
                elevation={0}
                sx={{
                  maxHeight: { xs: '40vh', md: '70vh' },
                  height: { xs: '40vh', md: '70vh' },
                  pb: { xs: 0, md: 1 },
                }}
              >
                <SimpleBar style={{ height: '100%' }} autoHide={false}>
                  {dueLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dueList.map((item: any, index) => (
                        <>
                          <ListItem alignItems='flex-start' key={index}>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontFamily: 'Montserrat',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {item?.plan?.name}
                                    {/* {isExpired(item?.dueDate) ?
                                   <CircleRoundedIcon sx={{ color: 'red', fontSize: '10px', }} /> : " "
                                 } */}

                                    {/* {item?.plan?.name ? item?.plan?.name.substring(0, 15) : ' '}{item?.plan?.name.length > 15 && "..."} - {" "} */}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontFamily: 'Montserrat',
                                      fontWeight: '500',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat',
                                      }}
                                    >
                                      (
                                      {item?.community?.name
                                        ? item.community.name.substring(0, 25)
                                        : ''}
                                      {item?.community?.name.length > 25 &&
                                        '...'}
                                      )
                                    </span>
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component='span'
                                    sx={{
                                      fontWeight: 'bold',
                                      fontSize: '13px',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    ₹
                                    {item?.plan?.offerValue === 0
                                      ? item?.plan?.totalPlanValue
                                      : item?.plan?.offerValue}{' '}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: 'inline',
                                      fontSize: '13px',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    Billed Every {item.plan?.interval}{' '}
                                    {item.plan?.interval > '1'
                                      ? `${getStaticValue(
                                        item.plan?.duration
                                      )}s`
                                      : getStaticValue(
                                        item.plan?.duration
                                      )}{' '}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {' '}
                                    { }
                                    {isExpired(item?.dueDate)
                                      ? 'Expired on: '
                                      : 'Next due on: '}
                                    <Typography
                                      component='span'
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {formatDate(item?.dueDate)}
                                    </Typography>
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                            <Stack
                              direction='column'
                              spacing={2}
                              sx={{ pt: 2 }}
                              // display='row'
                              justifyContent='center'
                              alignItems='center'
                            >
                              <Button
                                variant='outlined'
                                size='small'
                                disabled={loadingStates[index]}
                                // onClick={() => {
                                //   handleRetryClick(
                                //     item?.community?._id,
                                //     item?.plan?._id,
                                //     index,
                                //     "due"
                                //   );
                                // }}
                                onClick={() => {
                                  navigate(
                                    `/plans/subscription/${item?.plan?._id}`
                                  );
                                }}
                                sx={{
                                  backgroundColor: '#50A1CA',
                                  textTransform: 'none',
                                  borderColor: '#FFF1F1',
                                  color: '#ffffff',
                                  fontFamily: 'Montserrat',
                                  fontSize: '11px',
                                  '&:hover': {
                                    backgroundColor: '#50A1CA',
                                    borderColor: '#FFF1F1',
                                  },
                                }}
                              >
                                {loadingStates[index] ? (
                                  <CircularProgress
                                    size={20}
                                    style={{ color: 'green' }}
                                  />
                                ) : (
                                  'Pay Now'
                                )}
                              </Button>
                              {isExpired(item?.dueDate) ? (
                                <CircleRoundedIcon
                                  sx={{ color: 'red', fontSize: '12px' }}
                                />
                              ) : (
                                ' '
                              )}
                            </Stack>
                          </ListItem>
                          <Divider></Divider>
                        </>
                      ))}
                    </>
                  )}
                </SimpleBar>
              </Paper>
            </Paper>
          )}
        </Grid>

        <CustomPayModel
          fullWidth={true}
          maxWidth={'xs'}
          customPay={customPay}
          handleCustomPayClose={handleCustomPayClose}
          buttonText={
            customloading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Pay now'
            )
          }
          handleCustomPayIcon={handleCustomPayClose}
          handleSubmitClick={() => {
            //if (amount && amount > 99.0) {
            handleSubmit();
            // }
          }}
          paymentvalue={amount || ''}
          paymentOnChange={handleAmountChange}
          paymenReason={reason}
          paymentReasonOnChange={handleResonChange}
          readonly={readonly || mode}
        />
        {/* Payment Success Pop up */}
        <PaymentSuccess
          txnid={transaction?.txnid || ''}
          open={successOpen}
          amount={transaction?.amount || ''}
          timer={timer}
          dialogText={''}
          onClose={handleSuccessClose}
          onClickNavigateButton={() => {
            navigate('/payments');
            setSuccessOpen(false);
            setPaymentUpdated(!paymentUpdated);
          }}
        />

        {/* Payment Failed Pop up */}
        <PaymentFailed
          open={failureOpen}
          onClose={handleFailureClose}
          amount={transaction?.amount || ''}
          txnid={transaction?.txnid || ''}
          onClickNavigateButton={() => {
            navigate('/payments');
            setFailureOpen(false);
            setPaymentUpdated(!paymentUpdated);
          }}
          dialogText={''}
          timer={timer}
          OnClickRetryButtonFunction={() => {
            if ((transaction?.udf2 as any) === '663f0fbb7377a32029638f15') {
              setFailureOpen(false);
              handleCustomPayOpen();
              setAmount(transaction?.amount);
              setReason(transaction?.udf4 || '');
            } else {
              handleRetryClick(community, plan, '', '');
            }
          }}
        />
        {/*  */}

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={bank}
          onClose={handleBankPopUpClose}
        // sx={{
        //   "& .MuiDialog-paper": {
        //     borderRadius: "12px",
        //   },
        // }}
        >
          <DialogTitle sx={{ padding: '0px 5px 10px 0px' }}>
            <Grid container sx={{ backgroundColor: '#F0F9FF', p: '4px' }}>
              <Grid item xs={5} md={3} lg={3} xl={3} sm={3}></Grid>
              <Grid item xs={2} md={4} lg={4} xl={4} sm={4}></Grid>
              <Grid item xs={5} md={5} lg={5} xl={5} sm={5}>
                <Stack display={'flex'} alignItems={'end'}>
                  <Box
                    onClick={() => {
                      setBank(false);
                    }}
                    sx={{
                      backgroundColor: '#50A1CA',

                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '10px',
                      mt: '0.5px',

                      cursor: 'pointer',
                      '&:hover': {
                        // backgroundColor: "#50A1CA", // Background color on hover
                        cursor: 'pointer',
                        border: 'none',
                      },
                    }}
                  >
                    <Close sx={{ fontSize: '15px' }} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sm={12}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Typography
                    sx={{
                      color: 'gray',
                      fontSize: { xs: '12px', md: '15px' },
                      fontWeight: '500',
                      fontFamily: 'Montserrat'
                    }}
                  >
                    To send payment requests to your members, please add your
                    bank account details and obtain approval from the super
                    admin to enable payments to be deposited into your account.
                    Note: Members will be unable to process payments until a
                    bank account is added and approved by the super admin.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sm={12}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{ mt: 1 }}
                >
                  <Typography
                    sx={{
                      color: 'gray',
                      fontSize: { xs: '12px', md: '15px' },
                      fontWeight: '500',
                    }}
                  >
                    Note: Members will be unable to process payments until a
                    bank account is added and approved by the super admin.
                  </Typography>
                </Grid>
                <Grid item xs={8.5} md={6} lg={6} xl={8.5} sm={6}></Grid>
              </Grid>

              <Grid item sx={{ mt: 2 }} xs={4.5} md={4.5} lg={4.5} xl={4.5}>
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  sx={{
                    mt: 2,
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    background:
                      'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                  }}
                >
                  {' '}
                  Add Bank Account{' '}
                </Button>{' '}
              </Grid>
              <Grid item sx={{ mt: 2 }} xs={3} md={3} lg={3} xl={3}></Grid>

              <Grid item sx={{ mt: 2 }} xs={4.5} md={4.5} lg={4.5} xl={4.5}>
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  sx={{
                    mt: 2,
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    background:
                      'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                  }}
                >
                  {' '}
                  Add Later{' '}
                </Button>{' '}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/* <Button onClick={() => setFailureOpen(true)}> Exp</Button>   */}
        {/* <PlanExpired
          open={failureOpen}
          onClose={handleFailureClose}
          amount={transaction?.amount || ""}
          txnid={transaction?.txnid || ""}
          onClickNavigateButton={() => {
            navigate("/payments");
            setFailureOpen(false);
          }}
          dialogText={""}
          timer={timer}
          OnClickRetryButtonFunction={() => {
            handleRetryClick(community, plan, "");
            setFailureOpen(false);
          }}
        /> */}
      </Grid>
    </>
  );
};
export default UserPayments;
